import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import React from "react";
import { X } from "react-feather";

import useStyles from "./MyTaskPageSortByModal.styles";

export default function MyTaskPageSortByModal(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div class={classes.popoverArrow}></div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className={classes.title}>Sort By</h2>
            <div className={classes.clear} onClick={(e) => props.closeModal()}>
              <X size="30px" />
            </div>
          </div>
          <br />
          <FormControl component="fieldset">
            <RadioGroup
              // aria-label="gender"
              // name="gender1"
              value={value}
              onChange={(event) => handleChange(event)}
              defaultValue={props.defaultvalue}
            >
              <FormControlLabel
                className={classes.textColor}
                value="A-Z"
                control={<Radio />}
                label="A to Z Name"
              />
              <FormControlLabel
                className={classes.textColor}
                value="Z-A"
                control={<Radio />}
                label="Z to A Name"
              />

              <FormControlLabel
                className={classes.textColor}
                value="Deadline Date"
                control={<Radio />}
                label="Deadline Date"
              />

              <FormControlLabel
                className={classes.textColor}
                value="Starting Date"
                control={<Radio />}
                label="Starting Date"
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <div className={classes.cardAction}>
          <Button
            size="small"
            className={classes.sortButton}
            onClick={() => props.onSubmit(value)}
          >
            Done
          </Button>
        </div>
      </Card>
    </div>
  );
}
