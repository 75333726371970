import { Button, Grid, Menu, MenuItem } from "@material-ui/core";
import { DotsThree, PencilLine, Plus, Trash } from "phosphor-react";
import React from "react";
import { useState } from "react";
import useStyles from "./TaskTemplateSettingOverview.style";

const SingleTemplateCard = ({
  singleTemplate,
  handleManageTaskPhaseModalInfo,
  handleDeleteTaskTemplateInfo,
  handleEditTaskTemplateModalInfo,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <div
          onDoubleClick={(e) => {
            e.preventDefault();
            handleManageTaskPhaseModalInfo(singleTemplate);
          }}
          className={classes.singleTemplate}
        >
          <div className={classes.templateHeaderContainer}>
            <div>{singleTemplate?.total_phases}</div>
            <div className={classes.templateName}>{singleTemplate?.name}</div>
            <Button onClick={handleOpenMenu}>
              <DotsThree size={25} />
            </Button>
          </div>
          <div className={classes.duration}>
            Day - {singleTemplate?.total_days || 0}
          </div>
          <div className={classes.details}>{singleTemplate?.description}</div>
        </div>
      </Grid>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleManageTaskPhaseModalInfo(singleTemplate);
            handleCloseMenu();
          }}
        >
          <Plus size={25} />{" "}
          <span style={{ marginLeft: "10px" }}>Manage Phases</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleEditTaskTemplateModalInfo(singleTemplate);
            handleCloseMenu();
          }}
        >
          <PencilLine size={25} />{" "}
          <span style={{ marginLeft: "10px" }}>Edit Task Template</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteTaskTemplateInfo(singleTemplate);
            handleCloseMenu();
          }}
        >
          <Trash size={25} />{" "}
          <span style={{ marginLeft: "10px" }}>Delete Task Template</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SingleTemplateCard;
