import { Grid } from "@material-ui/core";
import { React, useState } from "react";
import Loader from "../../../Utilities/Loader/Loader";
import StatusItem from "./StatusItem/StatusItem";

export default function StatusList({
  selectedTrackable,
  projectStatus,
  trackableClass,
  handleDeleteStatusModal,
  handleEditStatusModal,
  anchorEl,
  handleAnchorEl,
  closeMenu,
}) {
  return (
    <div
      style={{
        display: "flex",
        gap: "0.4%",
        flexWrap: "wrap",
        marginTop: "1%",
      }}
    >
      {projectStatus.length > 0 ? (
        projectStatus.map((el) => (
          <StatusItem
            anchorEl={anchorEl}
            handleAnchorEl={handleAnchorEl}
            handleCloseMenu={closeMenu}
            status={el}
            selectedTrackable={selectedTrackable}
            trackableClass={trackableClass}
            handleDeleteStatusModal={handleDeleteStatusModal}
            handleEditStatusModal={handleEditStatusModal}
          />
        ))
      ) : (
        <Loader />
      )}
    </div>
  );
}
