import React, { useState } from "react";
import * as Icon from "react-feather";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//@MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Select,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import useStyles from "./SequencePageNewSequenceModal.styles";
import { Asterisk } from "phosphor-react";

import { useHistory } from "react-router-dom";
import { fget, fpost, exportFileWithXhr } from "../../../API/callsAPI";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import HelperText from "../../Utilities/HelperText/HelperText";

export default function SequencePageNewSequenceModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const [thumbnail, uploadThumbnailSet] = useState();
  const [thumbnailTouched, setThumbnailTouch] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleThumbnailUpload = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    uploadThumbnailSet(file);
    setThumbnailTouch(true);
  };

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    uploadThumbnailSet(null);
    setThumbnailTouch(false);
  };

  //Validators
  const NewSequenceValidationScheme = yup.object({
    sequenceName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      sequenceName: "",
      status: "",
      description: "",
    },
    validationSchema: NewSequenceValidationScheme,
    onSubmit: async (values) => {
      setLoader(true);
      if (thumbnailTouched) {
        setFileUploading(true);
      }
      const formdata = new FormData();
      formdata.append("code", values.sequenceName);
      if (thumbnail) {
        formdata.append("file_name", thumbnail.name);
      }
      formdata.append("file_path", "");
      formdata.append("project", props.projectId);
      formdata.append("status", values.status);
      formdata.append("description", values.description);
      let newSequence = {
        url: "trackables/shot-sequence/",
        data: formdata,
      };

      try {
        const newSeqRes = await fpost(newSequence);
        if (newSeqRes.status === 200 || 201) {
          if (thumbnail) {
            getSignedUrl(newSeqRes.data.id);
          } else {
            setLoader(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Created ${newSeqRes.data.code}`,
              isError: false,
            });
            setFileUploading(false);
            props.updateRefreshCounter();
            props.handleNewSequenceModal();
          }
        }
      } catch (error) {
        setFileUploading(false);
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  function updateProgressBar(e) {
    if (e.lengthComputable) {
      var percentComplete = parseInt((e.loaded / e.total) * 100);
      setUploadProgress(percentComplete);
    }
  }

  function transferComplete(evt) {
    setSnackValue({
      isOpen: true,
      message: `File transfered SuccessFully `,
      isError: false,
    });
  }

  function transferFailed(evt) {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  function transferCanceled(evt) {
    setSnackValue({
      isOpen: true,
      message: `File Transfer Cancelled`,
      isError: true,
    });
  }

  const getSignedUrl = async (newId) => {
    try {
      const uploadUrl = await fget({
        url: `trackables/sequence/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        await exportFileWithXhr({
          url: uploadUrl.data?.signed_url,
          data: thumbnail,
          updateProgressBar: (e) => {
            updateProgressBar(e);
          },
          transferComplete: transferComplete(),
          transferFailed: transferFailed(),
          transferCanceled: transferCanceled(),
        });
        fget({
          url: `trackables/sequence/${newId}/thumbnail`,
        });
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully Created`,
          isError: false,
        });
        setFileUploading(false);
        props.updateRefreshCounter();
        props.handleNewSequenceModal();
      }
    } catch (error) {
      setFileUploading(false);
      setLoader(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      console.log(e.dataTransfer.files[0]);
      uploadThumbnailSet(e.dataTransfer.files[0]);
      setThumbnailTouch(true);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
        // message={`${snackValue.message}`}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form
            id="form"
            enctype="application/x-www-form-urlencoded"
            onSubmit={formik.handleSubmit}
          >
            <ModalTitleAndButtons
              name="Sequence"
              type="New"
              closeModal={() => props.handleNewSequenceModal()}
            />
            <div className={classes.cardContentDiv1}>
              <div>
                <span>Sequence Name</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv3}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    placeholder="Enter sequence name"
                    fullWidth
                    type="text"
                    className={classes.cardContentInput1}
                    InputProps={{ disableUnderline: true }}
                    name="sequenceName"
                    id="sequenceName"
                    value={formik.values.sequenceName}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.sequenceName)}
                  />
                </div>
                {formik.errors.sequenceName && formik.touched.sequenceName ? (
                  <HelperText text={formik.errors.sequenceName} />
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "25px",
                }}
              >
                <div style={{ width: "150px" }}>
                  <span>Status</span>
                  <div
                    className={classes.cardContentDiv3}
                    style={{ marginTop: "5px" }}
                  >
                    <Select
                      disableUnderline
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-outlined-label"
                      className={classes.cardContentInput1}
                      id="selectStatusS"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      label="Select Status"
                    >
                      <MenuItem
                        className={classes.addNewStatusButton}
                        onClick={() => {
                          history.push(`/${props.projectId}/settings`);
                        }}
                      >
                        <Icon.Plus />
                        Add new Status
                      </MenuItem>
                      <MenuItem value="">None</MenuItem>
                      {props.status &&
                        props.status.map((eachStatus) => (
                          <MenuItem value={eachStatus.id}>
                            <span
                              style={{
                                height: "15px",
                                width: "15px",
                                backgroundColor: `rgb(${eachStatus?.color})`,
                                borderRadius: "50%",
                                display: "inline-block",
                                margin: "0px 5px",
                              }}
                            ></span>
                            {eachStatus.name} - {eachStatus.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
                <div style={{ maxWidth: "50%" }}>
                  <span>Status Code</span>
                  <div
                    style={{ marginTop: "5px" }}
                    className={classes.cardContentDiv3}
                  >
                    <TextField
                      className={classes.cardContentInput1}
                      InputProps={{
                        disableUnderline: true,
                        style: { opacity: "0.4" },
                      }}
                      disabled
                      fullWidth
                      value={
                        props.status?.find(
                          (eachStatus) => eachStatus.id === formik.values.status
                        )?.code
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "25px" }}>
                <span>Sequence Description</span>
                <div
                  className={classes.cardContentDiv4}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    type="text"
                    fullWidth
                    multiline={true}
                    InputProps={{ disableUnderline: true }}
                    placeholder="Enter a Description..."
                    name="description"
                    className={classes.cardContentInput1}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.description)}
                    helperText={
                      formik.errors.description
                        ? formik.errors.description
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "25px",
                display: "flex",
                flexDirection: "column",

                justifyContent: "space-between",

                width: "100%",
              }}
            >
              <span>Attachments </span>
              <UploadFile
                fileUploading={fileUploading}
                uploadProgress={uploadProgress}
                thumbnail={thumbnail}
                handleClearUpload={handleClearUpload}
                handleThumbnailUpload={handleThumbnailUpload}
              />
            </div>
            <br />

            <CardActions className={classes.cardAction}>
              <Button
                className={classes.done}
                type="submit"
                value="Submit"
                size="small"
                disabled={fileUploading}
                disableElevation
              >
                {loader ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  <b>Create New Sequence</b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
