import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

//UI
import { Divider, CircularProgress } from "@material-ui/core";
import useStyles from "./NotificationsDropdown.styles";

//APIs
import { fget, fpatch } from "../../../API/callsAPI";
//
import NotificationDropdownDetail from "./NotificationDropdownDetail";

function NotificationsDropdown(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const decodedURL = decodeURIComponent(
    escape(window.atob(history.location.search.substring(1)))
  );
  const searchParams = new URLSearchParams(decodedURL);

  const fetchNotification = async () => {
    try {
      const notificationRes = await fget({
        url: "notification/",
      });
      setNotifications(notificationRes.data.results);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  };

  const getTrackableId = async (id) => {
    try {
      const result = await fget({
        url: `trackables/task/${id}`,
      });
      if (result.status === 200 || 201) {
        if (result.data.linked_class == "Shot") {
          return {
            id: result.data.linked.id,
            parentId: result.data.linked.parent_sequence,
          };
        } else {
          return { id: result.data.linked.id };
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function that return a url according to notification type
  const sendToUrl = async (trackable, notificationDetail) => {
    let url = `/${notificationDetail.project}/`;
    switch (trackable) {
      case "project":
        return (url += "updates");
      case "department":
        return (url += `departments/${notificationDetail.content_object.id}/department`);
      case "task":
        return (url += "tasks");
      case "asset":
        return (url += `assets/${notificationDetail.content_object.id}/assetversions`);
      case "assetversion":
        searchParams.set(
          "versionId",
          `${notificationDetail.content_object.id}`
        );
        return (url += `assets/${notificationDetail.content_object.asset}/assetversions`);
      case "sequence":
        return (url += `sequence/${notificationDetail.content_object.id}/shots`);
      case "shot":
        return (url += `sequence/${notificationDetail.content_object.parent_sequence}/shots/${notificationDetail.content_object.id}/shotversions`);
      case "shotversion":
        searchParams.set(
          "versionId",
          `${notificationDetail.content_object.id}`
        );
        return (url += `sequence/${notificationDetail.content_object.sequence_id}/shots/${notificationDetail.content_object.shot}/shotversions`);
      case "taskcomment":
        const object = await getTrackableId(
          notificationDetail.content_object.task
        );
        if (object.id && object.parentId) {
          url += `sequence/${object.parentId}/shots/${object.id}/task`;
        } else {
          url += `assets/${object.id}/task`;
        }
        return url;
      case "trackablecomment":
        if (notificationDetail.content_object.version_type === "shotversion") {
          searchParams.set(
            "versionId",
            `${notificationDetail.content_object.version_object.id}`
          );
          return (url += `sequence/${notificationDetail.content_object.version_object.sequence_id}/shots/${notificationDetail.content_object.version_object.shot}/shotversions`);
        } else if (
          notificationDetail.content_object.version_type === "assetversion"
        ) {
          searchParams.set(
            "versionId",
            `${notificationDetail.content_object.version_object.id}`
          );
          return (url += `assets/${notificationDetail.content_object.version_object.asset}/assetversions`);
        }
      default:
        return (url += "updates");
    }
  };

  //mark notification as read and takes to the respective url
  const readNotifications = async (id, notificationDetail) => {
    if (!notificationDetail.isRead) {
      let newNotification = {
        url: `notification/${id}/`,
        data: {
          isRead: true,
        },
      };
      try {
        await fpatch(newNotification);
      } catch (error) {
        console.log(error);
      }
    }

    location.pathname = await sendToUrl(
      notificationDetail.notification_object.content_type,
      notificationDetail.notification_object
    );
    window.open(
      `${
        location.pathname +
        "?" +
        btoa(unescape(encodeURIComponent(searchParams.toString())))
      }`
    );
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <div className={classes.notificationsDropdown}>
      <div className={classes.notificationsDropdownMenu}>
        {!isLoaded ? (
          <CircularProgress color="secondary" style={{ margin: "auto" }} />
        ) : (
          notifications.map((notificationDetails) => {
            return (
              <div
                onClick={() =>
                  readNotifications(notificationDetails.id, notificationDetails)
                }
                key={notificationDetails.id}
              >
                <NotificationDropdownDetail
                  notificationDetails={notificationDetails}
                />
              </div>
            );
          })
        )}
      </div>
      <Divider className={classes.divider} />
      <div
        style={{ textDecoration: "none" }}
        onClick={() => {
          props.handleNotificationsModalToggle();
          props.handleNotificationModal();
        }}
      >
        <p className={classes.seeAll}>See all</p>
      </div>
    </div>
  );
}

export default NotificationsDropdown;
