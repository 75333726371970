import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderCollapse: "inherit",
  },

  SequenceImageDiv: {
    borderRadius: "10px",
    marginRight: "20px",
  },
  SequenceImage: {
    borderRadius: "8px",
    objectFit: "cover",
  },
  DepartmentsDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },

  textColor: {
    color: theme.palette.text.primary,
  },
  actionIcon: {
    backgroundColor: theme.palette.secondary.main,
  },
  sequencePageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  SequenceListItem: {
    padding: "1px",
    overflow: "hidden",
    borderCollapse: "collapse",
    width: "17%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
}));

export default useStyles;
