import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  profileDropdown: {
    position: "absolute",
    width: "250px",
    height: "auto",
    marginTop: "70px",
    background: theme.palette.secondary.light,
    borderRadius: "10px",
    right: "5%",
    fontFamily: "Nunito sans",
    textAlign: "center",
    color: theme.palette.text.primary,
    zIndex: 2000,
  },
  popoverArrow: {
    zIndex: 2000,
    borderRadius: "5px",
    position: "absolute",
    top: "-12px",
    right: "10px",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.light}`,
    width: "0",
    height: "0",
  },
  profileDropdownMenu: { margin: "20px" },
  profileDropdownItems: {
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    color: theme.palette.text.primary,
    p: { color: theme.palette.text.primary, padding: "0", margin: "0" },
    th: { display: "flex", justifyContent: "space-between", width: "100%" },
  },
}));

export default useStyles;
