import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  viewProject: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
    fontFamily: "poppins",
    padding: "0px 0px 3px 0px",
  },
  textLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  text: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    padding: "0px 0px 3px 0px",
  },
  trashIcon: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },
  trashIcon1: {
    color: theme.palette.text.primary,
    width: "30px",
    height: "45px",
    paddingRight: "10px",
  },

  menuPop: {
    margin: "0px",
    backgroundColor: theme.palette.secondary.light,
  },

  modalButton: {
    width: "180px",
    height: "45px",
    borderRadius: "15px",
    borderColor: theme.palette.secondary.light,
    background: theme.palette.secondary.light,
    fontFamily: "Nunito sans",
    textAlign: "center",
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
