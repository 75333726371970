import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // borderCollapse: "",
  },

  AssetImageDiv: {
    background: "#C4C4C4",
    borderRadius: "10px",
    marginRight: "20px",
  },
  AssetImage: {
    borderRadius: "8px",
  },
  DepartmentsDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },

  listText: {
    color: theme.palette.text.primary,
    padding: "1px",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },

  DepartmentDiv: {
    // display: "inline-grid",
    // textAlign: "center",
    // //border: "1px solid #EEEEEE",
    // width: "120px",
    // height: "40px",
    // borderRadius: "20px",
    // margin: "5px",
  },
  assetsListModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
