import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  commentBox: {
    width: "100%",
    minHeight: "30px",
    height: "25%",
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    padding: "1.3%",
  },

  sendBtn: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",
    marginLeft: "10px",
    minWidth: "30px",
    height: "30px",
  },

  icon: {
    margin: "2px",
    color: theme.palette.text.secondary,
    width: "15px",
    borderRadius: "2px",
  },
}));

export default useStyles;
