import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popoverArrow: {
    borderRadius: "5px",
    position: "absolute",
    top: "30%",
    right: "250px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.light}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
    transform: "rotate(-90deg)",
  },
  popoverArrowTable: {
    borderRadius: "5px",
    position: "absolute",
    top: "-20px",
    right: "75px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.secondary.light}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
  },
  popoverArrowKanban: {
    borderRadius: "5px",
    position: "absolute",
    top: "-20px",
    right: "20px",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderBottom: `20px solid ${theme.palette.primary.light}`,
    borderTop: "3px solid transparent",
    width: "0",
    height: "0",
  },
  card: {
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.light,
    padding: "10px",
    width: "250px",
  },
  card2: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.light,
    padding: "10px",
    width: "250px",
  },
  onHover: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
