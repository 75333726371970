import React from "react";
import * as Icon from "react-feather";
import { MouseSimple } from "phosphor-react";
import useStyles from "./ComparePageCard.styles";
import { Button } from "@material-ui/core";
import captureVideoFrame from "../../Player/capture-video-frame";
import CardToolsGroup from "./CardGrouped/CardToolsGroup";
import SelectVersionIcon from "../../Utilities/Svg/SelectVersionIcon";

export default function ComparePageCard({
  isSideBySide,
  setCanvasModal,
  setScreenshot,
  playerOneVersion,
  playerTwoVersion,
  url,
  versions,
  opacityValueOne,
  changeOpacityValueOne,
  opacityValueTwo,
  changeOpacityValueTwo,
  playerOne,
  playerTwo,
  playBoth,
  setPlayerTwo,
  volumePlayerOne,
  setVolumeOne,
  volumePlayerTwo,
  setVolumeTwo,
  refPlayerOne,
  refPlayerTwo,
  setCurrentTimeSeekOne,
  setCurrentTimeSeekTwo,
  currentDurationOne,
  currentDurationTwo,
  handleOnPrevious,
  handleOnNext,
  setPlayer,
  openModal,
  mutedOne,
  mutedTwo,
  onMuteOne,
  onMuteTwo,
}) {
  const classes = useStyles();

  return (
    <>
      {isSideBySide ? (
        <div className={classes.card}>
          {/* player One Details */}
          <div className={classes.CardContent1}>
            <div
              style={{
                width: "100%",
                height: "20%",
                display: "flex",
                marginTop: "3%",
              }}
            >
              <div className={classes.status}>
                <p>{playerOneVersion.status?.name}</p>
                <div
                  style={{
                    backgroundColor: `rgb(${playerOneVersion.status?.color})`,
                    height: "16px",
                    width: "16px",
                    borderRadius: "50%",
                    marginLeft: "5px",
                  }}
                />
              </div>
            </div>
            <div className={classes.title}>
              <div style={{ fontSize: "24px", textAlign: "center" }}>
                {playerOneVersion.name}
              </div>
              <div className={classes.statusCode}>
                {playerOneVersion.status?.code}
              </div>
            </div>
            <div className={classes.updateInfo1}>
              <div style={{ marginLeft: "2.8%", marginTop: "1%" }}>
                <div>
                  {new Date(playerOneVersion.updated_at).toLocaleDateString(
                    "en-US",
                    {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    }
                  )}{" "}
                  {new Date(playerOneVersion.updated_at).toLocaleTimeString(
                    [],
                    { hour: "2-digit", minute: "2-digit" }
                  )}
                </div>
                {playerOneVersion.updated_by?.first_name ? (
                  <div>
                    Updated By {playerOneVersion.updated_by?.first_name}{" "}
                    {playerOneVersion.updated_by?.last_name}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* Middle Separator */}
          <div className={classes.CardContent2}>
            <div
              style={{
                height: "75%",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  marginTop: "5%",
                  width: "40%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{ minWidth: "5%", height: "30%" }}
                  onClick={() => openModal("1")}
                >
                  <SelectVersionIcon />
                </Button>
                <Button
                  style={{ minWidth: "5%", height: "30%" }}
                  onClick={() => {
                    const frame = captureVideoFrame(refPlayerOne.current);
                    setScreenshot(frame.dataUri);
                    setCanvasModal(true);
                    setPlayer("1");
                  }}
                >
                  <Icon.Edit />
                </Button>
              </div>
              <div className={classes.circle}></div>
              <div style={{ marginTop: "5%", width: "40%" }}>
                {url ? (
                  <Button
                    style={{ minWidth: "5%", height: "30%" }}
                    onClick={() => {
                      const frame = captureVideoFrame(refPlayerTwo.current);
                      setScreenshot(frame.dataUri);
                      setCanvasModal(true);
                      setPlayer("2");
                    }}
                  >
                    <Icon.Edit />
                  </Button>
                ) : (
                  <div style={{ height: "30%", width: "15%" }}></div>
                )}
                {url ? (
                  <Button
                    style={{ minWidth: "5%", height: "30%" }}
                    onClick={() => openModal("2")}
                  >
                    <SelectVersionIcon />
                  </Button>
                ) : (
                  <div style={{ height: "30%", width: "15%" }}></div>
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "5px",
                width: "100%",
                height: "30%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className={classes.combinePlayButton}
                style={{
                  pointerEvents: url ? "" : "none",
                  opacity: url ? "1" : "0.4",
                }}
                onClick={playBoth}
              >
                Combine Play
              </Button>
            </div>
          </div>
          {/* Player Two Details */}
          {url ? (
            <div className={classes.CardContent3}>
              <div
                style={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "3%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "3%",
                    marginTop: "2%",
                  }}
                >
                  <p>{playerTwoVersion.status?.name}</p>
                  <div
                    style={{
                      backgroundColor: `rgb(${playerTwoVersion.status?.color})`,
                      height: "16px",
                      width: "16px",
                      borderRadius: "50%",
                      marginLeft: "5px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "130%",
                  height: "30%",
                  marginTop: "2%",
                  marginLeft: "-26%",
                }}
              >
                <div style={{ fontSize: "24px", textAlign: "center" }}>
                  {playerTwoVersion.name}
                </div>
                <div className={classes.statusCodeTwo}>
                  {playerTwoVersion.status?.code}
                </div>
              </div>
              <div className={classes.updateInfo2}>
                <div style={{ marginRight: "3%" }}>
                  <div style={{ textAlign: "right" }}>
                    {new Date(playerTwoVersion.updated_at).toLocaleDateString(
                      "en-US",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )}{" "}
                    {new Date(playerTwoVersion.updated_at).toLocaleTimeString(
                      [],
                      { hour: "2-digit", minute: "2-digit" }
                    )}
                  </div>
                  {playerTwoVersion.updated_by ? (
                    <div style={{ textAlign: "right" }}>
                      Updated By {playerTwoVersion.updated_by?.first_name}{" "}
                      {playerTwoVersion.updated_by?.last_name}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.CardContent3} />
          )}
        </div>
      ) : (
        <div className={classes.onTopcard}>
          <div
            style={{
              width: "100%",
              height: "46.5%",
              pointerEvents: opacityValueOne == 0 ? "none" : "",
              opacity: opacityValueOne == 0 ? "0.4" : "1",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <CardToolsGroup
              player={playerOne}
              opacityValue={opacityValueOne}
              changeOpacityValue={changeOpacityValueOne}
              currentDuration={currentDurationOne}
              onTimeSeek={setCurrentTimeSeekOne}
              handleOnPrevious={handleOnPrevious}
              handleOnNext={handleOnNext}
              refPlayer={refPlayerOne}
              volume={volumePlayerOne}
              onVolumeChange={(v) => setVolumeOne(v)}
              setPlayer={setPlayer}
              setCanvasModal={setCanvasModal}
              setScreenshot={setScreenshot}
              position="onTop"
              openModal={openModal}
              muted={mutedOne}
              onMute={onMuteOne}
            />
          </div>
          {/* middle seperator of div */}
          <div className={classes.div2}>
            <div className={classes.circle1}></div>
            <div className={classes.playButtondiv}>
              <Button
                onClick={playBoth}
                className={classes.playButton}
                style={{
                  pointerEvents: playerTwo.fileURL ? "" : "none",
                  opacity: playerTwo.fileURL ? "1" : "0.4",
                }}
              >
                Combine Play
              </Button>
            </div>
            <div className={classes.circle2}></div>
          </div>
          <div
            style={{
              width: "100%",
              height: "46.5%",
              pointerEvents: opacityValueTwo == 0 ? "none" : "",
              opacity: opacityValueTwo == 0 ? "0.4" : "1",
            }}
          >
            {playerTwo.fileURL ? (
              <CardToolsGroup
                player={playerTwo}
                opacityValue={opacityValueTwo}
                changeOpacityValue={changeOpacityValueTwo}
                currentDuration={currentDurationTwo}
                onTimeSeek={setCurrentTimeSeekTwo}
                handleOnPrevious={handleOnPrevious}
                handleOnNext={handleOnNext}
                refPlayer={refPlayerTwo}
                volume={volumePlayerTwo}
                onVolumeChange={(v) => setVolumeTwo(v)}
                setPlayerTwo={setPlayerTwo}
                versions={versions}
                setPlayer={setPlayer}
                setCanvasModal={setCanvasModal}
                setScreenshot={setScreenshot}
                type="two"
                position="bottom"
                openModal={openModal}
                muted={mutedTwo}
                onMute={onMuteTwo}
              />
            ) : (
              <div
                className={classes.selectVersion}
                onClick={() => openModal("2")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ transform: "rotate(-34.38deg)" }}>
                    <MouseSimple size={40} weight="duotone" />
                  </div>
                  <div>Click to Compare with a version</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
