import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    background: theme.palette.primary.main,
    height: "85%",
    width: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    position: "absolute",
    marginTop: "5%",
    marginLeft: "10%",
    overflowY: "auto",
  },
  modal1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.secondary.main,
    fontFamily: "Nunito Sans",
  },
  cardDiv: {
    backgroundColor: theme.palette.secondary.light,
  },
  editLogo: {
    margin: "2px",
    color: theme.palette.text.secondary,
    width: "15px",
    borderRadius: "2px",
  },
  editButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "4px",
    minWidth: "30px",
    height: "30px",
  },
  commentIconDiv: {
    width: "10%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.secondary.light,
    },
  },
  commentIcon: {
    margin: "0px",
    padding: "0px",
    height: "16px",
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  canvasDiv: {
    borderRadius: "10px",
    height: "95%",
    width: "95%",
    padding: "2% 5%",
    background: theme.palette.primary.main,
  },
}));

export default useStyles;
