import { Button, Card, CardContent, Modal } from "@material-ui/core";
import { ArrowsDownUp, X } from "phosphor-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { fdelete, fget } from "../../../../../API/callsAPI";
import DeleteModal from "../../../../Utilities/DeleteModal/DeleteModal.component";
import ManageTaskPhasesComponent from "../../TaskTemplateUtilities/ManageTaskPhasesComponent/ManageTaskPhasesComponent";
import RearrangeTaskPhases from "../../TaskTemplateUtilities/RearrangeTaskPhases/RearrangeTaskPhases.component";
import TemplateDetails from "../../TaskTemplateUtilities/TaskTemplateDetails/TemplateDetails.component";
import NewOrEditTaskPhaseModal from "../NewOrEditTaskPhaseModal/NewOrEditTaskPhaseModal.component";
import useStyles from "./ManageTaskPhaseModal.style";

const ManageTaskPhaseModal = ({
  templateDetails,
  departments,
  handleClose,
  handleDeleteTaskTemplateInfo,
  handleEditTaskTemplateModalInfo,
  setSnackValue,
}) => {
  const classes = useStyles();
  const [currentView, setCurrentView] = useState("managePhases");
  const [isNewTaskPhaseModalOpen, setIsNewTaskPhaseModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [phases, setPhases] = useState([]);
  const [editTasksPhaseInfo, setEditTasksPhaseInfo] = useState({
    selectedPhase: {},
    isModalOpen: false,
  });
  const [deleteTasksPhaseInfo, setDeleteTasksPhaseInfo] = useState({
    selectedPhase: {},
    isModalOpen: false,
  });
  const [refreshCounter, setRefreshCounter] = useState(0);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const handleNewTaskPhaseModalState = () => {
    setIsNewTaskPhaseModalOpen(!isNewTaskPhaseModalOpen);
  };

  const handleEditTaskPhaseInfo = (data) => {
    setEditTasksPhaseInfo({
      selectedPhase: data,
      isModalOpen: !editTasksPhaseInfo.isModalOpen,
    });
  };

  const handleDeleteTaskPhaseInfo = (data) => {
    setDeleteTasksPhaseInfo({
      selectedPhase: data,
      isModalOpen: !deleteTasksPhaseInfo.isModalOpen,
    });
  };

  const getPhases = async () => {
    try {
      const res = await fget({ url: `phase/?template=${templateDetails?.id}` });
      setPhases(res?.data?.results);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPhases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  const deletePhase = async () => {
    try {
      const res = await fdelete({
        url: `phase/${deleteTasksPhaseInfo?.selectedPhase?.id}/`,
      });
      if (res?.status === 200 || 201) {
        setSnackValue({
          isOpen: true,
          message: `successfully deleted task phase`,
          isError: false,
        });
        setDeleteTasksPhaseInfo({ selectedPhase: {}, isModalOpen: false });
        updateRefreshCounter();
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.detailsAndManageContainer}>
            <div style={{ width: "30%" }}>
              <TemplateDetails
                templateDetails={templateDetails}
                handleDeleteTaskTemplateInfo={handleDeleteTaskTemplateInfo}
                handleEditTaskTemplateModalInfo={
                  handleEditTaskTemplateModalInfo
                }
              />
            </div>
            <div className={classes.line}></div>
            <div style={{ width: "65%", height: "100%" }}>
              <div className={classes.cardTitle}>
                {currentView === "managePhases" ? (
                  <span className={classes.title}>Manage Task Phase</span>
                ) : (
                  <div className={classes.titleContainer}>
                    <ArrowsDownUp
                      size={25}
                      className={classes.rearrangeButton}
                    />
                    <span className={classes.title}>Rearrange</span>
                  </div>
                )}

                <div className={classes.titleButtons}>
                  {currentView === "managePhases" ? (
                    <Button
                      onClick={() => {
                        setCurrentView("rearrangePhase");
                      }}
                      className={classes.rearrangeButton}
                      disabled={phases.length === 0 ? true : false}
                    >
                      <ArrowsDownUp size={25} />
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button
                    className={classes.closeButton}
                    onClick={() => handleClose()}
                  >
                    <X size="30px" />
                  </Button>
                </div>
              </div>
              <br />
              {currentView === "managePhases" ? (
                <ManageTaskPhasesComponent
                  setIsNewTaskPhaseModalOpen={setIsNewTaskPhaseModalOpen}
                  isLoaded={isLoaded}
                  phases={phases}
                  departments={departments}
                  handleEditTaskPhaseInfo={handleEditTaskPhaseInfo}
                  handleDeleteTaskPhaseInfo={handleDeleteTaskPhaseInfo}
                  closeManagePhaseModal={handleClose}
                />
              ) : (
                <RearrangeTaskPhases
                  phases={phases}
                  departments={departments}
                  updateRefreshCounter={updateRefreshCounter}
                  setSnackValue={setSnackValue}
                  setCurrentView={setCurrentView}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      <div>
        <Modal
          className={classes.flexCenter}
          open={isNewTaskPhaseModalOpen}
          onClose={() => setIsNewTaskPhaseModalOpen(false)}
        >
          <NewOrEditTaskPhaseModal
            type="New"
            handleClose={handleNewTaskPhaseModalState}
            templateId={templateDetails?.id}
            updateRefreshCounter={updateRefreshCounter}
            departments={departments}
            setSnackValue={setSnackValue}
          />
        </Modal>
        <Modal
          className={classes.flexCenter}
          open={editTasksPhaseInfo.isModalOpen}
          onClose={() =>
            setEditTasksPhaseInfo({ selectedPhase: {}, isModalOpen: false })
          }
        >
          <NewOrEditTaskPhaseModal
            type="Edit"
            handleClose={() =>
              setEditTasksPhaseInfo({ selectedPhase: {}, isModalOpen: false })
            }
            selectedPhase={editTasksPhaseInfo?.selectedPhase}
            templateId={templateDetails?.id}
            updateRefreshCounter={updateRefreshCounter}
            departments={departments}
            setSnackValue={setSnackValue}
          />
        </Modal>
        <Modal
          className={classes.flexCenter}
          open={deleteTasksPhaseInfo.isModalOpen}
          onClose={() =>
            setDeleteTasksPhaseInfo({ selectedPhase: {}, isModalOpen: false })
          }
        >
          <DeleteModal
            type="Tasks Phase"
            name={deleteTasksPhaseInfo?.selectedPhase?.name}
            handleCloseDeleteModal={() => {
              setDeleteTasksPhaseInfo({
                selectedPhase: {},
                isModalOpen: false,
              });
            }}
            handleDelete={() => {
              deletePhase();
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ManageTaskPhaseModal;
