import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  divider: {
    marginRight: "17px",
    backgroundColor: theme.palette.text.tertiary,
  },
  notificationsItems: {
    height: "50px",
    width: "99%",
    padding: "20px 10px 20px 0px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "&:hover": {
      "& $originalElement": {
        visibility: "hidden",
      },
      "& $displayElement": {
        visibility: "visible",
      },
    },
  },
  notificationsItemsUnread: {
    height: "50px",
    width: "99%",
    padding: "20px 10px 20px 0px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    background: theme.palette.secondary.light,
    "&:hover": {
      "& $originalElement": {
        visibility: "hidden",
      },
      "& $displayElement": {
        visibility: "visible",
      },
    },
  },
  notificationDrop: {
    color: theme.palette.secondary.main,
    marginLeft: "5px",
    marginRight: "5px",
    height: "20px",
    minWidth: "20px",
  },
  descriptionContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  description: {
    color: theme.palette.text.primary,
    textAlign: "start",
    width: "40%",
    padding: "5px",
    fontFamily: "Poppins",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  description2: {
    display: "flex",
    alignItems: "center",
  },
  thumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  singleUser: {
    display: "flex",
    alignItems: "center",
    border: `1px solid grey`,
    borderRadius: "15px",
  },
  hoverContainer: {
    width: "15%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  originalElement: {
    visibility: "visible",
    fontFamily: "Poppins",
    fontSize: "12px",
    color: theme.palette.text.tertiary,
  },
  displayElement: {
    width: "35%",
    display: "flex",
    justifyContent: "space-between",
    visibility: "hidden",
    position: "relative",
    left: 100,
  },
  notdisplayElement: {
    width: "35%",
    display: "flex",
    justifyContent: "space-between",
    visibility: "hidden",
    position: "relative",
    left: 100,
  },
  iconBtn: {
    minWidth: "30px",
  },
}));

export default useStyles;
