import React, { useState, useEffect } from "react";
import { Input } from "@material-ui/core";
import { Button, MenuItem } from "@material-ui/core";
import { X, Search } from "react-feather";
import useStyles from "./NewTask.styles.js";
import { fget } from "../../../API/callsAPI";
import NoThumbnail from "../../Utilities/no-thumbnail.png";

const ThumbNail = (props) => {
  return (
    <img
      src={props.data ? props.data : NoThumbnail}
      width="32px"
      height="32px"
      alt="thumbnail"
      style={
        props.data
          ? { borderRadius: "10px", marginRight: "5px" }
          : { marginRight: "5px" }
      }
    />
  );
};

// trackable list component
const TrackableList = (props) => {
  const classes = useStyles();

  // function to get the assets-trackables
  const assetList = () => {
    return (
      <>
        {props.assetList
          ?.filter((search) => {
            if (props.searchQuery === undefined) {
              return search;
            } else if (props.searchQuery === "") {
              return search;
            } else if (
              search.name
                ?.toLowerCase()
                .includes(props.searchQuery.toLowerCase())
            ) {
              return search;
            } else return null;
          })
          .map((asset) => (
            <>
              <MenuItem
                value={asset.id}
                onClick={() => {
                  props.setSelectedTrackable({
                    id: asset.id,
                    name: asset.name,
                  });
                  props.setAnchorEl(false);
                }}
              >
                <ThumbNail data={asset.thumbnail} />
                {asset.name}
              </MenuItem>

              {props.assetList[props.assetList.length - 1] === asset ? (
                props.sequenceList.length ? (
                  props.findAsset ? (
                    props.findSequence ? (
                      <hr className={classes.horizontalLine} />
                    ) : (
                      ""
                    )
                  ) : (
                    <hr className={classes.horizontalLine} />
                  )
                ) : (
                  ""
                )
              ) : (
                <hr className={classes.horizontalLine} />
              )}
            </>
          ))}
      </>
    );
  };

  // function to get sequences-trackables
  const sequenceList = () => {
    return (
      <>
        {props.sequenceList
          ?.filter((search) => {
            if (props.searchQuery === undefined) {
              return search;
            } else if (props.searchQuery === "") {
              return search;
            } else if (
              search.code
                ?.toLowerCase()
                .includes(props.searchQuery.toLowerCase())
            ) {
              return search;
            } else return null;
          })
          .map((sequence) => (
            <>
              <MenuItem
                value={sequence.id}
                onClick={() => props.setSelectedSequence(sequence)}
              >
                <ThumbNail data={sequence.thumbnail} />
                {sequence.code}
              </MenuItem>
              {props.sequenceList[props.sequenceList.length - 1] ===
              sequence ? (
                ""
              ) : (
                <hr className={classes.horizontalLine} />
              )}
            </>
          ))}
      </>
    );
  };

  // function to list both assets and sequences
  const allTrackableList = () => {
    return (
      <>
        {assetList()}
        {sequenceList()}
      </>
    );
  };

  return (
    <>
      <Button
        className={
          props.findAsset
            ? classes.findTrackableSelect
            : classes.findTrackableDeselect
        }
        onClick={() => {
          props.setFindAsset(!props.findAsset);
        }}
      >
        find assets:
      </Button>
      <Button
        className={
          props.findSequence
            ? classes.findTrackableSelect
            : classes.findTrackableDeselect
        }
        onClick={() => props.setFindSequence(!props.findSequence)}
      >
        find sequences:
      </Button>
      <div style={{ height: "12rem", overflow: "auto" }}>
        {!props.findAsset && !props.findSequence && allTrackableList()}
        {props.findAsset && assetList()}
        {props.findSequence && sequenceList()}
      </div>
    </>
  );
};

// sequence shots list  component
const ShotList = (props) => {
  const [shots, setShots] = useState();
  const classes = useStyles();

  useEffect(() => {
    getShot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShot = () => {
    fget({
      url: `trackables/shot/?project=${props.selectedSequence.project}&parent_sequence=${props.selectedSequence.id}`,
    })
      .then((res) => {
        setShots(res.data.results);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div style={{ height: "12rem", overflow: "auto", marginTop: "1rem" }}>
        {shots?.map((shot) => (
          <>
            <MenuItem
              value={shot.id}
              onClick={() => {
                props.setSelectedTrackable({
                  id: shot.id,
                  name: shot.code,
                });
                props.setAnchorEl(false);
              }}
            >
              <ThumbNail data={shot.thumbnail} />
              {shot.code}
            </MenuItem>
            {shots[shots.length - 1] === shot ? (
              ""
            ) : (
              <hr className={classes.horizontalLine} />
            )}
          </>
        ))}
      </div>
    </>
  );
};

// main component
const SelectTrackablesModal = (props) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={classes.searchDiv}>
          <Search size="18px" style={{ marginLeft: "10px" }} />
          <Input
            className={classes.searchInput}
            disableUnderline="false"
            placeholder={props.selectedSequence ? "" : "Search Trackables"}
            disabled={!!props.selectedSequence}
            onChange={(e) => props.onChangeSearchQuery(e)}
          />
          <div
            className={
              props.selectedSequence
                ? classes.sequenceSelectorVisible
                : classes.sequenceSelectorHidden
            }
          >
            <Button onClick={() => props.setSelectedSequence()}>
              <X size="16px" />
              <span style={{ marginBottom: "1px", marginLeft: "3px" }}>
                sequence:
              </span>
            </Button>
            <span style={{ paddingRight: "5px" }}>
              {props.selectedSequence?.code}
            </span>
          </div>
        </div>
        <Button
          style={{
            display: "grid",
            alignContent: "center",
            marginLeft: "1.5rem",
            marginTop: "0.8rem",
            minWidth: "10px",
          }}
          onClick={() => props.setAnchorEl(false)}
        >
          <X size="22px" />
        </Button>
      </div>
      {props.selectedSequence ? (
        <ShotList
          selectedSequence={props.selectedSequence}
          setSelectedSequence={props.setSelectedSequence}
          setSelectedTrackable={props.setSelectedTrackable}
          setAnchorEl={props.setAnchorEl}
        />
      ) : (
        <TrackableList
          assetList={props.assetList}
          sequenceList={props.sequenceList}
          setSelectedTrackable={props.setSelectedTrackable}
          setAnchorEl={props.setAnchorEl}
          findAsset={props.findAsset}
          setFindAsset={props.setFindAsset}
          findSequence={props.findSequence}
          setFindSequence={props.setFindSequence}
          setSelectedSequence={props.setSelectedSequence}
          searchQuery={props.searchQuery}
        />
      )}
    </>
  );
};

export default SelectTrackablesModal;
