import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "./SortModal.style.js";
import { X, ArrowUp } from "react-feather";

export default function SortModal({ closeModal, onSubmit, defaultvalue }) {
  const classes = useStyles();

  const [value, setValue] = useState();
  const [isUp, setIsUp] = useState(true);

  const handleChange = (value) => {
    setIsUp(!isUp);
    setValue(value);
  };

  useEffect(() => {
    if (defaultvalue === "Z-A" || defaultvalue === "LastModified") {
      setIsUp(false);
    }
    setValue(defaultvalue);
  }, [defaultvalue]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.flexDiv1}>
            <span className={classes.title}>Sort</span>
            <Button className={classes.cross}>
              <X size="30px" onClick={() => closeModal()} />
            </Button>
          </div>
          <br />
          <div className={classes.flexDiv} style={{ marginBottom: "15px" }}>
            <span
              className={classes.sortName}
              style={{
                color:
                  value === "Z-A" || value === "A-Z" ? "#F9F9F9" : "#606479",
              }}
              onClick={() => handleChange(isUp ? "Z-A" : "A-Z")}
            >
              Alphabetical Order
            </span>

            {(value === "Z-A" || value === "A-Z") && (
              <Button
                className={classes.cross}
                onClick={() => handleChange(isUp ? "Z-A" : "A-Z")}
              >
                <ArrowUp
                  style={{
                    transform: `rotate(${isUp ? "0deg" : "180deg"})`,
                    transition: "transform 0.6s ease-in-out",
                    color: "#6C65D9",
                  }}
                />
              </Button>
            )}
          </div>
          <div className={classes.flexDiv}>
            <span
              className={classes.sortName}
              style={{
                color:
                  value === "LastModified" || value === "LatestModified"
                    ? "#F9F9F9"
                    : "#606479",
              }}
              onClick={() =>
                handleChange(isUp ? "LastModified" : "LatestModified")
              }
            >
              Date Modified
            </span>
            {(value === "LatestModified" || value === "LastModified") && (
              <Button
                className={classes.cross}
                onClick={() =>
                  handleChange(isUp ? "LastModified" : "LatestModified")
                }
              >
                <ArrowUp
                  style={{
                    transform: `rotate(${isUp ? "0deg" : "180deg"})`,
                    transition: "transform 0.6s ease-in-out",
                    color: "#6C65D9",
                  }}
                />
              </Button>
            )}
          </div>
          {/* <FormControl component="fieldset">
            <RadioGroup
              // aria-label="gender"
              // name="gender1"
              value={value}
              onChange={(event) => handleChange(event)}
              defaultValue={defaultvalue}
            >
              <FormControlLabel
                className={classes.textColor}
                value="A-Z"
                control={<Radio />}
                label="A-Z"
              />
              <FormControlLabel
                className={classes.textColor}
                value="Z-A"
                control={<Radio />}
                label="Z-A"
              />
              <FormControlLabel
                className={classes.textColor}
                value="LatestModified"
                control={<Radio />}
                label="Latest Modified"
              />
              <FormControlLabel
                className={classes.textColor}
                value="DateCreated"
                control={<Radio />}
                label="Date Created"
              />
            </RadioGroup>
          </FormControl> */}
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Button
            className={classes.sortBtn}
            size="small"
            onClick={() => onSubmit(value)}
          >
            Sort
          </Button>
          <Button
            onClick={() => onSubmit("DateCreated")}
            style={{
              backgroundColor: "transparent",
              midWidth: "0px",
              padding: "5px",
              textDecoration: "underline",
            }}
          >
            Clear Sorts
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
