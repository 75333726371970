import { Cookie } from "phosphor-react";
import React from "react";

const PriorityViewer = ({ priority }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <span>{priority}</span>
      {priority === "Low" && (
        <>
          <Cookie size={20} style={{ color: "#FC5959" }} />
        </>
      )}
      {priority === "Medium" && (
        <>
          <Cookie size={20} style={{ color: "#FC5959" }} />
          <Cookie size={20} style={{ color: "#FC5959" }} />
        </>
      )}
      {priority === "High" && (
        <>
          <Cookie size={20} style={{ color: "#FC5959" }} />
          <Cookie size={20} style={{ color: "#FC5959" }} />
          <Cookie size={20} style={{ color: "#FC5959" }} />
        </>
      )}
    </div>
  );
};

export default PriorityViewer;
