import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  assetVersionListPageTableCell: {
    color: theme.palette.text.primary,
    padding: "0% !important",
    overflow: "hidden",
    borderCollapse: "collapse",
    width: "17%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  assetsVersionModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
