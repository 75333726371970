//Library
import React from "react";
import ProjectsPageOverview from "../../Components/ProjectPage/ProjectPageOverview/ProjectPageOverview.component.jsx";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
//Utils
import { fget, fgetBFF } from "../../API/callsAPI";
import Loader from "../../Components/Utilities/Loader/Loader.jsx";

export default function ProjectsPage(props) {
  //States
  const [projectItems, setProjectItems] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshToken, setRefreshToken] = useState(0);

  useEffect(() => {
    //fetchProjectsBff();
    fetchProjects();
  }, [refreshToken]);

  const updateRefreshToken = () => {
    setRefreshToken(refreshToken + 1);
  };

  //fetch projects
  const fetchProjects = async (page = currentPage) => {
    try {
      const projectsRes = await fget({
        url: `project/?page=${page}`,
      });
      setProjectItems(projectsRes.data.results);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      setError(error.response ? error.response.data.detail : error.message);
    }
  };

  return (
    <div>
      {isLoaded ? (
        <ProjectsPageOverview
          ProjectItems={projectItems}
          searchQuery={props.searchQuery}
          updateRefreshToken={updateRefreshToken}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
}

ProjectsPage.propTypes = {
  searchQuery: PropTypes.string,
};
