import { Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fget } from "../../../API/callsAPI";
import DepartmentDetailsTaskPage from "../DepartmentDetailsTask/DepartmentDetailsTaskPage.component";
import DepartmentDetailsUsers from "../DepartmentDetailsUsers/DepartmentDetailsUsers.component";
import useStyles from "./DepartmentDetailsOverview.styles";
import { Alert } from "@material-ui/lab";
import { reOrderTask } from "../../MyTasks/TaskPageOverview/TaskPageUtilities";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";

export default function DepartmentDetailsOverview(props) {
  const classes = useStyles();
  const { deptID, projectId } = props.params;
  const [departmentInfo, setDepartmentInfo] = useState({});
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [isUpdating, setIsUpdating] = useState(false);
  // users component states
  const [allUsers, setAllUsers] = useState([]);
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [departmentAdmin, setDepartmentAdmin] = useState([]);
  // task page states
  const [taskList, setTaskList] = useState([]);
  const [isTasksLoaded, setIsTasksLoaded] = useState(false);
  const [showMyTasks, setShowMyTasks] = useState(false);

  useEffect(() => {
    getDepartmentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(departmentInfo).length !== 0) fetchUsers();
  }, [departmentInfo]);

  useEffect(() => {
    fetchTask();
  }, [showMyTasks]);

  const getDepartmentInfo = async () => {
    try {
      const deptInfo = await fget({
        url: `department/${deptID}`,
      });
      setDepartmentInfo(deptInfo.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: `project/${projectId}/users/`,
      });
      let temp = [];
      usersRes.data.results.map((el) =>
        temp.push({
          label: el?.first_name + " " + el?.last_name,
          value: el?.id,
          avatar: el?.avatar,
        })
      );
      setAllUsers(temp);
      let usersAlready =
        departmentInfo.users &&
        temp.filter((el) => departmentInfo.users.includes(el.value));

      let adminAlready =
        departmentInfo.admin_users &&
        temp.filter((el) => departmentInfo.admin_users.includes(el.value));
      setDepartmentAdmin(adminAlready);
      setDepartmentUsers(usersAlready);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTask = async () => {
    // setIsTasksLoaded(false);
    try {
      const tasksRes = await fget({
        url: `trackables/task/?project=${projectId}&department=${deptID}&assigned=${
          showMyTasks ? 1 : 0
        }`,
      });
      setTaskList(tasksRes.data.results);
      setIsTasksLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ paddingRight: "1.5%", height: "calc(100vh - 130px)" }}>
      {isUpdating ? (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
        >
          <Alert severity="info">Updating Please Wait</Alert>
        </Snackbar>
      ) : (
        <ErrorOrSuccessMessage snackValue={snackValue} />
      )}
      <div style={{ height: "6%" }}>
        <BreadCrumb shotCode={departmentInfo ? departmentInfo.name : ""} />
      </div>
      <div style={{ height: "30%" }}>
        <DepartmentDetailsUsers
          departmentId={deptID}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          setSnackValue={setSnackValue}
          allUsers={allUsers}
          departmentUsers={departmentUsers}
          departmentAdmin={departmentAdmin}
          setDepartmentUsers={setDepartmentUsers}
          setDepartmentAdmin={setDepartmentAdmin}
          getDepartmentInfo={getDepartmentInfo}
        />
      </div>
      <div style={{ marginTop: "10px", height: "64%" }}>
        <DepartmentDetailsTaskPage
          groupedTaskList={reOrderTask(taskList)}
          projectId={projectId}
          isTasksLoaded={isTasksLoaded}
          showMyTasks={showMyTasks}
          setShowMyTasks={setShowMyTasks}
        />
      </div>
    </div>
  );
}
