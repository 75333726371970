// Library
import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { darkTheme, lightTheme } from "./theme";

//Pages
import ProjectsPage from "./Pages/ProjectsPage/ProjectsPage.component";
import AssetsPage from "./Pages/AssetsPage/AssetsPage.component";
import SidebarAndNavbar from "./Components/SidebarAndNavbar/SidebarAndNavbar.component";

import Updates from "./Pages/UpdatesPage/Updates.component";
import NotificationsPage from "./Components/Notifications/NotificationsPage.component";
import AssetVersions from "./Pages/AssetVersions/AssetVersions.component";
import SequencePage from "./Pages/SequencePage/SequencePage.component";
import ShotsPage from "./Pages/ShotsPage/ShotsPage.component";
import ShotVersion from "./Pages/ShotVersions/ShotVersion.Component";
import { auth, getPayload, readCookie } from "./Components/authorization";
import Departments from "./Pages/DepartmentPage/Department.component";
import DepartmentDetail from "./Pages/DepartmentDetails/DepartmentDetail.component";
import ComparePage from "./Pages/ComparePage/ComparePage.component";
import Response404 from "./Pages/InformativeComponents/404Response.compnent";
import UnAuthorized from "./Pages/InformativeComponents/UnAuthorized.component";
import SettingsPage from "./Pages/SettingsPage/SettingsPage";
import Login from "./Pages/LoginPage/LoginPage.Component";
import TaskComponentOverview from "./Components/MyTasks/TaskPageOverview/TaskPageOverview.component";
import "./App.css";
import Sequencer from "./Pages/Sequencer/Sequencer.component";
import PlaylistPage from "./Pages/PlaylistPage/PlaylistPage.component";
const REACT_APP_BASE_URL_SSOF = process.env.REACT_APP_BASE_URL_SSOF;
const REACT_APP_BASE_URL_MCF = process.env.REACT_APP_BASE_URL_MCF;
const SERVICE_IDENTIFIER = process.env.SERVICE_IDENTIFIER;

function App() {
  const [loggedIn, setloggedIn] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") === null
      ? "dark"
      : localStorage.getItem("theme")
  );
  const [searchQuery, setSearchQuery] = useState("");
  const showSeqExperimental = localStorage.getItem("sequencerExp");
  const toggleTheme = () => {
    // if the theme is not light, then set it to dark
    if (theme === "light") {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
      // otherwise, it should be light
    } else {
      localStorage.setItem("theme", "light");
      setTheme("light");
    }
    window.location.reload();
  };
  const accesstoken = readCookie("access");
  let authorized = false;
  useEffect(() => {
    
    if (accesstoken) {
      setloggedIn(true);
      const payload = getPayload(accesstoken);
  
      if (payload.aud.length) {
        for (let i = 0; i < payload.aud.length; i++) {
          if (payload.aud[i] === SERVICE_IDENTIFIER) {
            authorized = true;
          }
        }
      }
    }else{
      setloggedIn(false);
    }
  
    return () => {
      
    }
  }, [])
  
  //<ErrorHandler FallbackComponent={ErrorHandler}></ErrorHandler>
  const handleSearchQuery = (inputSearchString) => {
    setSearchQuery(inputSearchString);
  };

  const projectPageComponent = () => {
    return <ProjectsPage searchQuery={searchQuery} />;
  };

  const assetsPageComponent = (routerParams) => {
    return <AssetsPage {...routerParams} searchQuery={searchQuery} />;
  };

  const assetsVersionComponent = (routerParams) => {
    return <AssetVersions {...routerParams} searchQuery={searchQuery} />;
  };

  const sequencePageComponent = (routerParams) => {
    return <SequencePage {...routerParams} searchQuery={searchQuery} />;
  };

  const shotsPageComponent = (routerParams) => {
    return <ShotsPage {...routerParams} searchQuery={searchQuery} />;
  };

  const shotsVersionComponent = (routerParams) => {
    return <ShotVersion {...routerParams} searchQuery={searchQuery} />;
  };

  const departmentPageComponent = (routerParams) => {
    return <Departments {...routerParams} searchQuery={searchQuery} />;
  };

  const playlistPageComponent = (routerParams) => {
    return <PlaylistPage {...routerParams} searchQuery={searchQuery} />;
  };

  const SequencerPageComponent = (routerParams) => {
    return <Sequencer {...routerParams} />;
  };

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <Switch>
        <>
          {loggedIn || document.cookie !== "" ? (
            <>
              {!auth(SERVICE_IDENTIFIER) ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    background: "black",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    You don't have access to this service <br />
                  </p>
                </div>
              ) : (
                <>
                  <SidebarAndNavbar
                    themeToggler={toggleTheme}
                    handleSearchQuery={handleSearchQuery}
                  >
                    <Switch>
                      <Route exact path="/" component={projectPageComponent} />
                      <Route
                        exact
                        path="/:projectId/tasks"
                        component={(routerParams) => (
                          <TaskComponentOverview
                            {...routerParams}
                            trackableType="task"
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/:projectId/updates"
                        component={Updates}
                      />
                      <Route
                        exact
                        path="/notifications"
                        component={NotificationsPage}
                      />
                      <Route
                        exact
                        path="/:projectId/sequence"
                        component={sequencePageComponent}
                      />
                      <Route
                        exact
                        path="/:projectId/playlist"
                        component={playlistPageComponent}
                      />
                      {showSeqExperimental ? (
                        <Route
                          exact
                          path="/:projectId/playlist/:playlistId/sequencer"
                          component={SequencerPageComponent}
                        />
                      ) : (
                        ""
                      )}

                      <Route
                        exact
                        path="/:projectId/departments"
                        component={departmentPageComponent}
                      />
                      <Route
                        exact
                        path="/:projectId/departments/:deptID/department"
                        component={(routerParams) => (
                          <DepartmentDetail {...routerParams} />
                        )}
                      />
                      <Route
                        exact
                        path="/:projectId/sequence/:sid/shots"
                        component={shotsPageComponent}
                      />
                      <Route
                        exact
                        path="/:projectId/sequence/:sid/shots/:shotID/shotversions"
                        component={shotsVersionComponent}
                      />
                      <Route
                        exact
                        path="/:projectId/sequence/:sid/shots/:shotID/shotversions/:shotVID/compare"
                        component={(routerParams) => (
                          <ComparePage {...routerParams} trackableType="shot" />
                        )}
                      />
                      <Route
                        exact
                        path="/:projectId/assets"
                        component={assetsPageComponent}
                      />
                      <Route
                        exact
                        path="/:projectId/assets/:assetID/assetversions"
                        component={assetsVersionComponent}
                      />
                      <Route
                        exact
                        path="/:projectId/assets/:assetID/assetversions/:avID/assetversion"
                        component={(routerParams) => (
                          <AssetVersions {...routerParams} />
                        )}
                      />
                      <Route
                        exact
                        path="/:projectId/assets/:assetID/assetversions/:avID/assetversions/compare"
                        component={(routerParams) => (
                          <ComparePage
                            {...routerParams}
                            trackableType="asset"
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/:projectId/assets/:Id/task"
                        component={(routerParams) => (
                          <TaskComponentOverview
                            {...routerParams}
                            trackableType="asset"
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/:projectId/sequence/:sid/shots/:Id/task"
                        component={(routerParams) => (
                          <TaskComponentOverview
                            {...routerParams}
                            trackableType="shot"
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/:projectId/settings"
                        component={SettingsPage}
                      />
                      <Route path="*" component={Response404}></Route>
                    </Switch>
                  </SidebarAndNavbar>
                </>
              )}
            </>
          ) : (
            <>
              <Route path="/" exact>
                <Login setloggedIn={setloggedIn} />
              </Route>
            </>
          )}
        </>
      </Switch>
    </ThemeProvider>
  );
}

export default App;
