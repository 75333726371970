import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.text.primary,
    margin: "5px 10px",
  },
  assetPageToolsGrid: {
    display: "flex",
    alignItems: "center",
  },
  titleAndSiderContainer: {
    display: "flex",
    alignItems: "center",
    width: "40%",
  },
  dropDown: {
    backgroundColor: theme.palette.secondary.light,
  },
  // assetPageButton: {
  //   textTransform: "none",
  //   height: "2.5rem",
  //   width: "100%",
  //   color: theme.palette.text.primary,
  //   // margin: "0px 2px",
  //   "& :first-child": {
  //     color: theme.palette.text.primary,
  //     margin: "8px 1px",
  //   },
  //   "& :last-child": {
  //     margin: "8px",

  //     fontSize: "1.2rem",
  //   },
  // },

  // searchInput: {
  //   padding: "0.2px 6px",
  //   width: "20%",
  //   background: theme.palette.secondary.light,
  //   fontFamily: "Nunito Sans",
  //   fontStyle: "normal",
  // },

  formControl: {
    alignItems: "center",
    justifyContent: "center",
    width: "250px",
    height: "40px",
    background: theme.palette.secondary.light,
    borderRadius: "10px",
  },

  dropdownDiv: {
    display: "flex",
    alignItems: "center",
    width: "250px",
    justifyContent: "space-between",
  },
  selectText: {
    marginLeft: "10px",
    fontFamily: "Nunito Sans",
    opacity: "0.7",
    fontSize: "12px",
    color: theme.palette.text.primary,
  },

  plusIcon: {
    width: "28px",
    height: "19px",
  },

  iconStyle: {
    marginRight: "10px",
    height: "20px",
    color: theme.palette.secondary.main,
  },
  formLabelText: {
    // padding: "0.2px 6px",
    height: "0%",
    width: "100%",
    opacity: "0",
    background: theme.palette.secondary.light,
  },

  assetsPageNewAssetButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "auto",
    borderRadius: "10px",
    fontSize: "14px",
    padding: "12px",
    color: theme.palette.text.secondary,
  },

  sliderDiv: {
    display: "flex",
    alignItems: "center",
  },

  slider: {
    width: 200,
    alignItems: "center",
    color: theme.palette.secondary.main,
    height: "0",
  },
  // formControl: {
  //   display: "flex",
  //   margin: theme.spacing(1),
  //   width: "250px",
  //   borderRadius: "6px",
  //   height: "30px",
  //   background: theme.palette.secondary.light,
  // },

  inputSelect: {
    height: "35px",
    display: "flex",
  },
}));

export default useStyles;
