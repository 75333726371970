import { Button, Tooltip, Modal, Card } from "@material-ui/core";
import { PaperPlaneTilt } from "phosphor-react";
import * as Icon from "react-feather";
import React, { useState } from "react";
import SkeletonDiv from "./SkeletonDiv";
import DeleteModal from "../../DeleteModal/DeleteModal.component";
import PostEditor from "../../../PostEditor/postEditor";
import Divider from "@material-ui/core/Divider";
import useStyles from "./CommentOverview.styles";
import { fdelete } from "../../../../API/callsAPI";
import ErrorOrSuccessMessage from "../../ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import UserAvatar from "../../UserAvatar/UserAvatar.component";

// make dragable comment here
// add skeleton loader as well
export default function CommentsOverview({
  commentList,
  setCommentList,
  loggedInUser,
  setTaskNameFromComment,
  fetchComments,
  allUsersName,
  handleSubmit,
  changeSeekTime,
  matchHeight,
}) {
  const [open, setOpen] = useState(false);
  const [displayImage, setDisplayImage] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [repliedTo, setRepliedTo] = useState({});
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  let mentionsRef = React.createRef();
  let replyRef = React.createRef();
  const classes = useStyles();

  const deleteComment = (comment) => {
    setSelectedCommentId(comment.id);
    setDeleteCommentModalOpen(true);
  };

  const scrollTo = (comment) => {
    const scrollItem = document.getElementById(comment?.replied_to?.id);
    console.log("clicked", scrollItem);
    scrollItem &&
      scrollItem.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleDeleteComment = async () => {
    try {
      await fdelete({
        url: `trackables/trackable-comment/${selectedCommentId}/`,
      });
      fetchComments()
        .then((res) =>
          setCommentList({
            data: res,
            loading: false,
          })
        )
        .catch((err) => console.log(err));
      setDeleteCommentModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted comment`,
        isError: false,
      });
      return "deleted";
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  function handleKeyDown(event) {
    event.stopPropagation();
  }

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <div className={classes.comment}>
        <span>Comments</span>
        <span style={{ marginRight: "18px" }}>
          {commentList.data.length} comments
        </span>
      </div>
      <div
        style={{
          height: matchHeight ? "calc(100% - 107px)" : "calc(100% - 97px)",
          overflowY: "scroll",
          marginBottom: "12px",
        }}
      >
        {/* single comment */}
        {commentList.loading ? (
          <div style={{ margin: "15px 5px" }}>
            {/* calling this component 3 times to fill up comments area */}
            <SkeletonDiv />
            <SkeletonDiv />
            <SkeletonDiv />
          </div>
        ) : (
          commentList.data.map((eachComment) => (
            <>
              <div
                id={eachComment.id}
                style={{
                  marginTop: "8px",
                }}
              >
                <div className={classes.commentHead}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UserAvatar
                      avatar={eachComment?.author?.avatar}
                      firstName={eachComment?.author?.first_name[0]}
                      lastName={eachComment?.author?.last_name[0]}
                    />
                    <span style={{ margin: "0px 8px" }}>
                      {eachComment.author.first_name}{" "}
                      {eachComment.author.last_name}
                    </span>
                  </div>
                  <span
                    className={classes.time}
                    style={{
                      justifySelf: "flex-end",
                    }}
                  >
                    {new Intl.DateTimeFormat("en-US", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }).format(new Date(eachComment.created_at))}
                  </span>
                </div>

                {/* {console.log(eachComment.description.split("<p>")[0])} */}
                {eachComment.replied_to !== null ? (
                  <Card
                    style={{
                      marginLeft: "30px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      padding: "5px 15px",
                      gap: "15px",
                    }}
                    onClick={() => {
                      scrollTo(eachComment);
                    }}
                  >
                    {eachComment.replied_to.attachment ? (
                      <img
                        // className={classes.AssetImage}
                        style={{
                          marginLeft: "40px",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpen(true);
                          setDisplayImage(eachComment.replied_to.attachment);
                        }}
                        alt="projectImage"
                        src={eachComment.replied_to.attachment}
                        height="50px"
                        width="50px"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    ) : null}
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          eachComment.replied_to.description
                            .replace(/<p>/g, "<div>")
                            .replace(/<\/p>/g, "</div>")
                            .replace("0:0", "")
                            .slice(0, 15) + "...",
                      }}
                    ></span>
                    <div className={classes.time}>
                      {new Intl.DateTimeFormat("en-US", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }).format(new Date(eachComment.replied_to.created_at))}
                    </div>
                  </Card>
                ) : (
                  ""
                )}
                <div
                  style={{
                    display: "flex",
                    marginLeft: "40px",
                    justifyContent: "space-between",
                  }}
                >
                  {/* add show more on big comments */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {eachComment?.description?.split("<p>")[0] ? (
                      <span
                        style={{
                          // marginLeft: "40px",
                          cursor: "pointer",
                          color: "#008cdb",
                        }}
                        onClick={(e) => changeSeekTime(e)}
                      >
                        {eachComment?.description?.split("<p>")[0]}
                      </span>
                    ) : null}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: eachComment?.description
                          ?.split("<p>")[1]
                          ?.split("</p>")[0],
                      }}
                    ></span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <Tooltip title="Reply" placement="top-start">
                      <Button
                        style={{
                          padding: "0",
                          margin: "0",
                          minWidth: "25px",
                        }}
                        onClick={() => {
                          setRepliedTo(eachComment);
                          setShowReplyBox(true);
                        }}
                      >
                        <Icon.CornerDownLeft
                          style={{ hight: "16px", width: "16px" }}
                        />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Create New Task" placement="top-start">
                      <Button
                        style={{
                          padding: "0",
                          margin: "0",
                          minWidth: "25px",
                        }}
                        onClick={() => {
                          setTaskNameFromComment(eachComment.description);
                          console.log(eachComment.description);
                        }}
                      >
                        <Icon.CheckSquare
                          style={{ hight: "16px", width: "16px" }}
                        />
                      </Button>
                    </Tooltip>
                    {eachComment.author.id === loggedInUser?.user_id ? (
                      <Tooltip title="Delete Comment" placement="top-start">
                        <Button
                          style={{
                            padding: "0",
                            margin: "0",
                            minWidth: "25px",
                          }}
                          onClick={() => deleteComment(eachComment)}
                        >
                          <Icon.Trash2
                            style={{ hight: "16px", width: "16px" }}
                          />
                        </Button>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {eachComment.attachment ? (
                  <img
                    // className={classes.AssetImage}
                    style={{
                      marginLeft: "40px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpen(true);
                      setDisplayImage(eachComment.attachment);
                    }}
                    alt="projectImage"
                    src={eachComment.attachment}
                    height="190px"
                    width="358px"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                ) : null}
              </div>
              <Divider />
            </>
          ))
        )}
      </div>
      {showReplyBox ? (
        <div style={{ position: "relative" }} onKeyDown={handleKeyDown}>
          <Card
            className={classes.replyCard}
            onClick={() => {
              scrollTo(repliedTo);
            }}
          >
            {repliedTo.attachment ? (
              <img
                // className={classes.AssetImage}
                onClick={() => {
                  setOpen(true);
                  setDisplayImage(repliedTo.attachment);
                }}
                alt="projectImage"
                src={repliedTo.attachment}
                height="50px"
                width="50px"
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : null}
            <span
              dangerouslySetInnerHTML={{
                __html:
                  repliedTo.description
                    .replace(/<p>/g, "<div>")
                    .replace(/<\/p>/g, "</div>")
                    .replace("0:0", "")
                    .slice(0, 15) + "...",
              }}
            ></span>
            <div
              onClick={() => {
                setShowReplyBox(false);
              }}
            >
              <Icon.X size={20} />
            </div>
          </Card>
          <div
            style={{
              display: "flex",
            }}
          >
            <div className={classes.submitCommentField}>
              <PostEditor
                placeholder={"Type a Comment..."}
                mentions={allUsersName}
                ref={replyRef}
                handleSubmit={() =>
                  handleSubmit(
                    replyRef.current.toHtml(),
                    replyRef.current.onExtractMentions(),
                    repliedTo
                  ).then((res) => {
                    if (res) {
                      fetchComments()
                        .then((res) =>
                          setCommentList({ data: res, loading: false })
                        )
                        .catch((err) => console.log(err));
                      replyRef.current.reset();
                      setShowReplyBox(false);
                    }
                  })
                }
              />
            </div>
            <Button
              className={classes.submitButton}
              onClick={() =>
                handleSubmit(
                  replyRef.current.toHtml(),
                  replyRef.current.onExtractMentions(),
                  repliedTo
                ).then((res) => {
                  if (res) {
                    fetchComments()
                      .then((res) =>
                        setCommentList({ data: res, loading: false })
                      )
                      .catch((err) => console.log(err));
                    replyRef.current.reset();
                    setShowReplyBox(false);
                  }
                })
              }
            >
              <PaperPlaneTilt size={22} />
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }} onKeyDown={handleKeyDown}>
          <div className={classes.submitCommentField}>
            <PostEditor
              placeholder={"Type a Comment..."}
              mentions={allUsersName}
              ref={mentionsRef}
              handleSubmit={() =>
                handleSubmit(
                  mentionsRef.current.toHtml(),
                  mentionsRef.current.onExtractMentions()
                ).then((res) => {
                  if (res) {
                    fetchComments()
                      .then((res) =>
                        setCommentList({ data: res, loading: false })
                      )
                      .catch((err) => console.log(err));
                    mentionsRef.current.reset();
                  }
                })
              }
            />
          </div>
          <Button
            className={classes.submitButton}
            onClick={() =>
              handleSubmit(
                mentionsRef.current.toHtml(),
                mentionsRef.current.onExtractMentions()
              ).then((res) => {
                if (res) {
                  fetchComments()
                    .then((res) =>
                      setCommentList({ data: res, loading: false })
                    )
                    .catch((err) => console.log(err));
                  mentionsRef.current.reset();
                }
              })
            }
          >
            <PaperPlaneTilt size={22} />
          </Button>
        </div>
      )}

      <div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              outline: "none",
              height: "80vh",
            }}
          >
            <Icon.X
              style={{ alignSelf: "flex-end", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
            <img
              src={displayImage}
              height="90%"
              onContextMenu={(e) => e.preventDefault()}
              alt="from annotate"
            ></img>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          // className={classes.assetDetailsPageModals}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isDeleteCommentModalOpen}
          onClose={() => setDeleteCommentModalOpen(false)}
        >
          <DeleteModal
            type="Comment"
            name={selectedCommentId}
            handleCloseDeleteModal={() => {
              setDeleteCommentModalOpen(false);
            }}
            handleDelete={handleDeleteComment}
          />
        </Modal>
      </div>
    </>
  );
}
