import { Button, Slider } from "@material-ui/core";
import { Pause, Play, SpeakerHigh, SpeakerSlash } from "phosphor-react";
import React, { useState } from "react";
import { Download } from "react-feather";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "./CustomSeekBar.css";
//import components
import PlayerCard from "./PlayerCard/PlayerCard";

export default function CustomSeekBar({
  isPlaying,
  onTimeSeek,
  handlePlayPause,
  currentTime,
  annotations,
  totalDuration,
  seekForward,
  seekBack,
  onDownload,
  setCurrentMark, //sets the value of hovered over mark
  arrAnnotation, //hold the array of card to be displayed
  currentMark,
  scrollToComment,
  progress,
  volume,
  onVolumeChange,
  muted,
  onMute,
}) {
  const [showControls, setShowControls] = useState(false);

  const totalDurationCalc = (totalTime) => {
    let seconds = 0;
    let minutes = 0;
    if (totalTime % 60) {
      minutes = parseInt(totalTime / 60);
      seconds = parseInt(totalTime % 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
    }
    return `${minutes}:${seconds}`;
  };

  function handleKeyDown(event) {
    event.preventDefault();
  }

  const muiTheme = createMuiTheme({
    overrides: {
      MuiSlider: {
        thumb: {
          color: "#6C65D9",
        },
        track: {
          color: "#1B1D28",
          minWidth: "4px",
        },
        rail: {
          color: "#606479",
          minWidth: "4px",
        },
      },
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "15px",
        }}
        onKeyDown={handleKeyDown}
      >
        <Button
          onClick={handlePlayPause}
          style={{ minWidth: "40px", marginBottom: "5px", marginRight: "0" }}
        >
          {isPlaying ? (
            <Pause color="#6C65D9" weight="fill" size={25} />
          ) : (
            <Play color="#6C65D9" weight="fill" size={25} />
          )}
        </Button>
        <Button
          style={{
            minWidth: 0,
            padding: "3px",
            marginBottom: "3px",
            border: "1px solid white",
            marginRight: "8px",
          }}
          onClick={seekBack}
        >
          <Play
            color="#6C65D9"
            weight="fill"
            size={12}
            style={{ transform: "rotate(3.142rad)" }}
          />
        </Button>
        <Button
          style={{
            minWidth: 0,
            padding: "3px",
            marginBottom: "3px",
            border: "1px solid white",
            marginRight: "13px",
          }}
          onClick={seekForward}
        >
          <Play color="#6C65D9" weight="fill" size={12} />
        </Button>
        <div
          style={{
            width: "85%",
            height: "220px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            marginBottom: "180px",
          }}
          onMouseLeave={(e) => {
            if (currentMark !== null) {
              setCurrentMark(null);
            }
          }}
        >
          {/* display the card when hovered over a mark if the current mark value is equal to annotation value */}
          <div style={{ display: "flex", width: "100%", height: "185px" }}>
            {arrAnnotation.map((annotation) => {
              return currentMark == annotation.value ? (
                <PlayerCard
                  key={annotation.value}
                  scrollToComment={scrollToComment}
                  annotationValue={annotation.value}
                  annotationDetails={annotation.label}
                />
              ) : (
                ""
              );
            })}
          </div>
          {/* upon hovering over slider it checks if the cursor is on mark if it is then it...*/}
          {/*...sets the current mark as the node value */}
          <Slider
            min={0}
            max={100}
            step={1}
            style={{ width: "100%", color: "#6C65D9" }}
            value={(currentTime / totalDuration) * 100}
            onChange={(seekEvent, value) => {
              seekEvent.preventDefault();
              seekEvent.stopPropagation();
              onTimeSeek((value / 100) * totalDuration);
            }}
            marks={annotations}
            onMouseOver={(e) => {
              if (
                e.target.getAttribute("class") == "MuiSlider-mark" ||
                e.target.getAttribute("class") ==
                  "MuiSlider-mark MuiSlider-markActive"
              ) {
                let currentMark = e.target
                  .getAttribute("style")
                  ?.match(/\d/g)
                  ?.join("");
                setCurrentMark(currentMark);
              }
            }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "10px",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontSize: "12px" }}>{progress}</span>
            <span style={{ fontSize: "12px" }}>
              -{totalDurationCalc(totalDuration - currentTime)}
            </span>
          </div>
        </div>
        {/* show time, not in mockup yet */}
        {/* <span style={{ marginLeft: "10px", marginBottom: "5px" }}>
          {Math.floor(currentTime)} / {Math.floor(totalDuration)}
        </span> */}
        <div
          style={{
            height: "185px",
            width: "40px",
            marginBottom: "154px",
            display: "flex",
            flexDirection: "column",
            paddingTop: "5px",
            alignItems: "center",
            borderRadius: "1px",
            backgroundColor: showControls ? "#252A38" : "",
          }}
          onMouseLeave={() => setShowControls(false)}
        >
          {showControls ? (
            <ThemeProvider theme={muiTheme}>
              <Slider
                style={{
                  height: "145px",
                  width: "20px",
                  paddingLeft: "30px",
                }}
                color="secondary"
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: "slider-vertical",
                  },
                }}
                orientation="vertical"
                valueLabelDisplay="auto"
                min={0}
                max={100}
                step={1}
                value={volume}
                onChange={(seekEvent, value) => {
                  seekEvent.preventDefault();
                  seekEvent.stopPropagation();
                  onVolumeChange(value);
                }}
              />
            </ThemeProvider>
          ) : (
            <div
              style={{
                height: "150px",
                width: "100%",
              }}
            ></div>
          )}
          <Button
            style={{ minWidth: "40px", minHeight: "35px", marginTop: "0px" }}
            onMouseEnter={() => setShowControls(true)}
            onClick={() => onMute(!muted)}
          >
            {muted == false ? (
              <SpeakerHigh size={16} weight="fill" />
            ) : (
              <SpeakerSlash size={16} weight="fill" />
            )}
          </Button>
        </div>
        <Button
          style={{ minWidth: "0px", padding: "5px" }}
          onClick={() => onDownload()}
        >
          <Download />
        </Button>
      </div>
      {/* either use div as pointer ir slider lable */}
      {/* <div
        style={{
          marginTop: "-1px",
          display: "flex",
          width: "90%",
          height: "2px",
          marginLeft: "40px",
          zIndex: "12",
        }}
      >
        <div style={{ width: "10%", backgroundColor: "red" }}></div>
        <div style={{ width: "10%", backgroundColor: "red" }}></div>
      </div> */}
    </>
  );
}
