import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { TableCell, TableRow, Button } from "@material-ui/core";
import useStyles from "../TaskPageListViewOverview.styles";
import { PencilSimpleLine } from "phosphor-react";
import StatusDropDown from "../../../../Utilities/StatusDropDown/StatusDropDown.component";
import NoThumbnail from "../../../../Utilities/NoThumbnail.component";
import UsersViewer from "../../../../Utilities/UsersViewer/UsersViewer";
import TaskVersionHover from "../../../../Utilities/TaskVersionHover/TaskVersionHover";
import { TrackablesAndIcons } from "../../../../Settings/Utility/TrackablesDetails";
import PriorityViewer from "../../../../Utilities/PriorityViewer/PriorityViewer.component";

const TaskPageListItems = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const ref = useRef(null);

  const [assignedUser, setAssignedUser] = useState([]);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (props.singleTask.assigned_users.length > 0) {
      setAssignedUser(props.singleTask?.assigned_users);
    }
  }, [props.singleTask]);

  const getTaskUser = (user) => {
    setAssignedUser(user);
  };

  const removeTaskUser = (user) => {
    handleRemoveUser(assignedUser.filter((sUser) => sUser.id !== user.id));
  };

  const handleRemoveUser = (currUsers) => {
    let allId = [];
    if (currUsers) {
      currUsers?.map((eachUser) => allId.push(eachUser.id));
    }
    props.handleTaskUpdate(props?.singleTask?.id, { assigned_users: allId });
  };

  const handleSubmit = () => {
    let allId = [];
    if (assignedUser) {
      assignedUser?.map((eachUser) => allId.push(eachUser.id));
    }
    props.handleTaskUpdate(props?.singleTask?.id, { assigned_users: allId });
  };

  const openTrackableInNewTab = () => {
    if (props.singleTask.linked_class === "Asset") {
      location.pathname = `/${props.singleTask.linked.project}/assets/${props.singleTask.linked.id}/assetversions/`;
      window.open(`${location.pathname}`);
    } else {
      location.pathname = `/${props.singleTask.linked.project}/sequence/${props.singleTask.linked.parent_sequence}/shots/${props.singleTask.linked.id}/shotversions`;
      window.open(`${location.pathname}`);
    }
  };

  const openVersionInNewTab = () => {
    const version =
      props.singleTask.shotversions[0]?.id ||
      props.singleTask.assetversions[0]?.id;
    if (props.singleTask.linked_class === "Asset") {
      location.pathname = `/${props.singleTask.linked.project}/assets/${props.singleTask.linked.id}/assetversions/`;
      const encode = btoa(unescape(encodeURIComponent(`versionId=${version}`)));

      window.open(`${location.pathname}?${encode}`);
    } else {
      location.pathname = `/${props.singleTask.linked.project}/sequence/${props.singleTask.linked.parent_sequence}/shots/${props.singleTask.linked.id}/shotversions/`;
      const encode = btoa(unescape(encodeURIComponent(`versionId=${version}`)));
      window.open(`${location.pathname}?${encode}`);
    }
  };

  const handleResetAssignedUser = () => {
    setAssignedUser(props.singleTask?.assigned_users);
  };

  return (
    <>
      <TableRow
        hover
        style={{ cursor: "pointer", display: props.hideItems ? "none" : "" }}
        key={props.singleTask.id}
      >
        <TableCell className={classes.listText}>
          <span>
            {props.singleTask.name ? props.singleTask.name : "no name"}
          </span>
        </TableCell>
        <TableCell className={classes.listText}>
          <span>
            {new Date(props.singleTask.start_time).toString().substr(4, 6)} -{" "}
            {new Date(props.singleTask.end_time).toString().substr(4, 6)}
          </span>
        </TableCell>
        <TableCell className={classes.listText}>
          <span>
            <StatusDropDown
              url={`trackables/task/${props.singleTask.id}/`}
              statusList={props?.statusList}
              currStatus={props?.singleTask?.status}
              updateRefreshCounter={props.updateRefreshCounter}
            />
          </span>
        </TableCell>
        <TableCell className={classes.listText}>
          <PriorityViewer priority={props.singleTask.priority} />
        </TableCell>
        <TableCell className={classes.listText}>
          <div
            className={classes.inLineDiv}
            style={{
              marginRight: "10px",
              position: "relative",
            }}
          >
            <div>
              {
                TrackablesAndIcons?.find(
                  (track) =>
                    track?.trackable === props?.singleTask?.linked_class
                )?.icon
              }
            </div>

            {props.singleTask.linked?.thumbnail ? (
              <img
                style={{
                  borderRadius: "7px",
                  objectFit: "cover",
                  margin: "4px 6px 4px 10px",
                }}
                src={props.singleTask.linked?.thumbnail}
                height="28px"
                width="28px"
                alt="No Img"
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : (
              <NoThumbnail
                style={{
                  margin: "4px 6px 4px 10px",
                  height: "28px",
                  width: "28px",
                }}
              />
            )}

            <span style={{ display: "block" }} ref={ref}>
              <span onClick={openTrackableInNewTab} className={classes.onHover}>
                {props.singleTask.linked.name || props.singleTask.linked.code}
              </span>
              {props.singleTask.shotversions[0]?.name ||
              props.singleTask.assetversions[0]?.name ? (
                <>
                  <span>{" - "}</span>
                  <span
                    onClick={openVersionInNewTab}
                    className={classes.onHover}
                  >
                    {props.singleTask.shotversions[0]?.name ||
                      props.singleTask.assetversions[0]?.name}
                  </span>
                </>
              ) : (
                ""
              )}
              {props.singleTask.shotversions.length > 1 ||
              props.singleTask.assetversions.length > 1 ? (
                <span
                  onMouseEnter={() => {
                    setIsHover(true);
                  }}
                >
                  {" "}
                  +
                  {(props.singleTask.shotversions.length ||
                    props.singleTask.assetversions.length) - 1}
                </span>
              ) : (
                ""
              )}
            </span>

            {isHover ? (
              <div
                style={{
                  marginTop: "0px",
                  marginLeft: `${ref.current.clientWidth + 56}px`,
                  position: "absolute",
                  cursor: "auto",
                }}
              >
                <TaskVersionHover
                  versionList={
                    props.singleTask.shotversions.length
                      ? props.singleTask.shotversions
                      : props.singleTask.assetversions
                  }
                  parent={props.singleTask.linked}
                  scrollValue={props.scrollValue}
                  setIsHover={setIsHover}
                  isHover={isHover}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </TableCell>
        <TableCell className={classes.listText}>
          <div className={classes.inLineDiv}>
            {props.singleTask.department?.name}
          </div>
        </TableCell>
        <TableCell className={classes.listText}>
          <div className={classes.inLineDiv} style={{ marginRight: "30px" }}>
            <UsersViewer
              selectedTask={props.singleTask}
              editable="yes"
              showDoneButton={true}
              handleSubmit={handleSubmit}
              removeTaskUser={removeTaskUser}
              getTaskUser={getTaskUser}
              usersList={props.projectUsers}
              assignedUser={assignedUser}
              type={"multiple"}
              handleResetAssignedUser={handleResetAssignedUser}
            />
          </div>
        </TableCell>
        <TableCell className={classes.listText}>
          <Button onClick={() => props.handleViewTaskModal(props.singleTask)}>
            <PencilSimpleLine size={22} />
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TaskPageListItems;
