import {
  Card,
  CardContent,
  CircularProgress,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import React from "react";
import { MoreHorizontal, Users, Edit3, Trash2 } from "react-feather";
import { PushPin, PushPinSimple } from "phosphor-react";
import useStyles from "./ProjectCards.styles";
import { Menu, Tooltip } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router";
import StringTruncate from "../../Utilities/StringTruncate/StringTruncate";
import ProjectPageNewProjectModal from "../ProjectPageModals/ProjectPageNewProjectModal";
function CircularProgressWithLabel({ value, size }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        // value={value}
        value={100}
        size={size}
        style={
          value < 100
            ? {
                color: "grey",
              }
            : { color: "green" }
        }
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          {`${Math.floor(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function ProjectCards(props) {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.cardContentDiv}>
              <div className={classes.cardContentDiv1}>
                <img
                  alt="projectImage"
                  src={
                    props.project.thumbnail
                      ? props.project.thumbnail
                      : "https://images.pexels.com/photos/577514/pexels-photo-577514.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  }
                  style={{
                    objectFit: "cover",
                    borderRadius: "15px",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>

              <div
                className={classes.cardContentDiv2}
                onClick={(event) => {
                  if (event.ctrlKey) {
                    window.open(`/${props.project.id}/updates`);
                  } else {
                    history.push(`/${props.project.id}/updates`);
                  }
                }}
              >
                <div className={classes.cardContentDiv2Title}>
                  <Tooltip title={props.project.name} placement="top-start">
                    <span className={classes.title}>
                      {StringTruncate(props.project.name, 20)}
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.cardContentDiv2Footer}>
                  <span style={{ marginTop: "0px" }}>Created on : </span>
                  <span style={{ marginTop: "0px" }}>
                    {props.project.created_at.slice(0, 10)}
                  </span>
                  {/* p tag is empty purposefully so that sudo seloctor dosent applys propety to tags above it */}
                  <p></p>
                </div>
                <div className={classes.cardContentDiv2Desc}>
                  <Tooltip
                    title={props.project?.description}
                    placement="bottom-start"
                  >
                    <div className={classes.descText}>
                      {props.project.description
                        ? `${props.project.description}`
                        : "Project has no description !"}
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex" }}>
                  {props.pinnedProjects.includes(props.project.id) ? (
                    <Button
                      style={{ minWidth: "0px", padding: "4px" }}
                      onClick={() => props.handleunPinnedPost(props.project.id)}
                    >
                      <PushPinSimple size={20} color="#F9F9F9" weight="fill" />
                    </Button>
                  ) : (
                    <Button
                      style={{ minWidth: "0px", padding: "4px" }}
                      onClick={() => props.handlePinnedPost(props.project.id)}
                    >
                      <PushPin size={20} />
                    </Button>
                  )}
                  <button
                    id={props.project.id}
                    className={classes.clickableDiv}
                    onClick={(e) => handleOpen(e)}
                  >
                    <center>
                      <MoreHorizontal className={classes.projectDot} />
                    </center>
                  </button>
                </div>

                {/* <div style={{marginTop: "10px", marginLeft: "10px"}}>

                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size="50px"
                    className={classes.circleDefaultColor}
                  />
                </div> */}
                <div
                  style={{
                    cursor: "default",
                  }}
                >
                  <CircularProgressWithLabel
                    value={props.project.completion_percentage}
                    size="50px"
                  />
                </div>
                <Tooltip
                  title={props.project?.abbreviation}
                  placement="bottom-start"
                >
                  <div className={classes.abbreviationText}>
                    {props.project.abbreviation
                      ? `${props.project.abbreviation}`
                      : "Abbreviation !"}
                  </div>
                </Tooltip>
              </div>
            </div>
          </CardContent>
        </Card>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ className: `${classes.menuPop}` }}
        >
          <MenuItem
            className={classes.modalButton}
            onClick={() => {
              handleClose();
              props.handleManageUserModal({
                modalOpen: "true",
                projectId: props.project.id,
              });
            }}
          >
            <Users className={classes.trashIcon} />
            Manage Users
          </MenuItem>
          <MenuItem
            className={classes.modalButton}
            onClick={() => {
              handleClose();
              props.handleEditProjectModal(props.project);
            }}
          >
            <Edit3 className={classes.trashIcon} />
            Edit Project
          </MenuItem>
          <MenuItem
            id={`del${props.project.id}`}
            className={classes.modalButton}
            onClick={() => {
              handleClose();
              props.handleDeleteProjectModal(props.project);
            }}
          >
            <Trash2 className={classes.trashIcon1} />
            Delete Project
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}

export default ProjectCards;
