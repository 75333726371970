import React from "react";
import useStyles from "./DeleteHdrModal.style";
import { Button, Card, CardContent } from "@material-ui/core";
export default function DeleteHdrModal(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.CardContent}>
          <p className={classes.headText}>Confirm Deletion</p>
          <p className={classes.text}>
            Are you sure you want to delete this Hdr ?
          </p>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.yesButton}
              type="submit"
              size="small"
              disableElevation
              onClick={props.deleteHdrMaps}
            >
              Yes, delete it
            </Button>
            <Button
              className={classes.noButton}
              type="submit"
              size="small"
              disableElevation
              onClick={props.closeModal}
            >
              No, keep it
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
