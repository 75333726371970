import React, { useState } from "react";
import * as Icon from "react-feather";
import { Checkbox, FormControlLabel } from "@material-ui/core";
//@MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import useStyles from "./ShotsPageNewShotModal.styles";
import { Asterisk } from "phosphor-react";
import { useHistory } from "react-router-dom";
import { fget, fpost, exportFileWithXhr } from "../../../API/callsAPI";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import HelperText from "../../Utilities/HelperText/HelperText";

export default function ShotsPageNewShotModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const [thumbnail, uploadThumbnailSet] = useState();
  const [thumbnailTouched, setThumbnailTouch] = useState(false);
  const [department, setDepartment] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [defaultVersion, setDefaultVersion] = useState(false);
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const handleThumbnailUpload = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    uploadThumbnailSet(file);
    setThumbnailTouch(true);
  };

  const handleClearUpload = (event) => {
    event.stopPropagation();
    uploadThumbnailSet(null);
    setThumbnailTouch(false);
  };

  const selectedDepartment = [];
  const handleDeptChange = (e) => {
    e.map((el) =>
      selectedDepartment.includes(el.id) ? " " : selectedDepartment.push(el.id)
    );
    setDepartment(selectedDepartment);
  };

  //Validators
  const NewShotValidationScheme = yup.object({
    shotName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 10")
      .required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      shotName: "",
      status: "",
      parentSequence: props.sequenceId,
      description: "",
      department: department,
      shotVersionName: "",
    },
    validationSchema: NewShotValidationScheme,
    onSubmit: async (values) => {
      setLoader(true);
      if (thumbnailTouched) {
        setFileUploading(true);
      }
      let formData = new FormData();
      formData.append("code", values.shotName);
      if (thumbnail) {
        formData.append("file_name", thumbnail.name);
      }
      formData.append("file_path", "");
      formData.append("status", values.status);
      formData.append("project", props.projectId);
      formData.append("description", values.description);
      formData.append("parent_sequence", props.sequenceId);
      formData.append("vp_linked_level_sequence", "");
      if (department) {
        for (var i = 0; i < department.length; i++) {
          formData.append(`allowed_dept`, department[i]);
        }
      }
      let newShot = {
        url: "trackables/shot/",
        data: formData,
      };

      try {
        const res = await fpost(newShot);

        if (defaultVersion) {
          createDefaultShotVersion(res.data);
        }
        if (res.status === 200 || 201) {
          if (thumbnail) {
            await getSignedUrl(res.data.id, "shot");
          } else {
            setLoader(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Created ${res.data.code}`,
              isError: false,
            });
            setFileUploading(false);
            props.updateRefreshCounter();
            props.updateRefreshCounter();
            props.handleNewShotModal();
          }
        }
      } catch (error) {
        setLoader(false);
        setFileUploading(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  function updateProgressBar(e) {
    if (e.lengthComputable) {
      var percentComplete = parseInt((e.loaded / e.total) * 100);
      setUploadProgress(percentComplete);
    }
  }

  function transferComplete(evt) {
    setSnackValue({
      isOpen: true,
      message: `File transfered SuccessFully `,
      isError: false,
    });
  }

  function transferFailed(evt) {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  function transferCanceled(evt) {
    setSnackValue({
      isOpen: true,
      message: `File Transfer Cancelled`,
      isError: true,
    });
  }

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      console.log(e.dataTransfer.files[0]);
      uploadThumbnailSet(e.dataTransfer.files[0]);
      setThumbnailTouch(true);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const createDefaultShotVersion = async (data) => {
    let formData = new FormData();
    formData.append(
      "name",
      formik.values.shotVersionName || `${data?.code}.v001`
    );
    if (thumbnail) {
      formData.append("file_name", thumbnail?.name);
    }
    formData.append("status", formik?.values?.status);
    formData.append("shot", data?.id);

    let newVersion = {
      url: "trackables/shot-version/",
      data: formData,
    };

    try {
      const res = await fpost(newVersion);

      if (res.status === 200 || 201) {
        if (thumbnail) {
          getSignedUrl(res?.data?.id, "shotversion");
        } else {
          setLoader(false);
          setSnackValue({
            isOpen: true,
            message: `SuccessFully Created ${res?.data?.name}`,
            isError: false,
          });
          setFileUploading(false);
          props.updateRefreshCounter();
        }
      }
    } catch (error) {
      setFileUploading(false);
      setLoader(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const getSignedUrl = async (newId, trackable) => {
    try {
      const res = await fget({
        url: `trackables/${trackable}/${newId}/upload`,
      });
      if (res.status === 200) {
        await exportFileWithXhr({
          url: res.data?.signed_url,
          data: thumbnail,
          updateProgressBar: (e) => {
            updateProgressBar(e);
          },
          transferComplete: transferComplete(),
          transferFailed: transferFailed(),
          transferCanceled: transferCanceled(),
        });

        fget({
          url: `trackables/${trackable}/${newId}/thumbnail`,
        });
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully Created`,
          isError: false,
        });
        setFileUploading(false);
        props.updateRefreshCounter();
        props.handleNewShotModal();
      }
    } catch (error) {
      setLoader(false);
      setFileUploading(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Shot"
              type="New"
              closeModal={() => props.handleNewShotModal()}
            />
            <div className={classes.cardContentDiv1}>
              <div>
                <span>Shot Name</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv3}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    placeholder="Enter shot name"
                    fullWidth
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    className={classes.cardContentInput1}
                    name="shotName"
                    value={formik.values.shotName}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.shotName)}
                  />
                </div>
                {formik.errors.shotName && formik.touched.shotName ? (
                  <HelperText text={formik.errors.shotName} />
                ) : null}
              </div>
              <div
                className={classes.displayFlex}
                style={{ marginTop: "15px" }}
              >
                <div style={{ width: "150px" }}>
                  <span>Status</span>
                  <div
                    className={classes.cardContentDiv3}
                    style={{ marginTop: "5px" }}
                  >
                    <Select
                      disableUnderline
                      style={{
                        width: "100%",
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="selectStatusShot"
                      label="Select Status"
                      className={classes.cardContentInput1}
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                    >
                      <MenuItem
                        className={classes.addNewStatusButton}
                        onClick={() => {
                          history.push(`/${props.projectId}/settings`);
                        }}
                      >
                        <Icon.Plus />
                        Add new Status
                      </MenuItem>
                      <MenuItem value="">None</MenuItem>
                      {props.status &&
                        props.status.map((eachStatus) => (
                          <MenuItem value={eachStatus.id}>
                            <span
                              style={{
                                height: "15px",
                                width: "15px",
                                backgroundColor: `rgb(${eachStatus?.color})`,
                                borderRadius: "50%",
                                display: "inline-block",
                                margin: "0px 5px",
                              }}
                            ></span>
                            {eachStatus.name} - {eachStatus.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
                <div style={{ maxWidth: "50%" }}>
                  <span>Status Code</span>
                  <div
                    style={{ marginTop: "5px" }}
                    className={classes.cardContentDiv3}
                  >
                    <TextField
                      className={classes.cardContentInput1}
                      InputProps={{
                        disableUnderline: true,
                        style: { opacity: "0.4" },
                      }}
                      disabled
                      fullWidth
                      value={
                        props.status.find(
                          (eachStatus) => eachStatus.id === formik.values.status
                        )?.code
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <span>Shot Description</span>
                <div
                  className={classes.cardContentDiv4}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    type="text"
                    className={classes.cardContentInput2}
                    placeholder="Enter a Description..."
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    multiline={true}
                    InputProps={{ disableUnderline: true }}
                    helperText={
                      formik.errors.description
                        ? formik.errors.description
                        : null
                    }
                  />
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <label>Departments</label>
                <div
                  style={{ marginTop: "5px" }}
                  className={classes.cardContentDiv3}
                >
                  <Autocomplete
                    multiple
                    id="selectDepartments"
                    options={props.departments ? props.departments : []}
                    getOptionLabel={(option) =>
                      option.title ? option.title : "No Option"
                    }
                    className={classes.autoComplete}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, v) => handleDeptChange(v)}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "15px",
                width: "100%",
              }}
            >
              <span>Attachment</span>

              <UploadFile
                fileUploading={fileUploading}
                uploadProgress={uploadProgress}
                thumbnail={thumbnail}
                handleClearUpload={handleClearUpload}
                handleThumbnailUpload={handleThumbnailUpload}
              />
            </div>

            <FormControlLabel
              control={<Checkbox />}
              label={`Create a default asset version`}
              onChange={(e) => setDefaultVersion(e.target.checked)}
            />
            <br />
            {defaultVersion ? (
              <div>
                <span>Shot Version Name</span>
                <div
                  className={classes.cardContentDiv3}
                  style={{ marginTop: "5px" }}
                >
                  <TextField
                    fullWidth
                    placeholder="Enter asset version name"
                    name="shotVersionName"
                    id="TextField"
                    defaultValue={
                      formik?.values?.shotName
                        ? `${formik?.values?.shotName}.v001`
                        : ""
                    }
                    className={classes.cardContentInput1}
                    InputProps={{ disableUnderline: true }}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.shotName && Boolean(formik.errors.shotName)
                    }
                    helperText={
                      formik.errors.shotName ? formik.errors.shotName : null
                    }
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <CardActions className={classes.cardAction}>
              <Button
                disabled={fileUploading}
                className={classes.doneButton}
                type="submit"
                value="Submit"
                size="small"
                variant="outlined"
                disableElevation
                color="secondary"
              >
                {loader ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "25px", height: "25px" }}
                  />
                ) : (
                  <span>Create New Shot</span>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
