import { Button } from "@material-ui/core";
import React from "react";
import { SkipBack, SkipForward } from "react-feather";
import useStyles from "./CustomPagination.style.js";
import Pagination from "@material-ui/lab/Pagination";

const CustomPagination = ({
  currentPage,
  totalPage,
  totalItem,
  handlePageChange,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.showingNumber}>
        Showing {(currentPage - 1) * 50 + 1}
        {"  "}-{"  "}
        {currentPage * 50 >= totalItem ? totalItem : currentPage * 50} of{"  "}
        {totalItem}
      </span>
      <Pagination
        page={currentPage}
        count={totalPage}
        color="secondary"
        size="medium"
        onChange={(e, value) => {
          handlePageChange(value);
        }}
      />
      <span></span>
    </div>
  );
};

export default CustomPagination;
