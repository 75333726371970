import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  changeBackgroundButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "35px",
    width: "200px",
    padding: "1% 1%",
    borderRadius: "10px",
    marginTop: "2%",
    color: theme.palette.text.secondary,
  },
  canvasHdr: {
    width: "100% !important",
    height: "70px !important",
    borderRadius: "13px",
    cursor: "pointer",
    marginRight: "2%",
    marginLeft: "0%",
  },
  backgroundOptionsContainer: {
    direction: "row",
    display: "flex",
    alignItems: "center",
    marginTop: "2%",
    overflowX: "hidden",
    width: "99%",
  },
  hdrSelected: {
    width: "100% !important",
    height: "70px !important",
    borderRadius: "15px",
    cursor: "pointer",
    marginRight: "2%",
    marginLeft: "0%",
    border: `3px solid ${theme.palette.secondary.main}`,
  },
  container: {
    position: "relative",
    height: "85px",
    width: "148px",
    borderRadius: "15px",
    marginRight: "2%",
  },

  close: {
    position: "absolute",
    top: -3,
    right: -3,
    cursor: "pointer",
    backgroundColor: theme.palette.secondary.main,
    padding: "5%",
    borderRadius: "50%",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    visibility: "display",
    "&:hover": {
      backgroundColor: "#8B0000",
    },
  },
  rightArrow: {
    position: "absolute",
    right: "0px",
    marginTop: "15px",
    // background: theme.palette.secondary.light,
    height: "80px",
    width: "30px",
    padding: "0px",
    minWidth: "0px",
    minHeight: "0px",
    color: "#6C65D9",
    "&:hover": {
      background: "none",
    },
  },
}));

export default useStyles;
