import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "360px",
    fontFamily: "Poppins",
  },
  card: {
    borderRadius: "20px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "13px 27px",
  },
  title: {
    color: theme.palette.text.primary,
  },
  clear: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },

  textColor: {
    color: theme.palette.text.primary,
  },

  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    padding: "13px 27px",
    justifyContent: "space-between",
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
  sortButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "115px",
    textTransform: "none",
    marginBottom: "15px",
    padding: "6px ",
    borderRadius: "7px",
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
