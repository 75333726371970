import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #6C65D9",
  },
  header: {
    width: "100%",
    display: "flex",
    height: "25%",
    alignItems: "center",
    justifyContent: "space-around",
  },
  title: {
    fontSize: "14px",
    color: "#606479",
    textDecorationLine: "underline",
  },
  taskName: {
    fontSize: "32px",
  },
  dateAndPriority: {
    height: "25%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  date: {
    fontSize: "18px",
  },
  fieldName: {
    fontSize: "14px",
    color: "#606479",
    textAlign: "center",
  },
  priorityCookies: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  statusContainer: {
    height: "25%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  userAndDepartment: {
    height: "25%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  divider: {
    backgroundColor: "#6C65D9",
  },
  submitCommentField: {
    width: "87%",
    height: "46px",
    padding: "12px",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    overflowY: "hidden",
    marginLeft: "4px",
  },
  submitButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "10px",
    marginLeft: "8px",
    minWidth: "44px",
    height: "44px",
  },
  text: {
    color: theme.palette.primary.light,
  },
  icon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover ": {
      color: theme.palette.primary.light,
    },
  },
  flexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 25px",
  },
  replyingToText: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
}));

export default useStyles;
