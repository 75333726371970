import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  root: {
    boxSizing: "border-box",
  },
  assetsPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContainer: {
    width: "100%",
  },
  table: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  tableRow: {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  headTable: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
  tableBody: {
    width: "100%",
    overflowY: "auto",
    display: "block",
    tableLayout: "fixed",
    [theme.breakpoints.down(1142)]: {
      height: `calc(100vh - 310px)`,
    },
    [theme.breakpoints.down(717)]: {
      height: `calc(100vh - 414px)`,
    },
  },
}));

export default useStyles;
