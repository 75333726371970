import React, { useState, useEffect, useRef } from "react";
// import ReactPlayer from "react-player";
import captureVideoFrame from "./capture-video-frame.js";
import { X } from "react-feather";

import CanvasFrame from "../CanvasFrame/CanvasFrame.component";
import { CircularProgress, Modal } from "@material-ui/core";
import useStyles from "./player.styles";
import CustomSeekBar from "./CustomSeekBar.jsx";
import PlayerErrorCard from "../Utilities/PlayerErrorCard/PlayerErrorCard";

function Player({
  seekTime,
  player,
  setScreenshot,
  canvasModal,
  comments,
  playing,
  users,
  setList,
  screenshot,
  assetId,
  fetchComments,
  version_id,
  updatesPage,
  data,
  setPlayerProgress,
  handlePlayPause,
  seekForward,
  seekBack,
  scrollToComment,
  updateRefresh,
  selectedVersion,
}) {
  const [canvasmodal, setCanvasModal] = useState(false);
  const [progress, setProgress] = useState("00:00");
  const canvasModalToggle = () => setCanvasModal(!canvasmodal);
  const classes = useStyles();

  //custom seekbar states
  const [totalDuration, setTotalDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0); //current time of video
  const [currentTimeSeek, setCurrentTimeSeek] = useState(0); //changes when seek via custom seek bar
  const [annotations, setAnnotations] = useState([]);
  const [initaial, setInitaial] = useState(false); // represent first onchange of a video
  const [arrAnnotation, setArrAnnotation] = useState([]); //array of card to be displayed
  const [currentMark, setCurrentMark] = useState(null); //represent the current hovered over mark
  const [showControls, setShowControls] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    code: 2,
    message: "An error occurred, Please try again later!",
  });
  const [volumePlayer, setVolumePlayer] = useState({
    volume: 20,
    muted: false,
  });

  useEffect(() => {
    if (seekTime) {
      player.current.currentTime = seekTime;
    }
  }, [seekTime]);

  useEffect(() => {
    if (currentTimeSeek) {
      player.current.currentTime = currentTimeSeek;
    }
  }, [currentTimeSeek]);
  useEffect(() => {
    const frame = captureVideoFrame(player?.current);
    setScreenshot(frame.dataUri);
  }, [canvasModal]);

  useEffect(() => {
    generateAnnotations();
  }, [comments, totalDuration]);
  // custom seekbar functions

  //this function get eliminates the old comments for same node and only keeps the latest one
  function getLatestComments(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  // generates annotations out of comments
  function generateAnnotations() {
    let ArrayAnotation = [];
    let displayAnnotaionarr = [];
    if (comments?.length) {
      for (let i = 0; i < comments.length; i++) {
        const stamp = comments[i].description.split("<p>");
        let stampInt;
        if (stamp[0] !== "") {
          stamp[0].split(":").map((eachInt, index) => {
            if (index === 0) {
              stampInt = parseInt(eachInt) * 60;
            } else {
              stampInt = stampInt + parseInt(eachInt);
            }
          });
        }
        if (parseInt(stampInt) === 0) {
          ArrayAnotation.push({ value: 0 });
        }
        if (parseInt(stampInt)) {
          ArrayAnotation.push({
            value: parseInt((parseInt(stampInt) / totalDuration) * 100), //get stamp for 100% of seek bar
            label: "",
          });
          displayAnnotaionarr.push({
            value: parseInt((parseInt(stampInt) / totalDuration) * 100),
            label: comments[i],
          });
          //array gets the stamp and detail of each stamp for card
        }
      }
    }
    const arr1 = getLatestComments(displayAnnotaionarr, "value");
    setAnnotations(ArrayAnotation);
    setArrAnnotation(arr1);
  }

  useEffect(() => {
    if (playing) {
      player?.current?.play();
    } else {
      player?.current?.pause();
    }
  }, [playing]);

  useEffect(() => {
    if (player?.current) {
      player.current.volume = volumePlayer.volume / 100;
      player.current.muted = volumePlayer.muted;
    }
  }, [volumePlayer]);

  const reLoad = () => {
    updateRefresh();
  };

  useEffect(() => {
    setShowError(false);
  }, [selectedVersion]);

  return (
    <>
      <div className="player-wrapper">
        <Modal open={canvasmodal} className={classes.modal}>
          <div className={classes.canvasDiv}>
            <div className={classes.modalHead}>
              <h1>Canvas</h1>
              <X onClick={canvasModalToggle} />
            </div>
            <CanvasFrame
              users={users}
              setCommentList={setList}
              isUpdate={setList ? true : false}
              image={screenshot}
              assetId={assetId}
              toggle={canvasModalToggle}
              fetchComments={fetchComments}
              version_id={version_id}
              progress={progress}
            />
          </div>
        </Modal>

        {!showError ? (
          <div
            style={{
              position: "relative",
              paddingTop: "56.25%",
              backgroundColor: "black",
            }}
          >
            {showControls ? (
              ""
            ) : updatesPage ? (
              ""
            ) : (
              <div className={classes.loader}>
                <CircularProgress />
                <div>Buffering...</div>
              </div>
            )}
            <video
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                height: "100%",
                width: "100%",
                filter: showControls ? "" : "blur(5px)",
              }}
              src={data ? data : ""}
              crossOrigin="anonymous"
              ref={player}
              onLoadedData={() => {
                setShowControls(true);
                setTotalDuration(player?.current.duration);
              }}
              onError={(error) => {
                console.log("error", error);
                if (error.target.error.code === 4) {
                  setErrorMessage({
                    code: 4,
                    message: "Media source not supported",
                  });
                }
                setShowError(true);
              }}
              onPlaying={() => setShowControls(true)}
              onWaiting={() => {
                if (!playing) {
                  setShowControls(false);
                }
              }}
              controls={updatesPage ? true : false}
              onContextMenu={(e) => e.preventDefault()}
              onTimeUpdate={(e) => {
                setCurrentTime(player?.current.currentTime);
                let seconds = 0;
                let minutes = 0;
                if (player?.current.currentTime % 60) {
                  minutes = parseInt(player?.current.currentTime / 60);
                  seconds = parseInt(player?.current.currentTime % 60);
                  if (seconds < 10) {
                    seconds = `0${seconds}`;
                  }
                }
                let time = `${minutes}:${seconds}`;
                setProgress(time);
                setPlayerProgress(time);
                setInitaial(true);
              }}
            />
            {/* div that floats on video player */}
            {updatesPage ? (
              ""
            ) : (
              <div
                style={{
                  zIndex: "10",
                  width: "100%",
                  position: "absolute",
                  marginTop: "-40px",
                }}
              >
                <CustomSeekBar
                  isPlaying={playing}
                  handlePlayPause={handlePlayPause}
                  onTimeSeek={(time) => setCurrentTimeSeek(time)}
                  currentTime={currentTime}
                  totalDuration={totalDuration}
                  annotations={annotations}
                  seekForward={seekForward}
                  seekBack={seekBack}
                  onDownload={() => (window.location.href = data)}
                  arrAnnotation={arrAnnotation}
                  currentMark={currentMark}
                  setCurrentMark={setCurrentMark}
                  scrollToComment={scrollToComment}
                  progress={progress}
                  volume={volumePlayer.volume}
                  onVolumeChange={(volume) =>
                    setVolumePlayer({ ...volumePlayer, volume: volume })
                  }
                  muted={volumePlayer.muted}
                  onMute={(muted) => {
                    setVolumePlayer({ ...volumePlayer, muted: muted });
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <PlayerErrorCard errorMessage={errorMessage} reLoad={reLoad} />
        )}
      </div>
      {/* commenting this as we have moved annotation to top */}

      {/* <div style={{ textAlign: "center", marginTop: "13px" }}>
        <Button
          onClick={() => {
            setPlaying(false);
            player.seekTo(
              Math.min(
                player.getDuration(),
                player.getCurrentTime() - 1 / frameRate
              ),
              "seconds"
            );
          }}
          className={classes.ChevronsBtn}
        >
          <Icon.ChevronsLeft />
        </Button>
        <Button
          onClick={() => {
            const frame = captureVideoFrame(player.getInternalPlayer());
            setScreenshot(frame.dataUri);
            setCanvasModal(true);
          }}
          className={classes.annotateButton}
        >
          <Icon.Edit style={{ strokeWidth: "1.2px" }} />
        </Button>
        <Button
          onClick={() => {
            setPlaying(false);
            player.seekTo(
              Math.min(
                player.getDuration(),
                player.getCurrentTime() + 1 / frameRate
              ),
              "seconds"
            );
          }}
          className={classes.ChevronsBtn}
        >
          <Icon.ChevronsRight />
        </Button>
      </div> */}
    </>
  );
}

export default Player;
