import { Button } from "@material-ui/core";
import React from "react";
import * as Icon from "react-feather";
import { fpost, fget } from "../../../API/callsAPI";
import PostEditor from "../../PostEditor/postEditor";
import useStyles from "./UpdatesPageCardComment.styles";

export default function UpdatesPageCardComment(props) {
  const classes = useStyles();
  let mentionsRef = React.createRef();
  const handleSubmit = async () => {
    let newSequence = {
      url: "trackables/trackable-comment/",
      data: {
        description: mentionsRef.current.toHtml(),
        mentions: mentionsRef.current.onExtractMentions(),
        trackable: props.trackableId,
        version_id: props.versionId,
      },
    };

    try {
      const result = await fpost(newSequence);
      mentionsRef?.current?.reset();

      const res = await fget({
        url: `user/${result.data.author}`,
      });

      result.data.author = { ...res.data };
      props.setCommentList((prevComments) => [result.data, ...prevComments]);
      props.updateCommentCount();

      // setUserDetails(null);
      //props.fetchComments();
      //props.toggle();
    } catch (error) {
      //swal("Oops!", error.message, "error");
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div className={classes.commentBox}>
        <PostEditor
          placeholder={"Comment Here...."}
          // mentions={[{ name: "Foo", id: "1" }, { name: "Bar", id: "2" }]}
          mentions={props.users}
          ref={mentionsRef}
          handleSubmit={handleSubmit}
        />
      </div>

      <div style={{ width: "12%", display: "flex" }}>
        <Button
          className={classes.sendBtn}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Icon.Send className={classes.icon} />
        </Button>
      </div>
    </div>
  );
}
