import React, { useState } from "react";
import useStyles from "./NotificationInformation.styles";
import { Divider, Checkbox, Button } from "@material-ui/core";
import { Trash, EnvelopeSimple, EnvelopeSimpleOpen } from "phosphor-react";

//components
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import UsersViewer from "../../Utilities/UsersViewer/UsersViewer";
import AssestVersion from "../../Utilities/Svg/AssestVersion.svg";
import ShotVersion from "../../Utilities/Svg/ShotVersion.svg";
import {
  Cube,
  FilmStrip,
  FilmSlate,
  Bell,
  CheckSquareOffset,
} from "phosphor-react";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";
const NotificationInformation = ({
  notificationDetails,
  allUsers,
  setNotificationIds,
  handleCheckNofications,
  checkedNotifications,
  readOneNotification,
  handleDeleteNotification,
  notificationIds,
}) => {
  const classes = useStyles();

  const [isRead, setIsRead] = useState(notificationDetails?.isRead);

  //function taskes a trackable type and returns the correct matched component
  const trackableIcon = (trackable) => {
    switch (trackable) {
      case "task":
        return <CheckSquareOffset className={classes.notificationDrop} />;
      case "asset":
        return <Cube className={classes.notificationDrop} />;
      case "assetversion":
        return <img src={AssestVersion} className={classes.notificationDrop} />;
      case "sequence":
        return <FilmStrip className={classes.notificationDrop} />;
      case "shot":
        return <FilmSlate className={classes.notificationDrop} />;
      case "shotversion":
        return <img src={ShotVersion} className={classes.notificationDrop} />;
      default:
        return <Bell className={classes.notificationDrop} />;
    }
  };

  //function to get the user object from the array of id of users passed
  const getUsers = (users) => {
    const userArray = allUsers.filter((user) => users.includes(user.id));
    return userArray;
  };

  //function returns the user ui
  const trackableUser = (users) => {
    if (users.length !== 0) {
      const newUserList = getUsers(users);
      if (newUserList && newUserList.length == 1) {
        return (
          <div className={classes.singleUser}>
            <UserAvatar
              avatar={newUserList[0]?.avatar}
              firstName={newUserList[0]?.first_name}
              lastName={newUserList[0]?.last_name}
            />
            <span style={{ margin: "0 5px" }}>
              {newUserList[0]?.first_name} {newUserList[0]?.last_name}
            </span>
          </div>
        );
      }
      if (newUserList && newUserList.length > 1) {
        return <UsersViewer editable="no" users={newUserList} />;
      }
    }
  };

  // function that will help getting all the checked checkbox and thier ids
  const handleChange = (event, id) => {
    const selectedIndex = checkedNotifications.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(checkedNotifications, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(checkedNotifications.slice(1));
    } else if (selectedIndex === checkedNotifications.length - 1) {
      newSelected = newSelected.concat(checkedNotifications.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        checkedNotifications.slice(0, selectedIndex),
        checkedNotifications.slice(selectedIndex + 1)
      );
    }

    handleCheckNofications(newSelected);

    if (!event.target.checked) {
      setNotificationIds((prevItems) => {
        let filteredItem = prevItems.filter((eachItem) => eachItem !== id);
        return filteredItem;
      });
    } else {
      setNotificationIds((prevItems) => [...prevItems, id]);
    }
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <div
        className={
          isRead ? classes.notificationsItems : classes.notificationsItemsUnread
        }
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Checkbox
            checked={
              checkedNotifications?.indexOf(notificationDetails.id) !== -1
            }
            onChange={(event) => handleChange(event, notificationDetails.id)}
            size="small"
          />
        </div>
        {trackableIcon(notificationDetails?.notification_object?.content_type)}
        <div className={classes.descriptionContainer}>
          <p className={classes.description}>
            {notificationDetails?.notification_object?.content_object?.name &&
              `${notificationDetails?.notification_object?.content_object?.name} :`}
            {notificationDetails.message}
          </p>

          <div
            style={{
              display: "flex",
              width: "45%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={classes.description2}>
              {notificationDetails?.notification_object?.content_object
                ?.thumbnail && (
                <div className={classes.thumbnail}>
                  {trackableIcon(
                    notificationDetails?.notification_object?.content_type
                  )}
                  <div>
                    <ImageViewer
                      sliderState={0}
                      url={
                        notificationDetails?.notification_object?.content_object
                          ?.thumbnail
                      }
                      style={{
                        height: "38px",
                        width: "38px",
                        marginRight: "8px",
                      }}
                    />
                  </div>
                  <p>Thumbnail</p>
                </div>
              )}

              {notificationDetails?.notification_object?.content_object
                ?.assigned_users
                ? trackableUser(
                    notificationDetails?.notification_object?.content_object
                      ?.assigned_users
                  )
                : notificationDetails?.notification_object?.content_object
                    ?.admin_users
                ? trackableUser(
                    notificationDetails?.notification_object?.content_object
                      ?.admin_users
                  )
                : ""}
            </div>
            {notificationDetails?.notification_object?.content_object
              ?.updated_by ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Updated by:</span>
                {trackableUser([
                  notificationDetails?.notification_object?.content_object
                    ?.updated_by,
                ])}
              </div>
            ) : notificationDetails?.notification_object?.content_object
                ?.created_by ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Created by:</span>
                {trackableUser([
                  notificationDetails?.notification_object?.content_object
                    ?.created_by,
                ])}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.hoverContainer}>
            <div
              className={
                notificationIds.length > 0
                  ? classes.notdisplayElement
                  : classes.displayElement
              }
            >
              <Button
                className={classes.iconBtn}
                onClick={(event) =>
                  handleDeleteNotification(event, notificationDetails.id)
                }
              >
                <Trash size={18} color="#FF0000" />
              </Button>
              {isRead ? (
                <Button
                  className={classes.iconBtn}
                  onClick={async (event) => {
                    event.stopPropagation();
                    const returnedObj = await readOneNotification(
                      notificationDetails.id,
                      notificationDetails,
                      false
                    );
                    if (returnedObj) {
                      setIsRead(returnedObj.isRead);
                    }
                  }}
                >
                  <EnvelopeSimpleOpen size={18} color="#6C65D9" />
                </Button>
              ) : (
                <Button
                  className={classes.iconBtn}
                  onClick={async (event) => {
                    event.stopPropagation();
                    const returnedObj = await readOneNotification(
                      notificationDetails.id,
                      notificationDetails,
                      true
                    );
                    if (returnedObj.isRead) {
                      setIsRead(returnedObj.isRead);
                    }
                  }}
                >
                  <EnvelopeSimple size={18} color="#6C65D9" />
                </Button>
              )}
            </div>
            <div className={classes.originalElement}>
              {new Intl.DateTimeFormat("en-IN", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              }).format(new Date(notificationDetails?.created_at))}
            </div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
};

export default NotificationInformation;
