import React, { useState } from "react";
import { Button, Card, Menu, MenuItem } from "@material-ui/core";
import useStyles from "./DepartmentCard.styles";
import * as Icon from "react-feather";
import { useHistory } from "react-router-dom";
import { DotsThree } from "phosphor-react";

function DepartmentCard({
  depart,
  projectId,
  handleEditDepartmentInfo,
  handleDeleteDepartmentInfo,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        onClick={(event) => {
          if (event.ctrlKey) {
            window.open(`/${projectId}/departments/${depart.id}/department`);
          } else {
            history.push(`/${projectId}/departments/${depart.id}/department`);
          }
        }}
      >
        <Card className={classes.DepartmentCard}>
          <div style={{ textAlign: "end" }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleOpenMenu(e);
              }}
            >
              <DotsThree size={25} />
            </Button>
          </div>
          <div className={classes.LogoDiv}>
            <Icon.Users className={classes.DepartmentIcon} size="80px" />
          </div>
          <div style={{ fontFamily: "poppins" }}>
            <div className={classes.name}>{depart.name}</div>
            <div className={classes.date}>
              Last Modified :
              {new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              }).format(new Date(depart.updated_at))}
            </div>
            <div className={classes.user}>Users : {depart.users.length}</div>
          </div>
        </Card>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{ className: `${classes.menuPop}` }}
      >
        <MenuItem
          className={classes.modalButton}
          onClick={() => {
            handleCloseMenu();
            handleEditDepartmentInfo(depart);
          }}
        >
          <Icon.Edit3 className={classes.editIcon} />
          Edit Department
        </MenuItem>
        <MenuItem
          className={classes.modalButton}
          onClick={() => {
            handleCloseMenu();
            handleDeleteDepartmentInfo(depart);
          }}
        >
          <Icon.Trash2 className={classes.trashIcon} />
          Delete Project
        </MenuItem>
      </Menu>
    </>
  );
}

export default DepartmentCard;
