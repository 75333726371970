import React from "react";
import * as Icon from "react-feather";

//@MUI
import { Button, Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useStyles from "./AssetsPageTool.styles";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
import PropTypes from "prop-types";
import {
  SortButton,
  ToggleButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component";

function AssetsTools(props) {
  const classes = useStyles();
  const [nameStatus, setnameStatus] = React.useState("None");
  const [setNameType] = React.useState("None");

  React.useEffect(() => {
    if (props.filterValues.status && props.status.length) {
      setnameStatus(
        props.status.filter((e) => e.id === props.filterValues.status)[0].name
      );
    }
  }, [props.filterValues.status, props.status]);

  if (sessionStorage.getItem("sliderState") === null)
    sessionStorage.setItem("sliderState", 0);

  return (
    <div className={classes.root}>
      <BreadCrumb />
      <div>
        <Grid
          container
          spacing={3}
          alignContent="center"
          className={classes.assetPageToolsGrid}
        >
          <Grid item>
            <ToggleButton handleToggler={props.handleAssetToggler} />
          </Grid>
          <Grid item>
            <div>
              <SortButton
                onSubmit={props.onSubmit}
                defaultvalue={props.defaultvalue}
              />
            </div>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <div className={classes.dropdownDiv}>
                <text className={classes.selectText}>
                  {props.filterValues.status ? nameStatus : "Select By Status"}
                </text>
                <Icon.ChevronDown className={classes.iconStyle} />
              </div>
              <Select
                style={{ height: "0%", opacity: "0" }}
                className={classes.formLabelText}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={props.filterValues.status}
                onChange={(event) => {
                  props.handleStatusChange(event.target.value[0]);
                  setnameStatus(event.target.value[1]);
                }}
                label="Select Status"
                defaultValue="none"
                MenuProps={{
                  classes: {
                    paper: classes.dropDown,
                  },
                }}
              >
                <MenuItem value={["", "None"]}>
                  <em>None</em>
                </MenuItem>
                {props.status &&
                  props.status.map((el) => (
                    <MenuItem value={[el.id, el.name]} key={el.id}>
                      {el.name} - {el.code}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <div className={classes.dropdownDiv}>
                <text className={classes.selectText}>
                  {props.filterValues.type
                    ? props.filterValues.type
                    : "Select By Type"}
                </text>
                <Icon.ChevronDown className={classes.iconStyle} />
              </div>
              <Select
                className={classes.formLabelText}
                style={{ height: "0%", opacity: "0" }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={props.filterValues.type}
                onChange={(event) => {
                  props.handleTypeChange(event.target.value[0]);
                  setNameType(event.target.value[1]);
                }}
                label="Select Type"
                defaultValue="none"
                MenuProps={{
                  classes: {
                    paper: classes.dropDown,
                  },
                }}
              >
                <MenuItem value={["", "None"]}>
                  <em>None</em>
                </MenuItem>
                {props.assetType &&
                  props.assetType.map((el) => (
                    <MenuItem value={[el, el]}>{el}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              size="small"
              className={classes.assetsPageNewAssetButton}
              disableElevation
              onClick={props.openNewAssetModal}
            >
              <Icon.Plus className={classes.plusIcon} />
              New Asset
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AssetsTools;

AssetsTools.propTypes = {
  openNewAssetModal: PropTypes.func,
  status: PropTypes.array,
  assetType: PropTypes.array,
  handleSliderChange: PropTypes.func,
  sliderState: PropTypes.number,
  handleStatusChange: PropTypes.func,
  handleTypeChange: PropTypes.func,
  filterValues: PropTypes.object,
  openSortModal: PropTypes.func,
  handleAssetToggler: PropTypes.func,
};
