import React from "react";
import useStyles from "./UpdatesPageCardCommentDiv.styles";
import { Divider, CircularProgress, Modal } from "@material-ui/core";
import { useState } from "react";
import { fdelete, fgetNextPage } from "../../../API/callsAPI";
import * as Icon from "react-feather";
import TrashBin from "./TrashBin";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import UserAvatar from "../../Utilities/UserAvatar/UserAvatar.component";

function UpdatesPageCardCommentDiv(props) {
  const classes = useStyles();
  //States
  // const [commentList, setCommentList] = useState(props.commentResult); //store the comment object which contains list and details of comment
  const [nextPageLoading, setNextPageLoading] = useState(false); //check if the next page is loading or not
  const [hasMore, setHasMore] = useState(props.commentList.next ? true : false); //to check if there are more comments to load
  const [nextPageUrl, setNextPageUrl] = useState(props.commentList.next);
  const [displayImage, setDisplayImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const displayImageToggle = () => setImageModal(!imageModal);
  //Function To fetch comments when page changes
  const fetchComments = () => {
    setHasMore(false);
    setNextPageLoading(true);
    fgetNextPage({
      url: nextPageUrl,
    })
      .then((res) => res.data)
      .then(
        (result) => {
          props.setCommentList((prevComments) => [
            ...prevComments,
            ...result.results,
          ]);
          if (result.next) {
            setNextPageUrl(result.next);
            setHasMore(true);
          }
          setNextPageLoading(false);
        },
        (error) => {
          setNextPageLoading(false);
          setHasMore(false);
        }
      );
  };

  //function to deleteComment
  const deleteComment = (e, ob) => {
    setSelectedCommentId(ob.id);
    setDeleteCommentModalOpen(true);
  };

  const handleDeleteComment = async () => {
    try {
      await fdelete({
        url: `trackables/trackable-comment/${selectedCommentId}/`,
      });
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted comment`,
        isError: false,
      });
      setDeleteCommentModalOpen(false);
      var a = props.commentList.filter((e) => {
        return e.id !== selectedCommentId;
      });
      props.setCommentList(a);
      props.updateCommentCount(a.length);
      setTimeout(() => props.closeDeleteCommentModalState(), 1500);
    } catch (error) {
      setDeleteCommentModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Modal
        open={imageModal}
        onClose={displayImageToggle}
        className={classes.modal1}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            outline: "none",
            height: "80vh",
          }}
        >
          <Icon.X
            style={{ alignSelf: "flex-end" }}
            onClick={displayImageToggle}
          />
          <img src={displayImage} height="90%" alt="attachment"></img>
        </div>
      </Modal>
      <div style={{ height: "400px", overflow: "auto", padding: "5px" }}>
        {props.commentResult.length > 0
          ? props.commentResult.map((ob, index) => {
              if (props.commentResult.length === index + 1) {
                return (
                  <>
                    <div className={classes.commentHead}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <UserAvatar
                          avatar={ob?.author?.avatar}
                          firstName={ob?.author?.first_name[0]}
                          lastName={ob?.author?.last_name[0]}
                        />
                        <p style={{ paddingLeft: "8px" }}>
                          {ob.author.first_name + " " + ob.author.last_name}
                        </p>
                      </p>
                      <p className={classes.time}>
                        {new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }).format(new Date(ob.created_at))}
                      </p>
                      {ob.author.id === props.loggedInUser?.user_id ? (
                        <div
                          onClick={(e) => {
                            deleteComment(e, ob);
                          }}
                        >
                          <TrashBin />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {ob.attachment ? (
                      <img
                        className={classes.AssetImage}
                        onClick={() => {
                          setDisplayImage(ob.attachment);
                          displayImageToggle();
                        }}
                        alt="projectImage"
                        src={ob.attachment}
                        width="75%"
                      />
                    ) : null}
                    <div
                      className={classes.textColor}
                      dangerouslySetInnerHTML={{
                        __html: ob.description,
                      }}
                    />
                    <Divider />
                    {hasMore ? (
                      <p
                        className={classes.textLoadMore}
                        onClick={() => fetchComments()}
                      >
                        Load More Comments
                      </p>
                    ) : null}
                    {nextPageLoading ? (
                      <div className={classes.nextCommentLoader}>
                        <CircularProgress
                          type="Oval"
                          color="#88C0D0"
                          height={50}
                          width={50}
                        />
                      </div>
                    ) : null}
                  </>
                );
              }
              return (
                <>
                  <div className={classes.commentHead}>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <UserAvatar
                        avatar={ob?.author?.avatar}
                        firstName={ob?.author?.first_name[0]}
                        lastName={ob?.author?.last_name[0]}
                      />
                      <p style={{ paddingLeft: "8px" }}>
                        {ob.author.first_name + " " + ob.author.last_name}
                      </p>
                    </p>
                    <p className={classes.time}>
                      {new Intl.DateTimeFormat("en-US", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }).format(new Date(ob.created_at))}
                    </p>
                    {ob.author.id === props.loggedInUser?.user_id ? (
                      <div
                        onClick={(e) => {
                          deleteComment(e, ob);
                        }}
                      >
                        <TrashBin />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {ob.attachment ? (
                    <img
                      className={classes.AssetImage}
                      onClick={() => {
                        setDisplayImage(ob.attachment);
                        displayImageToggle();
                      }}
                      alt="projectImage"
                      src={ob.attachment}
                      width="75%"
                    />
                  ) : null}
                  <div
                    className={classes.textColor}
                    dangerouslySetInnerHTML={{
                      __html: ob.description,
                    }}
                  />
                  <Divider />
                </>
              );
            })
          : ""}
      </div>
      <div>
        <Modal
          className={classes.updatesPageModals}
          open={isDeleteCommentModalOpen}
          onClose={() => setDeleteCommentModalOpen(false)}
        >
          <DeleteModal
            type="Comment"
            name={selectedCommentId}
            handleCloseDeleteModal={() => {
              setDeleteCommentModalOpen(false);
            }}
            handleDelete={handleDeleteComment}
          />
        </Modal>
      </div>
    </>
  );
}

export default UpdatesPageCardCommentDiv;
