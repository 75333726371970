import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "360px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    fontFamily: "Poppins",
    border: `2px solid ${theme.palette.secondary.main}`,
    zIndex: "-1",
  },

  card: {
    borderRadius: "20px",
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    padding: "13px 27px",
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: "600",
  },
  clear: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },
  typography: {
    margin: "10px 0px",
    color: theme.palette.text.primary,
  },
  cardContentDiv: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
    fontWeight: "normal",
    margin: "5px 0px",
  },
  cardAction: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    padding: "13px 27px",
    justifyContent: "space-between",
    "& :hover": {
      color: theme.palette.text.tertairy,
    },
  },
  doneButton: {
    backgroundColor: theme.palette.secondary.main,
    height: "40px",
    width: "115px",
    textTransform: "none",
    padding: "6px ",
    borderRadius: "7px",
    marginBottom: "15px",
    color: theme.palette.text.secondary,
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
    direction: "row",
    justifyContent: "space-between",
  },
  datePickerDiv: {
    width: "93%",
    overflowY: "hidden",
    height: "4.3rem",
    marginTop: "-9px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "normal",
  },
  datePickerComponent: {
    // marginLeft: "4px",
    // marginBottom: "2.2px",
    padding: "9px",
    borderRadius: "5px",
    backgroundColor: theme.palette.secondary.light,
    width: "100%",
    minHeight: "28px",
  },
}));

export default useStyles;
