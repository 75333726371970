import React, { useState, useEffect } from "react";
//@MUI
import { Button, Modal, Snackbar } from "@material-ui/core";
//@API
import { fget, fdelete, fpatch, fpostBFF } from "../../../API/callsAPI";

//@css
import useStyles from "./MyTasksOverview.styles";

//@Components
import NoData from "../../../Pages/InformativeComponents/NoData.component";
import MyTaskPageSortByModal from "../MyTaskPageModals/MyTaskPageSortByModal";
import MyTaskPageFiltersModal from "../MyTaskPageModals/MyTaskPageFiltersModal";
import TaskPageGanttViewOverview from "../TaskPageViews/TaskPageGanttView/TaskPageGanttViewOverview";
import TaskPageListViewOverview from "../TaskPageViews/TaskPageListView/TaskPageListViewOverview";
import { clearTrackable, reOrderTask } from "./TaskPageUtilities";
import { ViewMode } from "movie-collab-gantt";
import TaskPageTools from "../TaskPageTools/TaskPageTools.component";
import NewTask from "../TaskPageModals/NewTaskModalOverview";
import TaskPageTableViewOverview from "../TaskPageViews/TaskPageTableView/TaskPageTableViewOverview";
import CreateTaskOptionModal from "../MyTaskPageModals/CreateTaskOptionModal";
import CreateTaskFromTemplate from "../TaskPageModals/CreateTaskFromTemplate";
import { Alert } from "@material-ui/lab";
import TaskPageKanbanViewOverview from "../TaskPageViews/TaskPageKanbanView/TaskPageKanbanViewOverview/TaskPageKanbanViewOverview.component";
import { StatusRearrange } from "../../Utilities/StatusRearrange/StatusRearrange";
import Loader from "../../Utilities/Loader/Loader";
import { CaretLeft, CaretRight } from "phosphor-react";
import { ScaleLoader } from "react-spinners";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";

function TaskComponentOverview(props) {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [taskStatus, setTaskStatus] = useState();
  const [departments, setDepartments] = useState();
  const [projectUsers, setProjectUsers] = useState([]);
  const [showMyTask, setShowMyTask] = useState(false);
  //modal state
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [isSortModalOpen, setSortModalOpen] = useState(false);
  const [sortByDefault] = useState("A-Z");
  const [isCreateTaskOptionModalOpen, setCreateTaskOptionModalOpen] =
    useState(false);
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [
    isCreateTaskFromTemplateModalOpen,
    setIsCreateTaskFromTemplateModalOpen,
  ] = useState(false);
  //gantt states
  const [taskViewMode, setTaskViewMode] = useState("List");
  const [ganttViewMode, setGanttViewMode] = useState(ViewMode.Day);
  const [ganttSaveEvent, setGanttSaveEvent] = useState({});
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [assetList, setAssetList] = useState([]);
  const [sequenceList, setSequenceList] = useState([]);
  // filter states
  const [selectedDepartmentForFilter, setSelectedDepartmentForFilter] =
    useState("");
  const [selectedStartDateForFilter, setSelectedStartDateForFilter] = useState(
    {}
  );
  const [selectedEndDateForFilter, setSelectedEndDateForFilter] = useState({});
  const [selectedUserForFilter, setSelectedUserForFilter] = useState("");
  const [selectedTrackableForFilter, setSelectedTrackableForFilter] =
    useState("");

  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [updating, setUpdating] = useState(false);
  const [ganttFilterLoad, setGanttFilterLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTaskCount, setTotalTaskCount] = useState(0);
  const [isPreviousPageAvailable, setIsPreviousPageAvailable] = useState(false);
  const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);

  const updateRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };
  useEffect(() => {
    if (props.trackableType === "asset" || "shot") {
      fetchFilteredTasks({
        trackableType: props.trackableType,
        id: props.match.params.Id,
      });
    } else {
      fetchFilteredTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter, showMyTask, currentPage]);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //change it later
    return () => {
      clearTrackable();
    };
  }, []);

  useEffect(() => {
    getAllTrackables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTasksDepartmentAndStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTasksDepartmentAndStatusList = async () => {
    setIsLoaded(false);
    const data = {
      url: "web/tasks/",
      data: {
        project_id: props.match.params.projectId,
      },
    };
    try {
      const res = await fpostBFF(data);
      if (props.trackableType === "task") {
        setTaskList(res?.data?.tasks?.results);
      }
      setTaskStatus(StatusRearrange(res?.data?.status_list?.results));
      setDepartments(res?.data?.department_list?.results);
      setTotalTaskCount(res?.data?.tasks?.count);
      setIsPreviousPageAvailable(
        res?.data?.tasks?.previous !== null ? true : false
      );
      setIsNextPageAvailable(res?.data?.tasks?.next !== null ? true : false);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTrackables = async () => {
    try {
      const trackableRes = await fget({
        url: `trackables/asset/?project=${props.match.params.projectId}`,
      });
      setAssetList(trackableRes.data.results);
      const sequenceListRes = await fget({
        url: `trackables/shot-sequence/?project=${props.match.params.projectId}`,
      });
      setSequenceList(sequenceListRes.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredTasks = async ({ trackableType, id }) => {
    let url = `trackables/task/?project=${
      props.match.params.projectId
    }&assigned=${showMyTask ? 1 : 0}&page=${currentPage}`;

    if (trackableType && id) {
      url = `trackables/task/?project=${
        props.match.params.projectId
      }&linked=${id}&assigned=${showMyTask ? 1 : 0}`;
    }

    try {
      const taskListRes = await fget({
        url: url,
      });
      setTaskList(taskListRes.data.results);
      setIsPreviousPageAvailable(
        taskListRes?.data?.previous !== null ? true : false
      );
      setIsNextPageAvailable(taskListRes?.data?.next !== null ? true : false);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
      setIsNewPageLoading(false);
    }
  };

  const rearrangeTaskOrder = async (orderArray) => {
    try {
      await fpatch({
        url: "trackables/task-order/",
        data: { order: orderArray },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: `project/${props.match.params.projectId}/users`,
      });
      if (usersRes.status === 200) {
        setProjectUsers(usersRes.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleNewTaskModalState() {
    setIsNewTaskModalOpen(!isNewTaskModalOpen);
  }

  function handleCreateTaskFromTemplateModalState() {
    setIsCreateTaskFromTemplateModalOpen(!isCreateTaskFromTemplateModalOpen);
  }

  function handleCreateTaskOptionModalState() {
    setCreateTaskOptionModalOpen(!isCreateTaskOptionModalOpen);
  }

  function handleFilterModalState() {
    setFilterModalOpen(!isFilterModalOpen);
  }

  function handleSortModalState() {
    setSortModalOpen(!isSortModalOpen);
  }
  //change on tab toggle
  function handleTabChange(mode) {
    setShowMyTask(false);
    setTaskViewMode(mode);
  }
  function handleGanttViewModeChange(viewMode) {
    setGanttViewMode(viewMode);
  }
  function handleSubmitSort(sortBy) {
    setSortModalOpen(false);

    // switch (sortBy) {
    //   case "A-Z":
    //     taskList.sort((a, b) => a.name.localeCompare(b.name));
    //     setSortByDefault("A-Z");
    //     break;
    //   case "Z-A":
    //     taskList.sort((a, b) => b.name.localeCompare(a.name));
    //     setSortByDefault("Z-A");
    //     break;
    //   case "Deadline Date":
    //     taskList.sort(function (a, b) {
    //       var dateA = new Date(a.end_time),
    //         dateB = new Date(b.end_time);
    //       return dateA - dateB;
    //     });
    //     setSortByDefault("Deadline Date");
    //     break;
    //   case "Starting Date":
    //     taskList.sort(function (a, b) {
    //       var dateA = new Date(a.start_time),
    //         dateB = new Date(b.start_time);
    //       return dateA - dateB;
    //     });
    //     setSortByDefault("Starting Date");
    //     break;
    //   default:
    //     return taskList;
    // }
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  async function handleFilterBy(start, end, dept, user, trackable) {
    setGanttFilterLoad(true);
    setIsLoaded(false);
    const startDate = formatDate(new Date(start));
    // const endDate = formatDate(new Date(end));
    const currentDate = formatDate(Date.now());
    let filteredTask = [];
    console.log(startDate, dept, user, trackable);
    console.log(startDate !== "NaN-NaN-NaN" ? true : false);
    console.log(dept ? true : false);
    console.log(user ? true : false);
    console.log(trackable ? true : false);

    try {
      // when date, dept, user, trackable is selected
      if (
        startDate !== "NaN-NaN-NaN" &&
        dept &&
        dept !== "All Departments" &&
        user &&
        user !== "All Users" &&
        trackable &&
        trackable !== "All Trackables"
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&from=${startDate}&to=${currentDate}&department=${dept}&assigned_users=${user}&linked=${trackable}`,
        });
        filteredTask = result.data.results;
      }
      // when date, dept, user is selected and trackable is not selected or set as All Trackables
      else if (
        startDate !== "NaN-NaN-NaN" &&
        dept &&
        dept !== "All Departments" &&
        user &&
        user !== "All Users" &&
        (!trackable || trackable === "All Trackables")
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&from=${startDate}&to=${currentDate}&department=${dept}&assigned_users=${user}`,
        });
        filteredTask = result.data.results;
      }

      // when date, dept, trackable is selected and user is not selected or set as All Users
      else if (
        startDate !== "NaN-NaN-NaN" &&
        dept &&
        dept !== "All Departments" &&
        (!user || user === "All Users") &&
        trackable &&
        trackable !== "All Trackables"
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&from=${startDate}&to=${currentDate}&department=${dept}&linked=${trackable}`,
        });
        filteredTask = result.data.results;
      }
      // when date, user, trackable is selected and dept is not selected or set as All Departments
      else if (
        startDate !== "NaN-NaN-NaN" &&
        user &&
        user !== "All Users" &&
        (!dept || dept === "All Departments") &&
        trackable &&
        trackable !== "All Trackables"
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&from=${startDate}&to=${currentDate}&assigned_users=${user}&linked=${trackable}`,
        });
        filteredTask = result.data.results;
      }

      // when dept, user, trackable is selected and date is not selected
      else if (
        startDate === "NaN-NaN-NaN" &&
        user &&
        user !== "All Users" &&
        dept &&
        dept !== "All Departments" &&
        trackable &&
        trackable !== "All Trackables"
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&assigned_users=${user}&department=${dept}&linked=${trackable}`,
        });
        filteredTask = result.data.results;
      }

      // when only date and dept is selected
      else if (
        startDate !== "NaN-NaN-NaN" &&
        dept &&
        dept !== "All Departments" &&
        (!user || user === "All Users") &&
        (!trackable || trackable === "All Trackables")
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&from=${startDate}&to=${currentDate}&department=${dept}`,
        });
        filteredTask = result.data.results;
      }

      // when only date and user is selected
      else if (
        startDate !== "NaN-NaN-NaN" &&
        (!dept || dept === "All Departments") &&
        user &&
        user !== "All Users" &&
        (!trackable || trackable === "All Trackables")
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&from=${startDate}&to=${currentDate}&assigned_users=${user}`,
        });
        filteredTask = result.data.results;
      }

      // when only date and trackable is selected
      else if (
        startDate !== "NaN-NaN-NaN" &&
        (!dept || dept === "All Departments") &&
        (!user || user === "All Users") &&
        trackable &&
        trackable !== "All Trackables"
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&from=${startDate}&to=${currentDate}&linked=${trackable}`,
        });
        filteredTask = result.data.results;
      }

      // when only dept and user is selected
      else if (
        startDate === "NaN-NaN-NaN" &&
        dept &&
        dept !== "All Departments" &&
        user &&
        user !== "All Users" &&
        (!trackable || trackable === "All Trackables")
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&department=${dept}&assigned_users=${user}`,
        });
        filteredTask = result.data.results;
      }

      // when only dept and trackable is selected
      else if (
        startDate === "NaN-NaN-NaN" &&
        dept &&
        dept !== "All Departments" &&
        (!user || user === "All Users") &&
        trackable &&
        trackable !== "All Trackables"
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&department=${dept}&linked=${trackable}`,
        });
        filteredTask = result.data.results;
      }

      // when only user and trackable is selected
      else if (
        startDate === "NaN-NaN-NaN" &&
        (!dept || dept === "All Departments") &&
        user &&
        user !== "All Users" &&
        trackable &&
        trackable !== "All Trackables"
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${
            showMyTask ? 1 : 0
          }&assigned_users=${user}&linked=${trackable}`,
        });
        filteredTask = result.data.results;
      }

      // when only date is selected
      else if (
        startDate !== "NaN-NaN-NaN" &&
        (!dept || dept === "All Departments") &&
        (!user || user === "All Users") &&
        (!trackable || trackable === "All Trackables")
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${showMyTask ? 1 : 0}&from=${startDate}&to=${currentDate}`,
        });
        filteredTask = result.data.results;
      }

      // when only dept is selected
      else if (
        startDate === "NaN-NaN-NaN" &&
        dept &&
        dept !== "All Departments" &&
        (!user || user === "All Users") &&
        (!trackable || trackable === "All Trackables")
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${showMyTask ? 1 : 0}&department=${dept}`,
        });
        filteredTask = result.data.results;
      }

      // when only user is selected
      else if (
        startDate === "NaN-NaN-NaN" &&
        (!dept || dept === "All Departments") &&
        user &&
        user !== "All Users" &&
        (!trackable || trackable === "All Trackables")
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${showMyTask ? 1 : 0}&assigned_users=${user}`,
        });
        filteredTask = result.data.results;
      }

      // when only trackable is selected
      else if (
        startDate === "NaN-NaN-NaN" &&
        (!dept || dept === "All Departments") &&
        (!user || user === "All Users") &&
        trackable &&
        trackable !== "All Trackables"
      ) {
        const result = await fget({
          url: `trackables/task/?project=${
            props.match.params.projectId
          }&assigned=${showMyTask ? 1 : 0}&linked=${trackable}`,
        });
        filteredTask = result.data.results;
      }

      // when there is no selection/default
      else filteredTask = taskList;

      setTaskList(filteredTask);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }

    handleFilterModalState();
  }

  const handleTaskDelete = async (id) => {
    try {
      await fdelete({
        url: `trackables/task/${id}/`,
      });
      updateRefreshCounter();
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted`,
        isError: false,
      });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `Oops, some error occured. Please try again`,
        isError: true,
      });
    }
  };
  const [taskTemplateList, setTaskTemplateList] = useState([]);
  useEffect(() => {
    getTasksTemplates();
  }, []);

  const getTasksTemplates = async () => {
    try {
      const url = `task-template/?project=${props.match.params.projectId}`;
      const res = await fget({ url });
      setTaskTemplateList(res?.data?.results);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {updating ? (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="info">Updating Please wait</Alert>
        </Snackbar>
      ) : (
        <ErrorOrSuccessMessage snackValue={snackValue} />
      )}
      <TaskPageTools
        openFilterModal={handleFilterModalState}
        openSortModal={handleSortModalState}
        openNewTaskModal={handleNewTaskModalState}
        onTabChange={handleTabChange}
        onGanttViewModeChange={(view) => handleGanttViewModeChange(view)}
        externalSaveEvent={ganttSaveEvent}
        trackableType={props.trackableType}
        params={props.match.params}
        showMyTaskCheckBox={true}
        myTaskCheckBoxState={showMyTask}
        onCheckBoxClick={() => setShowMyTask(!showMyTask)}
        handleCreateTaskOptionModalState={handleCreateTaskOptionModalState}
        updateRefreshCounter={updateRefreshCounter}
      />
      {isLoaded ? (
        <>
          {taskList && taskList.length !== 0 ? (
            <>
              {taskViewMode === "List" ? (
                <TaskPageListViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  groupedTask={reOrderTask(taskList)}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  projectId={props.match.params.projectId}
                  handleTaskDelete={handleTaskDelete}
                  setUpdating={setUpdating}
                  trackableType={props.trackableType}
                />
              ) : taskViewMode === "Gantt" ? (
                <TaskPageGanttViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  task={taskList}
                  statusList={taskStatus}
                  projectId={props.match.params.projectId}
                  viewModeUser={ganttViewMode}
                  projectUsers={projectUsers}
                  externalSaveEvent={(event) => setGanttSaveEvent(event)}
                  onTaskRefresh={updateRefreshCounter}
                  absoluteLinked={
                    props.trackableType === "asset" || "shot"
                      ? props.match.params.Id
                      : null
                  }
                  departments={departments}
                  handleTaskDelete={handleTaskDelete}
                  ganttFilterLoad={ganttFilterLoad}
                  setGanttFilterLoad={setGanttFilterLoad}
                  trackableType={props.trackableType}
                />
              ) : taskViewMode === "Table" ? (
                <TaskPageTableViewOverview
                  rearrangeTaskOrder={rearrangeTaskOrder}
                  groupedTask={reOrderTask(taskList)}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  handleTaskDelete={handleTaskDelete}
                  trackableType={props.trackableType}
                />
              ) : taskViewMode === "Kanban" ? (
                <TaskPageKanbanViewOverview
                  taskList={taskList}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  projectUsers={projectUsers}
                  departments={departments}
                  setSnackValue={setSnackValue}
                  handleNewTaskModalState={handleNewTaskModalState}
                  setUpdating={setUpdating}
                  projectId={props.match.params.projectId}
                  handleTaskDelete={handleTaskDelete}
                  trackableType={props.trackableType}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <NoData />
          )}
          <>
            <div className={classes.paginationDiv}>
              <span className={classes.text}>
                Trackable showing : {reOrderTask(taskList)?.noOfTrackables}
              </span>
              <div className={classes.paginationRightTextAndButtons}>
                <span className={classes.text}>
                  Tasks {(currentPage - 1) * 50 + 1} -{" "}
                  {currentPage * 50 > totalTaskCount
                    ? totalTaskCount
                    : currentPage * 50}{" "}
                  out of {totalTaskCount}
                </span>
                <div className={classes.nextAndPrev}>
                  <Button
                    className={classes.previous}
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                      setIsNewPageLoading(true);
                    }}
                    disabled={!isPreviousPageAvailable ? true : false}
                  >
                    <CaretLeft size={20} />
                    <span>Previous</span>
                  </Button>
                  <Button
                    className={classes.next}
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                      setIsNewPageLoading(true);
                    }}
                    disabled={!isNextPageAvailable ? true : false}
                  >
                    <span>Next</span>
                    <CaretRight size={20} />
                  </Button>
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <Loader />
      )}
      <div>
        <Modal
          open={isFilterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          className={classes.TaskPageModals}
        >
          <MyTaskPageFiltersModal
            handleFilter={(
              selectedStartDate,
              selectedEndDate,
              selectedDepartment,
              selectedUser,
              selectedTrackable
            ) =>
              handleFilterBy(
                selectedStartDate,
                selectedEndDate,
                selectedDepartment,
                selectedUser,
                selectedTrackable
              )
            }
            closeModal={handleFilterModalState}
            departments={departments}
            selectedDepartment={selectedDepartmentForFilter}
            setSelectedDepartment={setSelectedDepartmentForFilter}
            selectedStartDate={selectedStartDateForFilter}
            setSelectedStartDate={setSelectedStartDateForFilter}
            selectedEndDate={selectedEndDateForFilter}
            setSelectedEndDate={setSelectedEndDateForFilter}
            projectUsers={projectUsers}
            selectedUser={selectedUserForFilter}
            setSelectedUser={setSelectedUserForFilter}
            selectedTrackable={selectedTrackableForFilter}
            setSelectedTrackable={setSelectedTrackableForFilter}
            trackableList={taskList}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isSortModalOpen}
          onClose={() => setSortModalOpen(false)}
        >
          <MyTaskPageSortByModal
            onSubmit={handleSubmitSort}
            defaultvalue={sortByDefault}
            closeModal={handleSortModalState}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isNewTaskModalOpen}
          onClose={() => setIsNewTaskModalOpen(false)}
        >
          <NewTask
            projectId={props.match.params.projectId}
            taskCount={taskList.length}
            defaultLinked={
              props.trackableType === "asset" || "shot"
                ? props.match.params.Id
                : ""
            }
            allUsers={projectUsers}
            // allTrackables={getTrackableOfTask()}
            sequenceList={sequenceList}
            assetList={assetList}
            departments={departments}
            taskStatus={taskStatus}
            handleNewTaskModal={handleNewTaskModalState}
            updateRefreshCounter={updateRefreshCounter}
            trackableType={props.trackableType}
            setSnackValue={setSnackValue}
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isCreateTaskOptionModalOpen}
          onClose={() => setCreateTaskOptionModalOpen(false)}
        >
          <CreateTaskOptionModal
            closeModal={handleCreateTaskOptionModalState}
            handleNewTaskModal={handleNewTaskModalState}
            handleCreateTaskFromTemplateModal={
              handleCreateTaskFromTemplateModalState
            }
          />
        </Modal>
        <Modal
          className={classes.TaskPageModals}
          open={isCreateTaskFromTemplateModalOpen}
          onClose={() => setIsCreateTaskFromTemplateModalOpen(false)}
        >
          <CreateTaskFromTemplate
            projectId={props.match.params.projectId}
            defaultLinked={
              props.trackableType === "asset" || "shot"
                ? props.match.params.Id
                : ""
            }
            sequenceList={sequenceList}
            assetList={assetList}
            departments={departments}
            handleCreateTaskFromTemplateModalState={
              handleCreateTaskFromTemplateModalState
            }
            updateRefreshCounter={updateRefreshCounter}
            trackableType={props.trackableType}
            setSnackValue={setSnackValue}
            taskStatus={taskStatus}
            allUsers={projectUsers}
            taskTemplateList={taskTemplateList}
          />
        </Modal>
        <Modal className={classes.TaskPageModals} open={isNewPageLoading}>
          <div className={classes.newPageLoader}>
            <ScaleLoader
              color="#6C65D9"
              height={55}
              style={{
                display: "flex",
                verticalAlign: "middle",
              }}
            />
            <div style={{ fontSize: "48px" }}>Please Wait</div>
            <div style={{ fontSize: "24px" }}>Loading More Tasks...</div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default TaskComponentOverview;
