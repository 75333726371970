import React from "react";
import * as Icon from "react-feather";

//@MUI
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
import useStyles from "./ShotsPageTools.styles";
import {
  SortButton,
  ToggleButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component.jsx";

export default function ShotsPageTools(props) {
  const classes = useStyles();
  const [nameStatus, setnameStatus] = React.useState("");

  const [sliderState, setSliderState] = React.useState(0);

  React.useEffect(() => {
    if (props.sliderState > 0) {
      setSliderState(props.sliderState);
      sessionStorage.setItem("sliderState", `${sliderState}`);
    } else setSliderState(sessionStorage.getItem("sliderState"));
  }, [props.sliderState, sliderState]);

  React.useEffect(() => {
    if (props.filterValues.status && props.status.length) {
      setnameStatus(
        props.status.filter((e) => e.id === props.filterValues.status)[0].name
      );
    }
  }, [props.filterValues.status, props.status]);

  if (sessionStorage.getItem("sliderState") === null)
    sessionStorage.setItem("sliderState", 0);

  return (
    <div className={classes.root}>
      <div>
        <BreadCrumb />
      </div>
      <div className={classes.container}>
        <div>
          <ToggleButton handleToggler={props.handleShotPageToggler} />
        </div>
        <div>
          <SortButton
            onSubmit={props.onSubmit}
            defaultvalue={props.defaultvalue}
          />
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <div
              style={{ justifyContent: "space-between" }}
              className={classes.dropdownDiv}
            >
              <text className={classes.selectText}>
                {props.filterValues.status ? nameStatus : "Select By Status"}
              </text>
              <Icon.ChevronDown className={classes.iconStyle} />
            </div>
            <Select
              className={classes.formLabelText}
              labelId="demo-simple-select-outlined-label"
              id="selectStatus"
              label="Select Status"
              value={props.filterValues.status}
              onChange={(event) => {
                props.handleStatusChange(event.target.value[0]);
                setnameStatus(event.target.value[1]);
              }}
              MenuProps={{
                classes: {
                  paper: classes.dropDown,
                },
              }}
            >
              <MenuItem value={["", "None"]}>
                <em>None</em>
              </MenuItem>
              {props.status &&
                props.status.map((el) => (
                  <MenuItem value={[el.id, el.name]} key={el.id}>
                    {el.name} - {el.id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {/* <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <div
                style={{ justifyContent: "space-between" }}
                className={classes.dropdownDiv}
              >
                <text className={classes.selectText}>
                  {props.filterValues.department
                    ? nametype
                    : "Select By Department"}
                </text>
                <Icon.ChevronsDown className={classes.iconStyle} />
              </div>

              <Select
                className={classes.formLabelText}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Select Department"
                value={props.filterValues.department}
                onChange={(event) => {
                  props.handleDepartmentChange(event.target.value[0]);
                  setNameType(event.target.value[1]);
                }}
              >
                <MenuItem value={["", "None"]}>
                  <em>None</em>
                </MenuItem>
                {props.departmentsList &&
                  props.departmentsList.map((el) => (
                    <MenuItem value={[el.id, el.name]} key={el.id}>
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid> */}
        {/* <Grid item style={{ marginRight: "6px" }}>
              <Button
                size="small"
                className={classes.buttonDeleteSequence}
                disableElevation
                onClick={props.openDeleteSequenceModal}
              >
                <Icon.Trash2 className={classes.plusIcon} />
                <p className={classes.buttonText}>Delete Sequence</p>
              </Button>
            </Grid>
            <Grid item style={{ marginRight: "6px" }}>
              <Button
                size="small"
                className={classes.buttonEditSequence}
                disableElevation
                onClick={props.openEditSequenceModal}
              >
                <Icon.Edit3 className={classes.plusIcon} />
                <p className={classes.buttonText}>Edit Sequence</p>
              </Button>
            </Grid> */}
        <div>
          <Button
            size="small"
            className={classes.shotsPageNewShotButton}
            disableElevation
            onClick={props.openNewShotModal}
          >
            <Icon.Plus className={classes.plusIcon} />
            New Shot
          </Button>
        </div>
      </div>
    </div>
  );
}
