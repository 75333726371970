import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";

//@MUI
import {
  Button,
  Checkbox,
  Tab,
  Tabs,
  Select,
  MenuItem,
} from "@material-ui/core";
import useStyles from "./MyTasksPageTool.styles";
import {
  Funnel,
  Kanban,
  ListBullets,
  Table,
  TreeStructure,
} from "phosphor-react";
import TaskGanttNavBar from "../../Utilities/TaskGantt/TaskGanttNavBar/TaskGanttNavBar.jsx";
import { useHistory } from "react-router";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
function TaskPageTools(props) {
  const classes = useStyles();
  const history = useHistory();
  const [view, setView] = useState("List");
  //important for the dropdown select for views
  const [isScreenSize, setScreenSize] = useState(window.innerWidth > 1112);
  const [trackableDetail, setTrackableDetail] = useState({
    name: "Task",
    url: null,
  });
  const updateScreenSize = () => {
    setScreenSize(window.innerWidth > 1112);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  });
  useEffect(() => {
    props.onTabChange(view);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);
  useEffect(() => {
    if (props.trackableType === "asset") {
      setTrackableDetail({
        name: "Asset",
        url: `/${props.params.projectId}/assets/`,
      });
    }
    if (props.trackableType === "shot") {
      setTrackableDetail({
        name: "Shot",
        url: `/${props.params.projectId}/sequence/${props.params.sid}/shots/`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {props.trackableType !== "task" ? <BreadCrumb /> : ""}
      <div className={classes.root}>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <h1
            className={classes.title}
            onClick={() => {
              if (trackableDetail.url) {
                history.push(trackableDetail.url);
              }
            }}
          >
            {trackableDetail.name}
          </h1>
          <div>
            {isScreenSize ? (
              <Tabs
                value={view}
                onChange={(event, value) => {
                  setView(value);
                }}
                style={{ marginLeft: "30px", minHeight: "50px" }}
              >
                {/* <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ListBullets
                      style={{
                        margin: "4px 12px",
                        height: "22px",
                        width: "22px",
                      }}
                    />
                    <span>List View</span>
                  </div>
                }
                value={"List"}
                style={{
                  width: "400px",
                  marginLeft: "30px",
                  minHeight: "50px",
                }}
              /> */}
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ListBullets
                        style={{
                          margin: "4px 12px",
                          height: "22px",
                          width: "22px",
                        }}
                      />
                      <span>List View</span>
                    </div>
                  }
                  value={"List"}
                  style={{
                    minWidth: "65px",
                    fontSize: "14px",
                    minHeight: "30px",
                    padding: "2px",
                  }}
                />
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TreeStructure
                        style={{
                          margin: "4px 12px",
                          height: "22px",
                          width: "22px",
                        }}
                      />
                      <span>Gantt View</span>
                    </div>
                  }
                  value={"Gantt"}
                  style={{
                    minWidth: "65px",
                    fontSize: "14px",
                    minHeight: "30px",
                    padding: "2px",
                  }}
                />
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Table
                        style={{
                          margin: "4px 12px",
                          height: "22px",
                          width: "22px",
                        }}
                      />
                      <span>Table View</span>
                    </div>
                  }
                  value={"Table"}
                  style={{ minWidth: "46px", fontSize: "14px", padding: "2px" }}
                />
                <Tab
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Kanban
                        style={{
                          margin: "4px 12px",
                          height: "22px",
                          width: "22px",
                        }}
                      />
                      <span>Kanban View</span>
                    </div>
                  }
                  value={"Kanban"}
                  style={{ minWidth: "46px", fontSize: "14px", padding: "2px" }}
                />
              </Tabs>
            ) : (
              <Select
                style={{
                  marginLeft: "30px",
                  minHeight: "50px",
                }}
                autoWidth={true}
                disableUnderline={true}
                value={view}
                label={view}
                onChange={(event) => setView(event.target.value)}
              >
                <MenuItem className={classes.menuitem} value={"List"}>
                  List View
                </MenuItem>
                <MenuItem className={classes.menuitem} value={"Gantt"}>
                  Gantt View
                </MenuItem>
                <MenuItem className={classes.menuitem} value={"Table"}>
                  Table View
                </MenuItem>
                <MenuItem className={classes.menuitem} value={"Kanban"}>
                  Kanban View
                </MenuItem>
              </Select>
            )}
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div>
            {view === "Gantt" ? (
              <TaskGanttNavBar
                onViewChange={props.onGanttViewModeChange}
                onSave={() => props.externalSaveEvent.triggerSaveEvent()}
                isDateChanged={props.externalSaveEvent?.isDateChanged}
              />
            ) : (
              <>
                {props.showMyTaskCheckBox ? (
                  <div style={{ width: "200px" }}>
                    <Checkbox
                      checked={props.myTaskCheckBoxState}
                      onChange={props.onCheckBoxClick}
                    />
                    <span className={classes.checkBoxText}>
                      Show only my tasks
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <Button
            style={{ padding: "8px 4px", minWidth: "0px" }}
            onClick={() => props.updateRefreshCounter()}
          >
            <Icon.RotateCw />
          </Button>
          <Button
            className={classes.taskPageButton}
            onClick={props.openFilterModal}
          >
            <Funnel size={27} className={classes.iconStyle} />
            <p className={classes.text}>Filter</p>
          </Button>

          {/* <Button
            className={classes.taskPageButton}
            onClick={props.openSortModal}
          >
            <SortAscending size={27} className={classes.iconStyle} />
            <p className={classes.text}>Sort</p>
          </Button> */}
          <Button
            color="secondary"
            size="small"
            variant="contained"
            className={classes.taskPageButton}
            onClick={props.handleCreateTaskOptionModalState}
            // disabled={props.trackableType === "task" ? true : false}
          >
            <Icon.Plus className={classes.plusIcon} />
            <p className={classes.text}>New Task</p>
          </Button>
        </div>
      </div>
      <hr className={classes.hrElement} />
    </div>
  );
}

export default TaskPageTools;
