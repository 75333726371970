import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  shotsPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shotsTableContainer: {
    marginTop: "5px",
    width: "100%",
  },
  table: {
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  tableRow: {
    borderCollapse: "collapse",
  },
  tableBody: {
    display: "block",
    overflowY: "auto",
    [theme.breakpoints.down(1205)]: {
      height: `calc(100vh - 300px)`,
    },
    [theme.breakpoints.down(801)]: {
      height: `calc(100vh - 350px)`,
    },
  },
  shotPageTableCell: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    backgroundColor: theme.palette.primary.main,
    zIndex: "50",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
}));

export default useStyles;
