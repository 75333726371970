import React from "react";
import { Button, Card, CardContent, Select, MenuItem } from "@material-ui/core";

import useStyles from "./MyTaskPageFiltersModal.styles";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { X } from "react-feather";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function MyTaskPageFiltersModal(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div class={classes.popoverArrow}></div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className={classes.title}>Filters</h2>
            <div className={classes.clear} onClick={(e) => props.closeModal()}>
              <X size="30px" />
            </div>
          </div>
          <div className={classes.cardContentDiv}>
            <div className={classes.datePickerContainer}>
              <div>
                From
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={props.selectedStartDate}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      onChange={(date) => props.setSelectedStartDate(date)}
                      id="Startdate-picker"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div>
                To
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={props.selectedEndDate}
                      onChange={(date) => props.setSelectedEndDate(date)}
                      id="Enddate-picker"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
          <div>
            <label id="demo-simple-select">Department</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={props.selectedDepartment}
              onChange={(e) => props.setSelectedDepartment(e.target.value)}
              name="department"
            >
              <MenuItem value="All Departments">All Departments</MenuItem>
              {props.departments &&
                props.departments.map((eachDepartment) => (
                  <MenuItem value={eachDepartment.id}>
                    {eachDepartment.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ marginTop: "10px" }}>
            <label id="demo-simple-select">Assigned User</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={props.selectedUser}
              onChange={(e) => props.setSelectedUser(e.target.value)}
              name="department"
            >
              <MenuItem value="All Users">All Users</MenuItem>
              {props.projectUsers &&
                props.projectUsers.map((eachUser) => (
                  <MenuItem value={eachUser.id}>
                    {eachUser.first_name} {eachUser.last_name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div style={{ marginTop: "10px" }}>
            <label id="demo-simple-select">Trackable</label>
            <Select
              style={{ padding: "3px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              className={classes.datePickerComponent}
              value={props.selectedTrackable}
              onChange={(e) => props.setSelectedTrackable(e.target.value)}
              name="trackable"
            >
              <MenuItem value="All Trackables">All Trackables</MenuItem>
              {props.trackableList &&
                props.trackableList.map((trackable) => (
                  <MenuItem value={trackable.linked.id}>
                    {trackable.linked.code || trackable.linked.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </CardContent>
        <div className={classes.cardAction}>
          <Button
            size="small"
            variant="outlined"
            className={classes.doneButton}
            onClick={() =>
              props.handleFilter(
                props.selectedStartDate,
                props.selectedEndDate,
                props.selectedDepartment,
                props.selectedUser,
                props.selectedTrackable
              )
            }
          >
            Done
          </Button>
          <Button
            size="small"
            style={{
              height: "40px",
              marginTop: "0.2rem",
            }}
            onClick={() => {
              props.setSelectedStartDate({});
              props.setSelectedEndDate({});
              props.setSelectedDepartment("");
              props.setSelectedUser("");
              props.setSelectedTrackable("");
            }}
          >
            <X
              size="20"
              style={{ marginTop: "-0.2rem", paddingRight: "3px" }}
            />
            Clear All
          </Button>
        </div>
      </Card>
    </div>
  );
}
