import { Button, Slider } from "@material-ui/core";
import React, { useState } from "react";
import captureVideoFrame from "../../../Player/capture-video-frame";
import * as Icon from "react-feather";
import { SpeakerHigh, SpeakerSlash } from "phosphor-react";
import { Play } from "phosphor-react";
import useStyles from "../ComparePageCard.styles";
import OnTop from "../../ComparePageTool/OnTop";
import SelectVersionIcon from "../../../Utilities/Svg/SelectVersionIcon";

export default function CardToolsGroup({
  versions,
  player,
  opacityValue,
  changeOpacityValue,
  currentDuration,
  onTimeSeek,
  handleOnPrevious,
  handleOnNext,
  refPlayer,
  volume,
  onVolumeChange,
  setPlayerTwo,
  type,
  setPlayer,
  setCanvasModal,
  setScreenshot,
  position,
  openModal,
  muted,
  onMute,
}) {
  const classes = useStyles();
  //shows and hides the volume control slider
  const [showControls, setShowControls] = useState(false);
  return (
    <>
      {/* upper part div, player one */}
      <div className={classes.div1}>
        {/* button options */}
        <div
          style={{
            display: "flex",
            height: "15%",
            width: "95%",
            marginTop: "1%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "45%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onMouseLeave={() => setShowControls(false)}
          >
            <Button
              style={{ minWidth: "23%" }}
              onMouseEnter={() => setShowControls(true)}
              onClick={() => onMute(!muted)}
            >
              {muted == false ? (
                <SpeakerHigh
                  style={{ height: "100%", width: "100%" }}
                  className={classes.volumebtn}
                  weight="duotone"
                />
              ) : (
                <SpeakerSlash
                  style={{ height: "100%", width: "100%" }}
                  className={classes.volumebtn}
                  weight="duotone"
                />
              )}
            </Button>
            {showControls ? (
              <Slider
                className={classes.volumeSlider}
                color="secondary"
                min={0}
                max={100}
                step={1}
                value={volume}
                valueLabelDisplay="auto"
                onChange={(seekEvent, value) => {
                  seekEvent.preventDefault();
                  seekEvent.stopPropagation();
                  onVolumeChange(value);
                }}
              />
            ) : (
              <div
                style={{
                  width: "85%",
                }}
              ></div>
            )}
          </div>
          <div
            style={{
              width: "45%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              style={{ minWidth: "4%", marginLeft: "5%" }}
              onClick={() => {
                const frame = captureVideoFrame(refPlayer.current);
                setScreenshot(frame.dataUri);
                setCanvasModal(true);
                type == "two" ? setPlayer("2") : setPlayer("1");
              }}
            >
              <Icon.Edit />{" "}
            </Button>
            <Button
              style={{ minWidth: "5%" }}
              onClick={() => {
                type == "two" ? openModal("2") : openModal("1");
              }}
            >
              <SelectVersionIcon />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "100%",
              width: "25%",
              marginTop: "1.3%",
            }}
          >
            <OnTop position={position} />
          </div>
        </div>

        <div style={{ height: "20%", width: "90%", marginBottom: "2%" }}>
          {/* video slider */}
          <Slider
            className={classes.seekbarSlider}
            color="secondary"
            min={0}
            max={100}
            step={0.5}
            value={(currentDuration / player.totalDuration) * 100}
            onChange={(seekEvent, value) => {
              seekEvent.preventDefault();
              seekEvent.stopPropagation();
              onTimeSeek((value / 100) * player.totalDuration);
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "-10px",
            }}
          >
            <span>
              {new Date(currentDuration * 1000).toISOString().substr(11, 8)}
            </span>
            <span>
              {new Date(player.totalDuration * 1000)
                .toISOString()
                .substr(11, 8)}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "20%",
            width: "90%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "18%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => handleOnPrevious(refPlayer)}
              className={classes.prevBtn}
            >
              <Play size={20} weight="fill" className={classes.btn} />
            </Button>
            <Button
              onClick={() => handleOnNext(refPlayer)}
              className={classes.nextBtn}
            >
              <Play size={20} weight="fill" className={classes.btn} />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "50%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8V6H18V8H16ZM16 10V8H14V10H16ZM16 6V4H14V6H16ZM14 0.84V2H16C15.37 1.54 14.71 1.15 14 0.84ZM16 2V4H18C17.42 3.25 16.75 2.58 16 2ZM18 4V6H19.16C18.85 5.29 18.46 4.63 18 4ZM20 10C20 9.32 19.93 8.65 19.8 8H18V10H20ZM14 4V2H12V4H14ZM14 14H16V12H14V14ZM16 16H18V14H16V16ZM14 18H16V16H14V18ZM12 19.8C12.7 19.66 13.36 19.44 14 19.16V18H12V19.8ZM16 12H18V10H16V12ZM14 6H12V8H14V6ZM18 14H19.16C19.44 13.36 19.66 12.7 19.8 12H18V14ZM14 10H12V12H14V10ZM10 16V14H12V12H10V10H12V8H10V6H12V4H10V2H12V0.2C11.35 0.0700001 10.69 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20V18H12V16H10ZM12 16H14V14H12V16Z"
                fill="#F9F9F9"
              />
            </svg>
            <Slider
              className={classes.opacitySlider}
              color="secondary"
              size="medium"
              min={0}
              max={1}
              step={0.1}
              valueLabelDisplay="auto"
              value={opacityValue}
              onChange={(seekEvent, value) => {
                seekEvent.preventDefault();
                seekEvent.stopPropagation();
                changeOpacityValue(value);
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "3%",
            height: "20%",
            width: "100%",
          }}
        >
          <div style={{ margin: "0px 17px " }}>
            <span style={{ fontSize: "20px" }}>{player.version.name}</span>
            <br />
            <span className={classes.infoOntop}>
              {player.version.status?.code}
            </span>
          </div>
          <div style={{ margin: "0px 17px" }}>
            <span className={classes.infoOntop}>
              {new Date(player.version.updated_at).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}{" "}
              {new Date(player.version.updated_at).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
            <br />
            {player.version.updated_by ? (
              <span className={classes.infoOntop}>
                Updated By {player.version.updated_by?.first_name}{" "}
                {player.version.updated_by?.last_name}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <p>{player.version.status?.name}</p>
          <div
            style={{
              backgroundColor: `rgb(${player.version.status?.color})`,
              height: "16px",
              width: "16px",
              borderRadius: "50%",
              marginLeft: "5px",
            }}
          />
        </div>
      </div>
    </>
  );
}
