import { useLocation } from "react-router-dom";
import useStyles from "./TaskVersionHover.styles";
import { Card, Divider } from "@material-ui/core";
import NoThumbnail from "../NoThumbnail.component";
import VersionIcon from "../Svg/VersionIcon";
import { useEffect, useState } from "react";

const TaskVersionHover = ({
  versionList,
  parent,
  setIsHover,
  tableView,
  versionLength,
  kanban,
}) => {
  const [margin, setMargin] = useState(55);
  const classes = useStyles();
  const location = useLocation();

  // the card element moves to top when more items are there in table view
  // to increase marginTop of card if so
  useEffect(() => {
    if (versionLength) {
      let marginTop = 55;
      for (let i = 3; i <= versionLength; i++) {
        marginTop += 40;
      }
      setMargin(marginTop);
    }
  }, [versionLength]);

  return (
    <div
      style={
        tableView
          ? {
              zIndex: 10,
              marginTop: `${margin}px`,
              marginLeft: "140px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }
          : {
              zIndex: 10,
              marginLeft: "20px",
              position: "relative",
            }
      }
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        class={
          tableView
            ? classes.popoverArrowTable
            : kanban
            ? classes.popoverArrowKanban
            : classes.popoverArrow
        }
      ></div>
      <Card className={kanban ? classes.card2 : classes.card}>
        {versionList.map((version, index) => {
          if (index !== 0) {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <VersionIcon />
                  {parent.thumbnail ? (
                    <img
                      style={{
                        borderRadius: "7px",
                        objectFit: "cover",
                        marginRight: "5px",
                        marginLeft: "10px",
                      }}
                      src={parent.thumbnail}
                      height="28px"
                      width="28px"
                      alt="Img"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  ) : (
                    <NoThumbnail
                      style={{
                        marginRight: "5px",
                        marginLeft: "10px",
                        height: "28px",
                        width: "28px",
                      }}
                    />
                  )}
                  {parent.name ? (
                    <span>
                      {parent.name} -{" "}
                      <span
                        className={classes.onHover}
                        onClick={() => {
                          location.pathname = `/${parent.project}/assets/${parent.id}/assetversions/`;
                          const encode = btoa(
                            unescape(
                              encodeURIComponent(`versionId=${version.id}`)
                            )
                          );

                          window.open(`${location.pathname}?${encode}`);
                        }}
                      >
                        {version.name}
                      </span>
                    </span>
                  ) : (
                    <span>
                      {parent.code} -{" "}
                      <span
                        className={classes.onHover}
                        onClick={() => {
                          location.pathname = `/${parent.project}/sequence/${parent.parent_sequence}/shots/${parent.id}/shotversions/`;
                          const encode = btoa(
                            unescape(
                              encodeURIComponent(`versionId=${version.id}`)
                            )
                          );

                          window.open(`${location.pathname}?${encode}`);
                        }}
                      >
                        {version.name}
                      </span>
                    </span>
                  )}
                </div>
                {versionList.at(-1) !== version && (
                  <Divider style={{ margin: "5px 0" }} />
                )}
              </>
            );
          }
        })}
      </Card>
    </div>
  );
};

export default TaskVersionHover;
