import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  shotVersionPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shotVersionListTableCell: {
    padding: "0px !important",
    overflow: "hidden",
    borderCollapse: "collapse",
    width: "17%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },
}));

export default useStyles;
