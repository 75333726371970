import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sortButton: {
    textTransform: "none",
    height: "40px",
    borderRadius: "10px",
    width: "auto",
    fontSize: "14px",
    color: theme.palette.text.primary,
    "& :first-child": {
      color: theme.palette.text.primary,
      margin: "8px 1px",
    },
    "& :last-child": {
      margin: "7px",
    },
  },
  popoverArrow: {
    position: "absolute",
    borderRadius: "5px",
    top: "-22px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  popoverArrowTriangle: {
    borderRadius: "3px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.secondary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
}));

export default useStyles;
