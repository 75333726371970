import React, { useState, useEffect } from "react";
import { X } from "react-feather";
import { Asterisk } from "phosphor-react";
import { Popper, CircularProgress } from "@material-ui/core";
import SelectTrackablesModal from "./SelectTrackablesModal";
import HelperText from "../../Utilities/HelperText/HelperText";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import useStyles from "./NewTask.styles.js";

import { fget, fpost } from "../../../API/callsAPI";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const CreateTaskFromTemplate = ({
  defaultLinked,
  setSnackValue,
  updateRefreshCounter,
  handleCreateTaskFromTemplateModalState,
  trackableType,
  assetList,
  sequenceList,
  taskTemplateList,
}) => {
  const classes = useStyles();
  const [templateId, setTemplateId] = useState("");
  const [selectedTrackable, setSelectedTrackable] = useState({});
  const [findAsset, setFindAsset] = useState(false);
  const [findSequence, setFindSequence] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [trackableError, setTrackableError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id1 = open ? "simple-popper" : undefined;

  const onChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const createTask = async () => {
    if ((selectedTrackable || defaultLinked) && templateId) {
      try {
        setLoading(true);
        const phases = await fget({
          url: `phase/?template=${templateId}`,
        });
        let phaseOrder = [];
        let startDateDelay = 0;
        const tasks = phases?.data?.results?.map((eachPhase) => {
          const startDate = new Date(
            new Date(
              new Date(selectedStartDate).setDate(
                new Date(selectedStartDate).getDate() + startDateDelay
              )
            ).setHours(0, 0, 0, 0)
          );
          const data = {
            start_time: startDate,
            end_time: new Date(
              new Date(startDate).setDate(
                new Date(startDate).getDate() + eachPhase.number_of_days
              )
            ),
            name: eachPhase.name,
            description: eachPhase.description,
            phase: eachPhase.id,
            linked: defaultLinked || selectedTrackable.id || "",
            department: eachPhase.dept,
            color: "decolor",
          };
          startDateDelay += eachPhase.number_of_days;
          return data;
        });

        let newTask = {
          url: "trackables/task/",
          data: tasks,
        };

        const newTaskRes = await fpost(newTask);
        //make patch call to map task with phase order.
        const finalOrder = [];
        if (newTaskRes.data?.results) {
          newTaskRes.data.results.map((newTask) => {
            const index = phaseOrder.findIndex(newTask.phase);
            finalOrder[index] = newTask.id;
            return null;
          });
        }

        if (newTaskRes.status === 200 || 201) {
          setSnackValue({
            isOpen: true,
            message: `SuccessFully Created task from template`,
            isError: false,
          });
          updateRefreshCounter();
          handleCreateTaskFromTemplateModalState();
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
        handleCreateTaskFromTemplateModalState();
        setLoading(false);
      }
    } else {
      if (!templateId) {
        setTemplateError(true);
      }
      if (!selectedTrackable.id) {
        setTrackableError(true);
      }
    }
  };

  useEffect(() => {
    if (selectedTrackable.id) {
      setTrackableError(false);
    }
    if (templateId) {
      setTemplateError(false);
    }
  }, [selectedTrackable.id, templateId]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form enctype="multipart/form-data">
            <div className={classes.cardTool}>
              <span className={classes.title}>Create Task From Template</span>
              <Button onClick={() => handleCreateTaskFromTemplateModalState()}>
                <X className={classes.closeIcon} />
              </Button>
            </div>
            <div className={classes.cardContentDiv1}>
              {trackableType === "task" ? (
                <div className={classes.textFieldContainer}>
                  <span>Select Trackable</span>
                  <Asterisk size={12} color="#FF0000" weight="fill" />
                  <div
                    className={classes.cardContentDiv2}
                    style={{ marginTop: "5px" }}
                  >
                    <div
                      aria-describedby={id1}
                      onClick={handleClick}
                      className={classes.cardContentInput1}
                    >
                      <div
                        style={{
                          margin: "5px",
                        }}
                      >
                        {selectedTrackable?.name}
                      </div>
                    </div>
                    <Popper
                      id={id1}
                      open={open}
                      anchorEl={anchorEl}
                      style={{ zIndex: 1400 }}
                      className={classes.popperDiv}
                    >
                      <SelectTrackablesModal
                        disablePortal={trackableType ? true : false}
                        assetList={assetList}
                        sequenceList={sequenceList}
                        setSelectedTrackable={setSelectedTrackable}
                        setAnchorEl={setAnchorEl}
                        findAsset={findAsset}
                        setFindAsset={setFindAsset}
                        findSequence={findSequence}
                        setFindSequence={setFindSequence}
                        setSelectedSequence={setSelectedSequence}
                        searchQuery={searchQuery}
                        selectedSequence={selectedSequence}
                        onChangeSearchQuery={onChangeSearchQuery}
                      />
                    </Popper>
                  </div>
                  {trackableError ? (
                    <HelperText text={"This field is required"} />
                  ) : null}
                </div>
              ) : (
                ""
              )}
              <div className={classes.textFieldContainer}>
                <span>Select Template</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv2}
                  style={{ marginTop: "5px" }}
                >
                  <Select
                    required={true}
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-outlined-label"
                    id="department"
                    disableUnderline
                    className={classes.cardContentInput1}
                    label="Select Task Template"
                    onChange={(e) => setTemplateId(e.target.value.id)}
                    name="department"
                  >
                    {taskTemplateList &&
                      taskTemplateList.map((eachTemplate) => (
                        <MenuItem value={eachTemplate}>
                          {eachTemplate.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                {templateError ? (
                  <HelperText text={"This field is required"} />
                ) : null}
              </div>
              <div className={classes.textFieldContainer}>
                <span>Select Start Date</span>
                <div className={classes.datePickerDiv}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      disableToolbar
                      invalidDateMessage={"inValid"}
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      value={selectedStartDate}
                      onChange={(date) => handleStartDateChange(date)}
                      id="Startdate-picker"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        style: {
                          padding: "0px",
                        },
                      }}
                      className={classes.datePickerComponent}
                      size="small"
                      fullWidth={true}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <br />

            <CardActions className={classes.cardAction}>
              <Button
                fullWidth
                size="small"
                disableElevation
                onClick={(e) => {
                  e.preventDefault();
                  createTask();
                }}
              >
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  <b>Create Task From Template</b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateTaskFromTemplate;
