import React, { useEffect, useState } from "react";

//Validators
import { useFormik } from "formik";
import * as yup from "yup";

import * as Icon from "react-feather";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";

import useStyles from "./ShotVersionEditVersion.styles";
import { Asterisk } from "phosphor-react";
// import { useInput } from "./../../ProjectPage/ProjectPageModals/useInputCustomHook";
import { fget, fpatch, uploadFile } from "./../../../API/callsAPI";
import { useHistory } from "react-router-dom";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import HelperText from "../../Utilities/HelperText/HelperText";

const ShotVersionNewVersionModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [file, setFileUpload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [preLoadedFile, setPreLoadedFile] = useState(null);
  const [selectedTask, setSelectedTask] = useState(
    props.data.task?.id ? props.data.task?.id : null
  );

  useEffect(() => {
    if (props?.data?.file) {
      const fileNameArray = props.data.file.split("/");
      setFileUpload(fileNameArray[fileNameArray.length - 1]);
      setPreLoadedFile(true);
    }
    // fetching the tasks only if the taskList is empty
    // if its fetched in new version modal no need to make the api call again
    !props.taskList.length && props.fetchTasks();
  }, [props?.data?.file]);

  const displaySelectedFile = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    setFileUpload(file);
    setPreLoadedFile(false);
  };
  const handleClearUpload = (event) => {
    event.stopPropagation();
    setFileUpload("");
  };

  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  //Validators
  const EditShotVersionValidationScheme = yup.object({
    shotVersionName: yup
      .string("String required")
      .label("shotName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
  });

  const formik = useFormik({
    //add initail values for editing a version
    initialValues: {
      shotVersionName: props.data.name,
      status: props.data.status ? props.data.status.id : "",
    },
    validationSchema: EditShotVersionValidationScheme,
    onSubmit: async (values) => {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("name", values.shotVersionName);
      formdata.append("status", values.status);
      const isFileUpdated = typeof file === "string" ? false : true;
      if (isFileUpdated) {
        if (file) {
          formdata.append("file_name", file.name);
        }
      } else {
        //for deletion of file
        // formdata.append("file_name", file);
      }
      selectedTask !== null && formdata.append("task", selectedTask);

      let editedVersion = {
        url: `trackables/shot-version/${props.shotVersionId}/`,
        data: formdata,
      };
      try {
        const res = await fpatch(editedVersion);

        if (res.status === 200 || 201) {
          if (isFileUpdated && file) {
            getSignedUrl(res.data.id);
          } else {
            setLoading(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully edited ${res.data.name}`,
              isError: false,
            });
            props.updateRefreshCounter();
            props.handleEditShotVersionModalOpenState();
          }
        }
      } catch (error) {
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  const getSignedUrl = async (newId) => {
    /* let shotVersionClass = props.trackableClassList.find(
      (el) => el.model === "shotversion"
    ); */
    try {
      const res = await fget({
        url: `trackables/shotversion/${newId}/upload`,
      });
      if (res.status === 200) {
        uploadFile({ url: res.data?.signed_url, data: file }).then(
          () => {
            fget({
              url: `trackables/shotversion/${newId}/thumbnail`,
            });
            setLoading(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Edited ${props?.data?.name}`,
              isError: false,
            });
            props.updateRefreshCounter();
            props.handleEditShotVersionModalOpenState();
          },
          () => {}
        );
      }
    } catch (error) {
      setLoading(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      setFileUpload(e.dataTransfer.files[0]);
      // setFileTouched(true);
      setPreLoadedFile(false);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Version"
              type="Edit"
              closeModal={(e) => props.handleEditShotVersionModalOpenState(e)}
              openDeleteModal={(e) =>
                props.openDeleteShotVersionModal(e, props?.data)
              }
            />
            <div className={classes.cardContentDiv1}>
              <div>
                <span>Version Name</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  className={classes.cardContentDiv2}
                  style={{ marginTop: "7px" }}
                >
                  <TextField
                    fullWidth
                    type="text"
                    className={classes.cardContentInput1}
                    InputProps={{ disableUnderline: true }}
                    name="shotVersionName"
                    value={formik.values.shotVersionName}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.shotVersionName)}
                  />
                </div>
                {formik.errors.shotVersionName &&
                formik.touched.shotVersionName ? (
                  <HelperText text={formik.errors.shotVersionName} />
                ) : null}
              </div>
              <div
                style={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span>Status</span>
                  <div
                    className={classes.cardContentDiv2}
                    style={{ marginTop: "5px", width: "150px" }}
                  >
                    <Select
                      disableUnderline={true}
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-outlined-label"
                      className={classes.cardContentInput1}
                      id="selectStatusS"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      label="Select Status"
                      MenuProps={{
                        classes: {
                          paper: classes.dropDown,
                        },
                      }}
                    >
                      <MenuItem
                        value=""
                        className={classes.addNewStatusButton}
                        onClick={() => {
                          history.push(`/${props.projectId}/settings`);
                        }}
                      >
                        <Icon.Plus />
                        Add new Status
                      </MenuItem>
                      {props.status &&
                        props.status.map((eachStatus) => (
                          <MenuItem value={eachStatus.id}>
                            <span
                              style={{
                                height: "15px",
                                width: "15px",
                                backgroundColor: `rgb(${eachStatus?.color})`,
                                borderRadius: "50%",
                                display: "inline-block",
                                margin: "0px 5px",
                              }}
                            ></span>
                            {eachStatus.name} - {eachStatus.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
                <div style={{ maxWidth: "50%" }}>
                  <span>Status Code</span>
                  <div
                    style={{ marginTop: "5px", cursor: "not-allowed" }}
                    className={classes.cardContentDiv2}
                  >
                    <TextField
                      className={classes.cardContentInput1}
                      InputProps={{
                        disableUnderline: true,
                        style: { opacity: "0.4" },
                      }}
                      disabled
                      fullWidth
                      value={
                        props.status.find(
                          (eachStatus) => eachStatus.id === formik.values.status
                        )?.code
                      }
                    />
                  </div>
                </div>
              </div>

              <div>
                <span style={{ display: "block", margin: "25px 0 5px 0" }}>
                  Link to Task
                </span>
                <Select
                  style={{ width: "100%" }}
                  id="linkToTask"
                  label="Link to Task"
                  name="task"
                  className={classes.cardContentDiv2}
                  disableUnderline
                  value={selectedTask}
                  onChange={(e) => setSelectedTask(e.target.value)}
                  MenuProps={{
                    classes: {
                      paper: classes.dropDown,
                    },
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {props.taskList &&
                    props.taskList.map((task) => (
                      <MenuItem value={task.id}>{task.name}</MenuItem>
                    ))}
                </Select>
              </div>

              <div
                style={{
                  marginTop: "25px",
                  display: "flex",
                  flexDirection: "column",

                  justifyContent: "space-between",

                  width: "100%",
                }}
              >
                <span>Attachments </span>
                <UploadFile
                  thumbnail={file}
                  handleClearUpload={handleClearUpload}
                  handleThumbnailUpload={displaySelectedFile}
                  preLoadedFile={preLoadedFile}
                />
              </div>
            </div>
            <br />
            <CardActions className={classes.cardAction}>
              <Button
                className={classes.done}
                type="submit"
                value="Submit"
                size="small"
                disableElevation
              >
                {loading ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  "Done Editing"
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ShotVersionNewVersionModal;
