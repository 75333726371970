import React from "react";
import { Plus } from "phosphor-react";
//@MUI
import { Button } from "@material-ui/core";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";

import useStyles from "./SequencePageTools.styles.js";
import {
  SortButton,
  ToggleButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component.jsx";

export default function SequencePageTools(props) {
  const classes = useStyles();

  const [sliderState, setSliderState] = React.useState(0);

  React.useEffect(() => {
    if (props.sliderState > 0) {
      setSliderState(props.sliderState);
      sessionStorage.setItem("sliderState", `${sliderState}`);
    } else setSliderState(sessionStorage.getItem("sliderState"));
  }, [props.sliderState, sliderState]);

  if (sessionStorage.getItem("sliderState") === null)
    sessionStorage.setItem("sliderState", 0);

  return (
    <div className={classes.root}>
      <BreadCrumb />
      <div className={classes.inlineDiv}>
        <div>
          <ToggleButton handleToggler={props.handleToggler} />
        </div>
        {/* <div>
          <Button
            className={classes.sequencePageButton}
          // onClick={props.openFilterModal}
          >
            <Icon.Filter />
            <p>Filter</p>
          </Button>
        </div> */}
        <div>
          <SortButton
            onSubmit={props.onSubmit}
            defaultvalue={props.defaultvalue}
          />
        </div>
        <div>
          {/* <Grid className={classes.sequencePageToolsGrid}> */}
          {/* <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <div
                  style={{ justifyContent: "space-between" }}
                  className={classes.dropdownDiv}
                >
                  <text className={classes.selectText}>
                    {props.filterValues.status
                      ? nameStatus
                      : "Select By Status"}
                  </text>
                  <Icon.ChevronDown className={classes.iconStyle} />
                </div>
                <Select
                  style={{ height: "0%", opacity: "0" }}
                  className={classes.formLabelText}
                  labelId="demo-simple-select-outlined-label"
                  id="selectStatus"
                  label="Select Status"
                  value={props.filterValues.status}
                  onChange={(event) => {
                    props.handleStatusChange(event.target.value[0]);
                    setnameStatus(event.target.value[1]);
                  }}
                  defaultValue="none"
                >
                  <MenuItem value={["", "None"]}>
                    <em>None</em>
                  </MenuItem>
                  {props.status &&
                    props.status.map((el) => (
                      <MenuItem value={[el.id, el.name]} key={el.id}>
                        {el.name} - {el.code}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}
          {/* <Grid item> */}
          <Button
            size="small"
            className={classes.sequencePageNewSequenceButton}
            disableElevation
            onClick={props.openNewSequenceModal}
          >
            <Plus className={classes.plusIcon} />
            New Sequence
          </Button>
          {/* </Grid>
          </Grid> */}
        </div>
      </div>
    </div>
  );
}
