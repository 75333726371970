import React, { Suspense, useState, useRef, useEffect } from "react";
import {
  OrbitControls,
  Html,
  Environment,
  Stage,
  useGLTF,
  useProgress,
  useAnimations,
} from "@react-three/drei";
import useStyles from "./ModelViewer.style";
import { useThree, Canvas } from "@react-three/fiber";
import ChangeHdr from "./ChangeHdr/ChangeHdr.jsx";
import UploadHdr from "./UploadHdr/UploadHdr.jsx";
import { useFormik } from "formik";
import * as yup from "yup";
import { fpost, fget, fdelete, fpatch } from "../../../API/callsAPI";
import { CircularProgress, Modal, Button, Slide } from "@material-ui/core";
import DeleteHdrModal from "./DeleteHdrModal/DeleteHdrModal.jsx";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { X } from "react-feather";

export default function ModelViewer(props) {
  const classes = useStyles();
  const group = useRef();
  const [background, setBackground] = useState("");
  const [uploadFile, setUploadFile] = useState();
  const [backgroundOptions, setBackgroundOptions] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isDeleteHdrOpen, setDeleteHdrOpen] = useState(false);
  const [deleteHdr, setDeleteHdr] = useState();
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  //state to hold image from glrenderer
  const [gl, setgl] = useState();

  let apiUrl = "";
  if (props.versionType === "Asset") {
    apiUrl = `trackables/asset-version/${props.versionId}/`;
  } else {
    apiUrl = `trackables/shot-version/${props.versionId}/`;
  }

  useEffect(() => {
    getHdrMaps();
  }, [props.versionId]);

  const getHdrLinkedWithVersion = async (hdrMaps) => {
    try {
      const res = await fget({
        url: apiUrl,
      });
      if (res.status == 200 || 201) {
        if (res.data.linked_hdr_maps) {
          const hdr = hdrMaps.filter(
            (hdr) => hdr.id === res.data.linked_hdr_maps
          );
          setBackground(hdr[0].file);
        } else {
          setBackground("/defaultbackground.hdr");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  //get all the hdr
  const getHdrMaps = async () => {
    try {
      const res = await fget({
        url: `trackables/hdr-maps?project=${props.projectId}`,
      });
      if (res.status === 200 || 201) {
        setBackgroundOptions(res.data.results);
        getHdrLinkedWithVersion(res.data.results);
      }
    } catch (err) {
      console.log(err);
      setSnackValue({
        isOpen: true,
        message: `There is some issue in getting hdr maps`,
        isError: true,
      });
    }
  };

  //upload hr
  const getUploadFile = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    e.target.value = null;
    setUploadFile(file);
    submit(file);
  };
  const submit = async (val) => {
    setUploading(true);
    let formdata = new FormData();
    formdata.append("project", props.projectId);
    formdata.append("file", val, val.name);
    let upload = {
      url: `trackables/hdr-maps/`,
      data: formdata,
    };
    try {
      const res = await fpost(upload);
      if (res.status === 200 || 201) {
        setUploading(false);
        setBackgroundOptions([...backgroundOptions, res.data]);
        setSnackValue({
          isOpen: true,
          message: `Hdr Uploaded Successfully!`,
          isError: false,
        });
      }
    } catch (err) {
      console.log(err);
      setSnackValue({
        isOpen: true,
        message: `Hdr Map not uploaded. please try again!`,
        isError: true,
      });
    }
  };

  //get value of hdr which user wants to delete
  const getDeleteHdrValue = (val) => {
    setDeleteHdrOpen(true);
    setDeleteHdr(val);
  };

  //remove selected item
  const handleRemove = (id) => {
    const items = backgroundOptions;
    if (items.length > 0) {
      setBackgroundOptions(items.filter((item) => item.id != id));
    }
  };

  //delete hdr after getting confrimation from user
  const deleteHdrMaps = async () => {
    try {
      const res = await fdelete({
        url: `trackables/hdr-maps/${deleteHdr.id}?project=${props.projectId}/`,
      });
      if (res.status === 200 || 201) {
        setDeleteHdrOpen(false);
        setSnackValue({
          isOpen: true,
          message: `Hdr Deleted Successfully!`,
          isError: false,
        });
        handleRemove(deleteHdr.id);
        // setTimeout(() => window.location.reload(), 1500);
      }
    } catch (err) {
      console.log(err);
      setSnackValue({
        isOpen: true,
        message: `Deletion of this hdr fails. please try again !`,
        isError: false,
      });
    }
  };

  const LinkHdrWithVersion = async (id) => {
    try {
      const resp = await fpatch({ url: apiUrl, data: { linked_hdr_maps: id } });
      if (resp.status == 200 || 201) {
        setSnackValue({
          isOpen: true,
          message: `Hdr Changed!`,
          isError: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  function getSelectedBackground(val) {
    setBackground(val.file);
    LinkHdrWithVersion(val.id);
  }

  //show file downloading % - manage by suspense component
  function Loader() {
    const { progress } = useProgress();
    return (
      <Html>
        <div style={{ width: "auto" }}>
          <span
            style={{ color: "#1B1D28", fontSize: "20px", fontWeight: "900" }}
          >{`${parseInt(progress)}%`}</span>
        </div>
      </Html>
    );
  }

  //show animation of model
  function Model() {
    const { scene, animations } = useGLTF(props.file);
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
      if (Object.keys(actions).length !== 0) {
        for (let animation in actions) {
          actions[animation].play();
        }
      }
    });
    if (Object.keys(actions).length !== 0) {
      return (
        <group ref={group} dispose={null}>
          <group>
            <primitive object={scene} />
          </group>
        </group>
      );
    } else {
      return <primitive object={scene} />;
    }
  }

  //add hdr environment to model
  const Scene = () => {
    return (
      <>
        <Stage>
          <Environment background={true} files={background} path={""} />
          <mesh>
            <Model />
          </mesh>
        </Stage>
      </>
    );
  };

  const saveAsImage = () => {
    var imgData;
    try {
      //create canvas
      const canvas = document.createElement("canvas");
      const myContext = canvas.getContext("2d");

      //create <img />
      const img = new Image();
      var strMime = "image/jpeg";
      //get image src of modal from gl renderer
      imgData = gl.domElement.toDataURL(strMime);
      //add src to img
      img.src = imgData;

      img.onload = () => {
        canvas.width = 720;
        canvas.height = (canvas.width / img.width) * img.height;
        var scale = Math.min(
          canvas.width / img.width,
          canvas.height / img.height
        );
        var x = canvas.width / 2 - (img.width / 2) * scale;
        var y = canvas.height / 2 - (img.height / 2) * scale;
        //draw image created of modal on canvas
        myContext.drawImage(img, x, y, img.width * scale, img.height * scale);
        //sending image of canvas
        props.setScreenshot(canvas.toDataURL("image/jpeg"));
      };
    } catch (e) {
      console.log(e);
      return;
    }
    props.canvasModalToggle();
  };

  useEffect(() => {
    if (props.glbScreenshot === true) {
      saveAsImage();
      props.setGlbScreenshot(false);
    }
  }, [props.glbScreenshot]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <div style={{ height: "100%" }}>
        <Canvas
          gl={{ preserveDrawingBuffer: true }}
          className={classes.canvas}
          onCreated={({ gl, events }) => {
            setgl(gl);
          }}
        >
          <Suspense fallback={<Loader />}>
            <Scene />
          </Suspense>
          {/* for movemoment of modal */}
          <OrbitControls enableZoom={true} enablePan={false} />
        </Canvas>

        {props.allowHdrChange ? (
          ""
        ) : (
          <Slide direction="up" in="true">
            <div className={classes.bgContainer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginRight: "-9px",
                }}
              >
                <div>
                  {uploading ? (
                    <CircularProgress color="secondary" size={23} />
                  ) : (
                    <UploadHdr getUploadFile={getUploadFile} />
                  )}
                </div>
                <div
                  onClick={() => props.setAllowHdrChange(true)}
                  style={{ cursor: "pointer" }}
                >
                  <X />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  // overflowX: "hidden",
                }}
              >
                <ChangeHdr
                  HdrData={backgroundOptions}
                  selectedFile={getSelectedBackground}
                  file={background}
                  deleteHdr={getDeleteHdrValue}
                />
              </div>
            </div>
          </Slide>
        )}
        <div>
          <Modal
            className={classes.ModelPageModals}
            open={isDeleteHdrOpen}
            onClose={() => setDeleteHdrOpen(false)}
          >
            <DeleteHdrModal
              deleteHdrMaps={deleteHdrMaps}
              closeModal={() => setDeleteHdrOpen(false)}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}
