import { Button } from "@material-ui/core";
import React from "react";
import Svg404 from "./Svg404.component";
import * as Icons from "react-feather";
export default function Response404() {
  return (
    <div
      style={{
        backgroundColor: "#171B21",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1
          style={{
            fontSize: "80px",
            margin: "0px",
            fontFamily: "Nunito Sans",
            color: "#4ECCA3",
          }}
        >
          ERROR 404
        </h1>
        <br />
        <p
          style={{
            fontFamily: "Nunito Sans",
            color: "#ffffff",
            fontSize: "16px",
            margin: "5px",
          }}
        >
          Looks like we couldn’t find what you were <br /> looking for. Please
          try again.
        </p>
        <Button
          style={{ textTransform: "none", margin: "5px" }}
          startIcon={<Icons.ArrowLeft />}
          variant="outlined"
          color="secondary"
          onClick={() => window.location.replace("/")}
        >
          Go Back Home
        </Button>
      </div>
      <div>
        <Svg404 />
      </div>
    </div>
  );
}
