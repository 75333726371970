import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "6px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 5px rgb(54, 54, 54)",
      borderRadius: "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px",
    },

    "*::-webkit-scrollbar-thumb:hover": {
      backgroundColor: theme.palette.secondary.main,
      width: "10px",
    },
  },
  projectPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // projectPageFilterModals: {
  //   height: "35%",
  // },
  projectPageCardsDiv1: {
    //margin: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContainer: {
    height: "72vh",
    overflowY: "auto",
    [theme.breakpoints.down("lg")]: {
      height: "70vh",
    },
    paddingRight: "10px",
    backgroundColor: theme.palette.primary.main,
  },
  table: {
    backgroundColor: theme.palette.primary.main,
    minWidth: 650,
  },
  headTable: {
    color: "#878787",
    fontFamily: "Nunito sans",
    fontWeight: "normal",
    fontSize: "18px",
    backgroundColor: theme.palette.primary.main,
    padding: "8px 5px 8px 0px",
  },
  divider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#f9f9f9",
  },
  projectPageModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
