import React, { useState } from "react";

//@Components
import AssetVersionsListStatusButtons from "../AssetVersionsListContents/AssetVersionsListStatusButtons.component";
import AssetVersionEditVersionModal from "../AssetsVersionsPageModal/AssetVersionEditVersionModal";

//@MUI
import { TableRow, TableCell, Modal, Grid } from "@material-ui/core";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import useStyles from "./Assetversionlist.style";
import StatusDropDown from "../../Utilities/StatusDropDown/StatusDropDown.component";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import { fdelete } from "../../../API/callsAPI";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";

export default function Assetversionslist(props) {
  const [userCreatedAssetVersion, setUserCreatedAssetVersion] = useState(
    props.data.created_by
      ? props.data.created_by.first_name + " " + props.data.created_by.last_name
      : " "
  );
  const classes = useStyles();

  const [isEditAssetVerisonModalOpen, setEditAssetVersionModalOpen] =
    useState(false);

  const [isDeleteAssetVerisonModalOpen, setDeleteAssetVersionModalOpen] =
    useState(false);
  const [deleteData, setDeleteData] = useState({});

  function handleEditAssetVersionModalState(e) {
    e?.stopPropagation();
    setEditAssetVersionModalOpen(!isEditAssetVerisonModalOpen);
  }

  function handleDeleteAssetVersionModalState(e, data) {
    e?.stopPropagation();
    setDeleteData(data);
    setDeleteAssetVersionModalOpen(!isDeleteAssetVerisonModalOpen);
  }

  const handleAssetVersionDelete = async () => {
    try {
      await fdelete({
        url: `trackables/asset-version/${deleteData?.id}/`,
      });
      props.updateRefreshCounter();
      setDeleteAssetVersionModalOpen(false);
      setEditAssetVersionModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow
        hover
        onClick={(e) => props.openAssetDetailModal(props.data)}
        style={{
          cursor: "pointer",
          display: "table",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <TableCell
          align="left"
          className={classes.assetVersionListPageTableCell}
        >
          <div style={{ minWidth: "40%" }}>
            {props.data.thumbnail ? (
              <ImageViewer
                url={props.data.thumbnail}
                sliderState={props.isAssetTogglerOn ? 25 : 0}
              />
            ) : (
              <NoThumbnail sliderState={props.isAssetTogglerOn ? 25 : 0} />
            )}
          </div>
        </TableCell>
        <TableCell
          align="left"
          className={classes.assetVersionListPageTableCell}
        >
          <div>
            {props.data.name ? (
              <span>{props.data.name}</span>
            ) : (
              <span>Version Name</span>
            )}
          </div>
        </TableCell>
        <TableCell
          align="left"
          className={classes.assetVersionListPageTableCell}
        >
          <DateAndTimeViewer dateAndTime={props.data.updated_at} />
        </TableCell>
        <TableCell
          align="left"
          className={classes.assetVersionListPageTableCell}
        >
          <div>
            <StatusDropDown
              url={`trackables/asset-version/${props.data.id}/`}
              statusList={props.status}
              currStatus={props.data.status}
              updateRefreshCounter={props.updateRefreshCounter}
            />
          </div>
        </TableCell>
        <TableCell
          align="center"
          className={classes.assetVersionListPageTableCell}
        >
          <span>{userCreatedAssetVersion}</span>
        </TableCell>
        <TableCell
          align="right"
          className={classes.assetVersionListPageTableCell}
        >
          <AssetVersionsListStatusButtons
            openEditAssetVerisonModal={handleEditAssetVersionModalState}
            openDeleteAssetVersionModal={handleDeleteAssetVersionModalState}
          />
        </TableCell>
        <div>
          <Modal
            className={classes.assetsVersionModals}
            open={isEditAssetVerisonModalOpen}
            onClose={(e) => {
              setEditAssetVersionModalOpen(false);
              e.stopPropagation();
            }}
          >
            <AssetVersionEditVersionModal
              taskList={props.taskList}
              fetchTasks={props.fetchTasks}
              openCreateNewStatusModal={props.openCreateNewStatusModal}
              status={props.status}
              data={props.data}
              projectId={props.projectId}
              handleEditAssetVersionModalState={
                handleEditAssetVersionModalState
              }
              handleDeleteAssetVersionModalState={
                handleDeleteAssetVersionModalState
              }
              updateRefreshCounter={props.updateRefreshCounter}
              dragScreen={props.dragScreen}
              handleDragScreen={props.handleDragScreen}
              setDragFileEnterModal={props.setDragFileEnterModal}
            />
          </Modal>
        </div>
        <div>
          <Modal
            className={classes.assetsVersionModals}
            open={isDeleteAssetVerisonModalOpen}
            onClose={(e) => {
              setDeleteAssetVersionModalOpen(false);
              e.stopPropagation();
            }}
          >
            <DeleteModal
              type="assets version"
              name={deleteData?.name}
              handleCloseDeleteModal={() => {
                setDeleteAssetVersionModalOpen(false);
              }}
              handleDelete={handleAssetVersionDelete}
            />
          </Modal>
        </div>
      </TableRow>
    </>
  );
}
