import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  useMediaQuery,
  NativeSelect,
  Popover,
  Switch,
} from "@material-ui/core";
import {
  CaretLeft,
  CaretRight,
  Chats,
  CheckSquareOffset,
  Circle,
  DotsThreeVertical,
  DownloadSimple,
} from "phosphor-react";
import CommentsOverview from "../Comments/CommentsOverview";
import VersionSelector from "../VersionSelector/VersionSelector";
import useStyles from "./VersionviewerOverview.styles.js";
import { checkGlb, checkImage } from "../../../utils";
import ModelViewer from "../../Model-Viewer/ModelViewer";
import BreadCrumb from "../../BreadCrumb/BreadCrumb.jsx";
import { X } from "react-feather";
import Player from "../../../Player/player.component";
import HDRbgIcon from "./HDRbgIcon.png";
import AnnotateIcon from "../../Svg/AnnotateIcon";
import CompareIcon from "../../Svg/CompareIcon";
import ImageViewer from "../../Image-Viewer/ImageViewer.component";
import LinkedTaskAndComments from "../LinkedTaskAndComments/LinkedTaskAndComments.component";
import NoResultFound from "../../NoResultFound/NoResultFound";
import { fget } from "../../../../API/callsAPI";

// handle annotation

export default function VersionViewerOverview({
  versionList,
  selectedVersion,
  setSelectedVersion,
  // file,
  projectId,
  // commentList,
  fetchFile,
  fetchComments,
  parentTrackable,
  closeModal,
  handleStatusChange,
  status,
  setTaskNameFromComment,
  canvasModal,
  setCanvasModal,
  setImageRef,
  screenshot,
  setScreenshot,
  allUsersName,
  handleSubmit,
  version_type,
  assetId,
  setPlayerProgress,
  setIsOpenFullscreenViewer,
  setFileForFullscreenViewer,
  commentsRefreshCounter,
  taskStatusList,
}) {
  const matchHeight = useMediaQuery("(min-height:755px)");
  const matchWidth = useMediaQuery("(min-width:1240px)");

  const [commentList, setCommentList] = useState({ data: [], loading: true });
  const [file, setFile] = useState(null);
  const [seekTime, setSeekTime] = useState(0);
  const [loggedInUser, setLoggedInUser] = useState();
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);
  const [glbScreenshot, setGlbScreenshot] = useState(false);
  const [allowHdrChange, setAllowHdrChange] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTaskStatusVisible, setIsTaskStatusVisible] = useState(false);
  const [linkedTask, setLinkedTask] = useState({});
  const [taskComments, setTaskComments] = useState([]);
  const [refreshLinkedTask, setRefreshLinkedTask] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const frameRate = 24;
  const player = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let history = useHistory();
  let classes = useStyles();

  useEffect(() => {
    fetchFile()
      .then((res) => setFile(res))
      .catch((err) => {
        setFile(null);
        console.log(err);
      });

    //loggedIn user
    const accessToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    accessToken && setLoggedInUser(JSON.parse(atob(accessToken.split(".")[1])));

    versionList.results[versionList.results.length - 1] === selectedVersion
      ? setRightArrow(true)
      : setRightArrow(false);
    versionList.results[0] === selectedVersion
      ? setLeftArrow(true)
      : setLeftArrow(false);
  }, [selectedVersion, refresh]);

  const updateRefresh = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    fetchComments()
      .then((res) => setCommentList({ data: res, loading: false }))
      .catch((err) => {
        setCommentList({ data: [], loading: false });
        console.log(err);
      });
  }, [selectedVersion, commentsRefreshCounter]);

  const changeSeekTime = (event) => {
    var time = event.target.innerText;
    time = time.trim();
    var timeInNos = 0; //setting time to 0
    if (time.length === 4) {
      timeInNos +=
        parseInt(time.substr(0, 1)) * 60 + parseInt(time.substr(2, 2));
    } else {
      timeInNos +=
        parseInt(time.substr(0, 2)) * 60 + parseInt(time.substr(3, 2));
    }
    setSeekTime(timeInNos);
  };

  let changeColor; //scroll to comment function takes the id of the comment gets the element from the document and scrolls to that paricular comment
  //it changes the colour for highlighting and changes it back to original colour after 5 seconds
  //this function is called in the player card of player component
  const scrollToComment = (id) => {
    if (changeColor !== null) clearTimeout(changeColor);
    const scrollItem = document.getElementById(id);
    scrollItem &&
      scrollItem.scrollIntoView({ behavior: "smooth", block: "start" });
    scrollItem.style.backgroundColor = "#252A38";
    changeColor = setTimeout(() => {
      scrollItem.style.backgroundColor = "#1B1D28";
    }, 5000);
  };

  useEffect(() => {
    if (selectedVersion?.task !== null) {
      getTaskDetails();
      getLinkedTasksComments();
    }
  }, [selectedVersion, refreshLinkedTask]);

  const updateLinkedTaskAndComments = () => {
    setRefreshLinkedTask(refreshLinkedTask + 1);
  };

  const getTaskDetails = async () => {
    try {
      const res = await fget({
        url: `trackables/task/${selectedVersion.task.id}/`,
      });
      if (res.status === 200 || 201) {
        setLinkedTask(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getLinkedTasksComments = async () => {
    try {
      const res = await fget({
        url: `trackables/task-comment?task=${selectedVersion.task.id}`,
      });
      if (res.status === 200 || 201) {
        setTaskComments(res.data.results);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const seekFiveSecondsForward = () => {
    player.current.currentTime = Math.min(
      player.current.duration,
      player.current.currentTime + 5
    );
  };

  const seekFiveSecondsBack = () => {
    player.current.currentTime = Math.min(
      player.current.duration,
      player.current.currentTime - 5
    );
  };

  const seekForward = () => {
    setPlaying(false);
    player.current.pause();
    player.current.currentTime = Math.min(
      player.current.duration,
      player.current.currentTime + 1 / frameRate
    );
  };

  const seekBack = () => {
    setPlaying(false);
    player.current.pause();
    player.current.currentTime = Math.min(
      player.current.duration,
      player.current.currentTime - 1 / frameRate
    );
  };

  const previousVersion = () => {
    if (selectedVersion === versionList.results[0]) {
      return;
    }
    setIsTaskStatusVisible(false);
    versionList.results.map((version, index) => {
      if (version === selectedVersion) {
        setCommentList({ data: [], loading: true });
        setSelectedVersion(versionList.results[index - 1]);
      }
    });
  };

  const nextVersion = () => {
    if (
      selectedVersion === versionList.results[versionList.results.length - 1]
    ) {
      return;
    }
    setIsTaskStatusVisible(false);
    versionList.results.map((version, index) => {
      if (version === selectedVersion) {
        setCommentList({ data: [], loading: true });
        setSelectedVersion(versionList.results[index + 1]);
      }
    });
  };

  const handleSeekKeyboardEvent = (event) => {
    switch (true) {
      case event.key === " ":
        handlePlayPause();
        break;
      case !event.shiftKey && !event.ctrlKey && event.key === "ArrowRight":
        seekFiveSecondsForward();
        break;
      case !event.shiftKey && !event.ctrlKey && event.key === "ArrowLeft":
        seekFiveSecondsBack();
        break;
      case event.keyCode === 188 || event.key === ",":
        seekBack();
        break;
      case event.keyCode === 190 || event.key === ".":
        seekForward();
        break;
      case event.ctrlKey &&
        (event.keyCode === 39 || event.key === "ArrowRight"):
        nextVersion();
        break;
      case event.ctrlKey && (event.keyCode === 37 || event.key === "ArrowLeft"):
        previousVersion();
        break;
      default:
        break;
    }
  };
  const versionViewerModalRef = useRef(null);

  useEffect(() => {
    versionViewerModalRef.current.focus();
  }, []);
  return (
    <div
      className={classes.viewerComponent}
      ref={versionViewerModalRef}
      tabIndex={0}
      onKeyDown={handleSeekKeyboardEvent}
    >
      <div className={classes.leftButtonContainer}>
        <Button
          className={classes.leftOrRightButton}
          style={{ color: leftArrow ? "gray" : "#6C65D9" }}
          onClick={() => {
            setIsTaskStatusVisible(false);
            versionList.results.map((version, index) => {
              if (version === selectedVersion) {
                setCommentList({ data: [], loading: true });
                setSelectedVersion(versionList.results[index - 1]);
              }
            });
          }}
          disabled={leftArrow}
        >
          <CaretLeft size={100} weight="bold" fill="#6C65D9" />
        </Button>
      </div>
      <div className={classes.rightButtonContainer}>
        <Button
          className={classes.leftOrRightButton}
          style={{ color: rightArrow ? "gray" : "#6C65D9" }}
          onClick={(e) => {
            setIsTaskStatusVisible(false);
            versionList.results.map((version, index) => {
              if (version === selectedVersion) {
                setCommentList({ data: [], loading: true });
                setSelectedVersion(versionList.results[index + 1]);
              }
            });
          }}
          disabled={rightArrow}
        >
          <CaretRight weight="bold" size={100} fill="#6C65D9" />
        </Button>
      </div>

      <div className={classes.toolsContainer}>
        <div className={classes.breadcrumb}>
          <BreadCrumb
            fontSize="14px"
            shotCode={parentTrackable ? parentTrackable.name : ""}
          />
          <CaretRight />
          <Circle />
          <span>{selectedVersion?.name}</span>
        </div>
        <div className={classes.statusAndOtherToggler}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.statusDiv}>
              <Circle
                color={`rgb(${selectedVersion?.status?.color})`}
                weight="fill"
                size={20}
              />
              <NativeSelect
                Select
                disableUnderline={true}
                onChange={handleStatusChange}
                // onClick={(e) => e.stopPropagation()}
              >
                <option value="none" selected disabled hidden>
                  {selectedVersion?.status
                    ? selectedVersion.status.name
                    : "No Status"}
                </option>
                {status &&
                  status.map((val) => (
                    <option value={val.id}>{val.name}</option>
                  ))}
              </NativeSelect>
            </div>
            <div
              className={classes.annotationButton}
              onClick={() => {
                checkGlb(file)
                  ? setGlbScreenshot(true)
                  : setCanvasModal(!canvasModal);
              }}
            >
              <AnnotateIcon className={classes.iconStyle} />
            </div>

            <div
              className={classes.compareButton}
              onClick={() => {
                selectedVersion?.shot
                  ? history.push(
                      `/${projectId}/sequence/${selectedVersion.shot.parent_sequence}/shots/${selectedVersion.shot.id}/shotversions/${selectedVersion.id}/compare`
                    )
                  : history.push(
                      `/${projectId}/assets/${selectedVersion.asset.id}/assetversions/${selectedVersion.id}/assetversions/compare`
                    );
              }}
            >
              <CompareIcon className={classes.iconStyle} />
            </div>
          </div>
          <hr style={{ height: "30px", width: "1px", margin: "10px" }} />
          <div className={classes.commentsOrTaskToggler}>
            <Chats className={classes.iconStyle} />
            <Switch
              checked={isTaskStatusVisible}
              onChange={() => setIsTaskStatusVisible(!isTaskStatusVisible)}
              // disabled={selectedVersion.task ? false : true}
            />
            <CheckSquareOffset className={classes.iconStyle} />
          </div>
        </div>
        <div className={classes.closeButton} onClick={closeModal}>
          <X />
        </div>
      </div>
      <div
        style={{
          height: matchHeight ? "90%" : "485px",
          display: "flex",
          justifyContent: matchWidth ? "space-between" : "center",
          padding: "0 20px",
        }}
      >
        <div
          style={{
            width: matchWidth ? "69%" : "820px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {file ? (
            checkImage(file) ? (
              <ImageViewer
                matchHeight={matchHeight}
                setImageRef={setImageRef}
                setIsOpenFullscreenViewer={setIsOpenFullscreenViewer}
                setFileForFullscreenViewer={setFileForFullscreenViewer}
                file={file}
              />
            ) : checkGlb(file) ? (
              file ? (
                <div className={classes.modelViewerDiv}>
                  <ModelViewer
                    versionType={selectedVersion.asset ? "Asset" : "Shot"}
                    versionId={selectedVersion.id}
                    file={file}
                    setScreenshot={setScreenshot}
                    canvasModalToggle={() => setCanvasModal(!canvasModal)}
                    canvasModal={canvasModal}
                    projectId={projectId}
                    glbScreenshot={glbScreenshot}
                    setGlbScreenshot={setGlbScreenshot}
                    allowHdrChange={allowHdrChange}
                    setAllowHdrChange={setAllowHdrChange}
                  />
                  <div
                    className={`${classes.imageIconColor} ${
                      allowHdrChange
                        ? classes.allowHrdChange
                        : classes.notAllowed
                    }`}
                  >
                    <div className={classes.imageDiv}>
                      <img
                        className={classes.imageStyle}
                        src={HDRbgIcon}
                        onClick={() => setAllowHdrChange(!allowHdrChange)}
                      />
                      <div>
                        <DotsThreeVertical
                          weight="bold"
                          style={{ cursor: "pointer", marginTop: "2px" }}
                          onClick={handleClick}
                        />
                      </div>
                    </div>
                    <div>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        style={{
                          marginTop: "-45px",
                        }}
                      >
                        <div className={classes.downloadOption}>
                          <a href={file} download>
                            <DownloadSimple
                              size={20}
                              className={classes.imageIconColor}
                            />
                          </a>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
              ) : (
                "File is Loading"
              )
            ) : (
              <div className={classes.player}>
                <Player
                  data={file}
                  seekTime={seekTime}
                  setSeekTime={setSeekTime}
                  screenshot={screenshot}
                  comments={commentList.data}
                  setScreenshot={setScreenshot}
                  fetchComments={fetchComments}
                  version_type={version_type}
                  assetId={assetId}
                  version_id={selectedVersion?.id}
                  users={allUsersName}
                  canvasModal={canvasModal}
                  setCanvasModal={setCanvasModal}
                  setPlayerProgress={setPlayerProgress}
                  scrollToComment={scrollToComment}
                  player={player}
                  playing={playing}
                  setPlaying={setPlaying}
                  handlePlayPause={handlePlayPause}
                  seekBack={seekBack}
                  seekForward={seekForward}
                  updateRefresh={updateRefresh}
                  selectedVersion={selectedVersion}
                />
              </div>
            )
          ) : (
            <NoResultFound />
          )}

          {/* video player */}
        </div>
        <div
          style={{
            width: matchWidth ? "40%" : "420px",
            display: matchWidth ? "block" : "none",
            marginLeft: "15px",
          }}
        >
          {!isTaskStatusVisible ? (
            <CommentsOverview
              commentList={commentList}
              setCommentList={setCommentList}
              loggedInUser={loggedInUser}
              setTaskNameFromComment={setTaskNameFromComment}
              fetchComments={fetchComments}
              allUsersName={allUsersName}
              handleSubmit={handleSubmit}
              changeSeekTime={changeSeekTime}
              matchHeight={matchHeight}
            />
          ) : selectedVersion.task ? (
            <>
              <LinkedTaskAndComments
                linkedTask={linkedTask}
                taskComments={taskComments}
                projectId={projectId}
                updateLinkedTaskAndComments={updateLinkedTaskAndComments}
                taskStatusList={taskStatusList}
                loggedInUser={loggedInUser}
                matchHeight={matchHeight}
                matchWidth={matchWidth}
              />
            </>
          ) : (
            <NoResultFound />
          )}
        </div>
      </div>
      <div className={classes.versionSelector}>
        <VersionSelector
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          setCommentList={setCommentList}
          versionList={versionList}
          onVersionChange={{}}
          versionModalRef={versionViewerModalRef}
        />
      </div>
    </div>
  );
}
