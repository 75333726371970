import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1.4%",
    marginBottom: "1%",
  },
  title: {
    color: theme.palette.text.primary,
  },
  tool: {
    fontSize: "14px",
    fontWeight: "400",
  },
  projectPageToolsGrid: {
    display: "flex",
    // margin: "0px 10px",
    width: "100%",
  },
  projectPageButton: {
    textTransform: "none",
    height: "2.5rem",
    width: "100%",
    color: theme.palette.text.primary,
    margin: "0px 2px",
    "& :first-child": {
      color: theme.palette.text.primary,
      margin: "8px 1px",
    },
    "& :last-child": {
      margin: "8px",

      fontSize: "1.2rem",
    },
  },

  plusIcon: {
    width: "30px",
    height: "20px",
  },

  projectPageNewProjButton: {
    backgroundColor: theme.palette.secondary.main,
    // height: "43px",
    width: "140px",
    marginLeft: "9px",
    justifyContent: "flex-start",
    textTransform: "none",
    borderRadius: "7px",
    padding: "10px",
    color: theme.palette.text.secondary,
    "& :hover": {
      color: theme.palette.text.primary,
    },
  },
  popoverArrow: {
    position: "absolute",
    borderRadius: "5px",
    left: "40px",
    top: "-21px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.primary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  popoverArrowTriangle: {
    borderRadius: "5px",
    marginLeft: "40px",
    borderLeft: "25px solid transparent",
    borderRight: "25px solid transparent",
    borderBottom: `25px solid ${theme.palette.secondary.main}`,
    width: "0",
    height: "0",
    zIndex: "100",
  },
  popover: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
}));

export default useStyles;
