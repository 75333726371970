import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { Modal } from "@material-ui/core";
//@MUI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Select,
  TextField,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Asterisk } from "phosphor-react";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import useStyles from "./AssetsPageEditAssetModal.styles";
import { fdelete, fget, fpatch, uploadFile } from "./../../../API/callsAPI";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import ConfirmDeleteFile from "../../Utilities/ConfirmDeleteFile/ConfirmDeleteFile.component";
import HelperText from "../../Utilities/HelperText/HelperText";

export default function AssetsPageEditAssetModal({
  setSnackValue,
  parentAsset,
  assetType,
  statusList,
  projectId,
  handleDeleteAssetModalState,
  handleEditAssetModal,
  updateRefreshCounter,
  dragScreen,
  assetID,
  handleDragScreen,
  setDragFileEnterModal,
  data,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [departments, setDepartments] = useState([]); //For rendering departments list
  const [thumbnail, uploadThumbnailSet] = useState();
  const [setThumbnailTouch] = useState(false);
  const [defaultDept, setDefaultDept] = useState();
  const [department, setDepartment] = useState([]); //selected department from dropdown
  const [loading, setLoading] = useState(false);
  const [preLoadedFile, setPreLoadedFile] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);

  useEffect(() => {
    if (parentAsset?.file) {
      const fileNameArray = parentAsset.file.split("/");
      uploadThumbnailSet(fileNameArray[fileNameArray.length - 1]);
      setPreLoadedFile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentAsset]);

  useEffect(() => {
    fetchDepartments();
    defaultDepartments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleThumbnailUpload = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    uploadThumbnailSet(file);
    setThumbnailTouch(true);
    setPreLoadedFile(false);
  };

  const handleClearUpload = (event) => {
    event?.stopPropagation();
    uploadThumbnailSet(null);
    setThumbnailTouch(false);
  };

  const defaultDepartments = () => {
    let departmentsAlready = [];
    //Fix this warning
    parentAsset &&
      parentAsset?.allowed_dept.map((el) => {
        let temp = {
          title: el.name,
          id: el.id,
        };
        departmentsAlready.push(temp);
        return null;
      });

    setDefaultDept(departmentsAlready);
    setDepartment(departmentsAlready);
  };

  const handleDeptChange = (data) => {
    setDepartment(data);
  };

  // To fetch departments from bff at initial mount
  const fetchDepartments = async () => {
    try {
      const departmentRes = await fget({
        url: `department/?project=${projectId}`,
      });
      setDepartments(
        departmentRes.data.results?.map((el) => {
          const data = {
            title: el.name,
            id: el.id,
          };
          return data;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  //Validators
  const EditAssetValidationScheme = yup.object({
    assetName: yup
      .string("String required")
      .label("assetName")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required(),
    type: yup.string().required("This is a required field"),
  });

  const formik = useFormik({
    //add initail values for editing a version
    initialValues: {
      assetName: parentAsset ? parentAsset.name : "",
      status: parentAsset && parentAsset.status ? parentAsset.status.id : "",
      type: parentAsset && parentAsset.type ? parentAsset.type : "",
      department: [], //add Default array of departments with id's
    },
    validationSchema: EditAssetValidationScheme,
    onSubmit: async (values) => {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", values.assetName);
      thumbnail && formData.append("file_name", thumbnail.name);
      formData.append("project", projectId);
      formData.append("status", values.status);
      formData.append("type", values.type);
      if (department) {
        for (var i = 0; i < department.length; i++) {
          formData.append(`allowed_dept`, department[i].id);
        }
      }

      let updatedAsset = {
        url: `trackables/asset/${assetID}/`,
        data: formData,
      };

      try {
        const updateAssetRes = await fpatch(updatedAsset);
        if (updateAssetRes.status === 200 || 201) {
          if (thumbnail) {
            getSignedUrl(updateAssetRes.data.id);
          } else {
            setLoading(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Edited ${updateAssetRes.data.name}`,
              isError: false,
            });
            updateRefreshCounter();
            handleEditAssetModal();
          }
        }
      } catch (error) {
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
      setThumbnailTouch(false);
    },
  });

  const getSignedUrl = async (newId) => {
    try {
      const uploadUrl = await fget({
        url: `trackables/asset/${newId}/upload`,
      });
      if (uploadUrl.status === 200) {
        uploadFile({ url: uploadUrl.data?.signed_url, data: thumbnail }).then(
          () => {
            fget({
              url: `trackables/asset/${newId}/thumbnail`,
            });
            setLoading(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Edited ${data?.name}`,
              isError: false,
            });
            updateRefreshCounter();
            handleEditAssetModal();
          }
        );
      }
    } catch (error) {
      setLoading(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      handleDragScreen(true);
      setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      handleDragScreen(false);
      uploadThumbnailSet(e.dataTransfer.files[0]);
      setThumbnailTouch(true);
      setPreLoadedFile(false);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  //function to remove preloaded or selected file
  const handleDeleteFile = async (e) => {
    if (preLoadedFile) {
      try {
        const res = await fdelete({
          url: `trackables/asset/${assetID}/file`,
        });
        updateRefreshCounter();
        setDeleteFileModal(false);
        setSnackValue({
          isOpen: true,
          message: `${res?.data?.Details}`,
          isError: false,
        });
        handleClearUpload(e);
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    } else {
      setDeleteFileModal(false);
      setPreLoadedFile(false);
      handleClearUpload(e);
    }
  };

  return (
    <div className={classes.root}>
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          {dragScreen ? (
            <div className={classes.dragScreen}>Drop Here</div>
          ) : (
            ""
          )}
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Asset"
              type="Edit"
              closeModal={(e) => handleEditAssetModal(e)}
              openDeleteModal={(e) =>
                handleDeleteAssetModalState(e, parentAsset)
              }
            />
            <div className={classes.cardContentDiv1}>
              <div style={{ marginTop: "25px" }}>
                <div>
                  <span>Asset Name</span>
                  <Asterisk size={12} color="#FF0000" weight="fill" />
                  <div className={classes.cardContentDiv3}>
                    <TextField
                      type="text"
                      id="assetName"
                      className={classes.cardContentInput1}
                      InputProps={{ disableUnderline: true }}
                      name="assetName"
                      value={formik.values.assetName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.assetName)}
                    />
                  </div>
                  {formik.errors.assetName && formik.touched.assetName ? (
                    <HelperText text={formik.errors.assetName} />
                  ) : null}
                </div>

                <div style={{ marginTop: "25px" }}>
                  <div className={classes.displayFlex}>
                    <div>
                      <span>Status</span>
                      <div
                        className={classes.cardContentDiv3}
                        style={{ marginTop: "5px", width: "150px" }}
                      >
                        <Select
                          style={{ width: "100%" }}
                          labelId="demo-simple-select-outlined-label"
                          id="selectStatusA"
                          disableUnderline={true}
                          value={formik.values.status}
                          onChange={formik.handleChange}
                          className={classes.cardContentInput1}
                          name="status"
                          label="Select Status"
                        >
                          <MenuItem
                            value=""
                            className={classes.addNewStatusButton}
                            onClick={() => {
                              history.push(`/${projectId}/settings`);
                            }}
                          >
                            <Icon.Plus />
                            Add new Status
                          </MenuItem>
                          <MenuItem value="" disabled>
                            Select Status
                          </MenuItem>
                          {statusList &&
                            statusList?.map((eachStatus) => (
                              <MenuItem value={eachStatus.id}>
                                <span
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    backgroundColor: `rgb(${eachStatus?.color})`,
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    margin: "0px 5px",
                                  }}
                                ></span>
                                {eachStatus.name} - {eachStatus.code}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                    <div style={{ maxWidth: "50%" }}>
                      <span>Status Code</span>
                      <div
                        style={{ marginTop: "5px", cursor: "not-allowed" }}
                        className={classes.cardContentDiv3}
                      >
                        <TextField
                          className={classes.cardContentInput1}
                          InputProps={{
                            disableUnderline: true,
                            style: { opacity: "0.4" },
                          }}
                          disabled
                          fullWidth
                          value={
                            statusList?.find(
                              (eachStatus) =>
                                eachStatus.id === formik.values.status
                            )?.code
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "25px" }}>
                  <span>Type</span>
                  <Asterisk size={12} color="#FF0000" weight="fill" />
                  <div className={classes.cardContentDiv3}>
                    <Select
                      style={{
                        width: "100%",
                        height: "25px",
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="selectType"
                      disableUnderline
                      className={classes.cardContentInput1}
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      name="type"
                      label="Select Type"
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      {assetType &&
                        assetType.map((eachType) => (
                          <MenuItem value={eachType}>{eachType}</MenuItem>
                        ))}
                    </Select>
                  </div>
                  {formik.errors.type && formik.touched.type ? (
                    <HelperText text={formik.errors.type} />
                  ) : null}
                </div>
                {defaultDept ? (
                  <div style={{ marginTop: "25px" }}>
                    <label>Departments</label>
                    <Autocomplete
                      multiple
                      id="selectDept"
                      defaultValue={defaultDept}
                      options={departments}
                      getOptionLabel={(option) =>
                        option.title ? option.title : "No Option"
                      }
                      className={classes.autoComplete}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(e, v) => handleDeptChange(v)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />
            <div>
              <span>Attachment</span>
              <UploadFile
                thumbnail={thumbnail}
                preLoadedFile={preLoadedFile}
                handleClearUpload={handleClearUpload}
                handleThumbnailUpload={handleThumbnailUpload}
                setDeleteFileModal={setDeleteFileModal}
              />
            </div>
            <br />
            <CardActions className={classes.cardAction}>
              <Button
                fullWidth
                className={classes.done}
                type="submit"
                value="Submit"
                size="small"
                disableElevation
              >
                {loading ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  <b>Done Editing</b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <div>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={deleteFileModal}
          onClose={() => setDeleteFileModal(false)}
        >
          <ConfirmDeleteFile
            name={typeof thumbnail === "object" ? thumbnail?.name : thumbnail}
            closeModal={() => {
              setDeleteFileModal(false);
            }}
            deleteFile={() => {
              handleDeleteFile();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}
