import React from "react";
import { Button, Input } from "@material-ui/core";
import { UploadSimple } from "phosphor-react";

export default function UploadHdr(props) {
  return (
    <>
      <label
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          marginTop: "15%",
          cursor: "pointer",
        }}
        for="file"
      >
        <UploadSimple size={23} />
      </label>
      <input
        type="file"
        id="file"
        style={{ display: "none" }}
        onChange={(e) => {
          props.getUploadFile(e);
        }}
      />
    </>
  );
}
