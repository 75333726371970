import React, { useEffect, useState } from "react";
import { Button, Slider } from "@material-ui/core";
import CanvasDraw from "react-canvas-draw";
import * as Icon from "react-feather";
import mergeImages from "merge-images";
import { CirclePicker } from "react-color";
import useStyles from "./CanvasFrame.styles";
import { fget, fpost } from "../../API/callsAPI";
import { X, Eraser, ArrowUUpLeft } from "phosphor-react";
import PostEditor from "../PostEditor/postEditor";

const CanvasFrame = (props) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [isUpdate, setIsUpdate] = useState(props.isUpdate);
  const [color, setColor] = useState("#000000");
  const [brushRadius, setBrushRadius] = useState(2);
  const [lazyRadius, setLazyRadius] = useState(2);
  const [zoomCanvas, setzoomCanvas] = useState(true);

  useEffect(() => {
    if (!zoomCanvas) {
      // handleSubmit();
    }
  });

  let mentionsRef = React.createRef();

  let saveableCanvas = null;
  function getDataUrl(img) {
    // Create canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // Set width and height
    // Set width and height

    // get the top left position of the image

    if (img.height >= img.width) {
      canvas.width = 720;
      canvas.height = 402;
      var scale = Math.min(
        canvas.width / img.width,
        canvas.height / img.height
      );
      // get the top left position of the image
      var x = canvas.width / 2 - (img.width / 2) * scale;
      var y = canvas.height / 2 - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      return canvas.toDataURL("image/jpeg");
    } else {
      canvas.width = 720;
      canvas.height = (canvas.width / img.width) * img.height;
      // Draw the image
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      return canvas.toDataURL("image/jpeg");
    }
  }
  var i = new Image();
  i.src = props.imageRef ? getDataUrl(props.imageRef) : props.image;

  const handleSubmit = async (description, mentions) => {
    let b64 = mergeImages([
      props.imageRef ? getDataUrl(props.imageRef) : props.image,
      saveableCanvas.getDataURL(),
    ]).then(async (b64) => {
      const imageSrc = b64;
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const file = new File([blob], "img.png", { type: "image/png" });
      let formdata = new FormData();
      let time = props.imageRef ? "" : props.progress;
      formdata.append("description", time + description);
      formdata.append("trackable", props.assetID);
      formdata.append("attachment", file);
      formdata.append("version_id", props.version_id);
      // formdata.append("version_type", props.version_type);
      formdata.append("mentions[]", mentions);
      let newSequence = {
        url: "trackables/trackable-comment/",
        data: formdata,
      };

      try {
        const newCommentRes = await fpost(newSequence);
        if (newCommentRes.status === 200 || 201) {
          props?.updateCommentsRefreshCounter();
          props.fetchComments();
          if (isUpdate) {
            const userRes = await fget({
              url: `user/${newCommentRes.data.author}`,
            });
            newCommentRes.data.author = { ...userRes?.data };
            props.setCommentList((prevComments) => [
              newCommentRes.data,
              ...prevComments,
            ]);
          }
          if (props.toggle2) {
            props.toggle2();
          }
          props.toggle();
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const changeColor = (e) => {
    setColor(e.target.value);
  };

  const colour = [
    "#000000",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
    "#FF8888",
    "#FFE5E5",
    "#FFC1C1",
    "#FFC259",
    "#A09B6C",
    "#B1DD27",
    "#7EFE00",
    "#4DFFDB",
    "#5FD3C0",
    "#AB94DC",
    "#FF4DDB",
    "#F4939D",
  ];

  return (
    <div className={classes.canvasDiv}>
      <div className={classes.modalHead}>
        <span className={classes.heading}>Canvas</span>
        <Button>
          <X
            onClick={() => props.closeModal()}
            className={classes.closeIcon}
            size={30}
          />
        </Button>
      </div>
      <div style={{ display: "flex", gap: "2%", height: "100%" }}>
        <div className={classes.image}>
          <CanvasDraw
            ref={(canvasDraw) => {
              saveableCanvas = canvasDraw;
            }}
            id="canvass"
            style={{ cursor: zoomCanvas ? "grab" : null }}
            brushRadius={brushRadius}
            brushColor={color}
            lazyRadius={lazyRadius}
            canvasWidth={720}
            canvasHeight={420}
            enablePanAndZoom={zoomCanvas}
            clampLinesToDocument
            imgSrc={props.imageRef ? getDataUrl(props.imageRef) : props.image}
          />
        </div>
        <div className={classes.anotationTools}>
          <div className={classes.title}>Brush Settings</div>
          <div>
            <span>Color</span>
            <div
              style={{
                marginTop: "2%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CirclePicker
                id="color"
                name="color"
                width="100%"
                colors={colour}
                color={color}
                circleSpacing={10}
                onChangeComplete={(color) => {
                  setColor(color.hex);
                }}
              />
            </div>
          </div>
          <div>
            <span>Brush Size</span>
            <div style={{ display: "flex", alignItems: "center", gap: "3%" }}>
              <Slider
                className={classes.slider}
                defaultValue={2}
                value={brushRadius * 5}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                onChange={(e, value) => setBrushRadius(value / 5)}
              />
              <label>{brushRadius * 5}px</label>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <div className={classes.btncontainer1}>
              <Button
                size="small"
                variant="outlined"
                className={classes.button}
                onClick={(e) => {
                  e.preventDefault();
                  saveableCanvas.eraseAll();
                }}
              >
                <Eraser size={28} />
              </Button>
              <span className={classes.label}>Earser</span>
            </div>
            <div className={classes.btncontainer2}>
              <Button
                size="small"
                variant="outlined"
                className={classes.button}
                onClick={(e) => {
                  e.preventDefault();
                  saveableCanvas.undo();
                }}
              >
                <ArrowUUpLeft size={28} />
              </Button>
              <span className={classes.label}>Undo Changes</span>
            </div>
          </div>
          <div className={classes.commentBoxContainer}>
            <div className={classes.commentInput}>
              <PostEditor
                placeholder={"Type a comment"}
                mentions={props.users}
                ref={mentionsRef}
                handleSubmit={() =>
                  handleSubmit(
                    mentionsRef.current.toHtml(),
                    mentionsRef.current.onExtractMentions()
                  )
                }
              />
            </div>
            <Button
              className={classes.sendButton}
              size="small"
              onClick={() => {
                setzoomCanvas(!zoomCanvas);
                handleSubmit(
                  mentionsRef.current.toHtml(),
                  mentionsRef.current.onExtractMentions()
                );
              }}
            >
              <Icon.Send className={classes.sendIcon} size="20px" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasFrame;
