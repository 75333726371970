import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    alignItems: "center",
    justifyContent: "center",
    width: "150px",
    height: "40px",
    background: theme.palette.secondary.light,
    borderRadius: "10px",
    marginBottom: "10px",
  },
  dropdownDiv: {
    display: "flex",
    alignItems: "center",
    width: "150px",
    justifyContent: "space-between",
  },
  selectText: {
    marginLeft: "10px",
    fontFamily: "Nunito Sans",
    opacity: "0.7",
    fontSize: "12px",
    color: theme.palette.text.primary,
  },
  iconStyle: {
    marginRight: "10px",
    height: "20px",
    color: theme.palette.secondary.main,
  },
  formLabelText: {
    height: "0%",
    width: "100%",
    opacity: "0",
    background: theme.palette.secondary.light,
  },
  dropDown: {
    backgroundColor: theme.palette.secondary.light,
    height: "250px",
    padding: "10px",
  },
  durationDropDown: {
    backgroundColor: theme.palette.secondary.light,
    padding: "5px",
  },
  divider: {
    width: "100%",
    backgroundColor: theme.palette.text.tertiary,
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

export default useStyles;
