import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  statusDropDown: {
    display: "flex",
    alignItems: "center",
    width: "190px",
  },
  statusColor: {
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "10px",
    height: "15px",
    width: "15px",
    border: `1px solid ${theme.palette.border.main}`,
  },
  animatedIcon: {
    width: "20px !important",
    height: "20px !important",
  },
  input: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "2%",
    width: "160px",
  },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
