import React from "react";
import * as Icon from "react-feather";

import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import BreadCrumb from "../../Utilities/BreadCrumb/BreadCrumb.jsx";
import useStyle from "./ShotVersionsTools.styles";
import {
  SortButton,
  ToggleButton,
} from "../../CommonComponents/CommonButtons/CommonButtons.Component.jsx";

function ShotVersionsTools(props) {
  const classes = useStyle();
  const [statusName, setStatusName] = React.useState();
  const [taskStatusName, setTaskStatusName] = React.useState("");
  const [option, setOption] = React.useState(0);
  setTimeout(() => {
    setOption(props.tabOption);
  }, 500);

  React.useEffect(() => {
    if (props.filterValues.status && props.status.length) {
      setStatusName(
        props.status.filter((e) => e.id === props.filterValues.status)[0].name
      );
    }
  }, [props.filterValues.status, props.status]);

  return (
    <div className={classes.root}>
      <div>
        <BreadCrumb shotCode={props.parentShot ? props.parentShot.code : ""} />
      </div>
      <div className={classes.divContainerRight}>
        <div>
          <ToggleButton handleToggler={props.handleShotVersionToggle} />
        </div>
        <div>
          <SortButton
            onSubmit={props.onSubmit}
            defaultvalue={props.defaultvalue}
          />
        </div>
        {option === 0 ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <div className={classes.dropdownDiv}>
              <text className={classes.selectText}>
                {props.filterValues.status
                  ? statusName
                  : "Select By Status for Version"}
              </text>
              <Icon.ChevronDown className={classes.iconStyle} />
            </div>
            <Select
              style={{ height: "0%", opacity: "0" }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Select Status "
              value={props.filterValues.status}
              onChange={(event) => {
                props.handleStatusChange(event.target.value[0]);
                setStatusName(event.target.value[1]);
              }}
              defaultValue="none"
              MenuProps={{
                classes: {
                  paper: classes.dropDown,
                },
              }}
            >
              <MenuItem value={["", "None"]}>
                <em>None</em>
              </MenuItem>
              {props.status &&
                props.status.map((el) => (
                  <MenuItem value={[el.id, el.name]} key={el.id}>
                    {el.name} - {el.code}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl variant="outlined" className={classes.formControl}>
            <div className={classes.dropdownDiv}>
              <text className={classes.selectText}>
                {props.filterTaskValue.status
                  ? taskStatusName
                  : "Select By Status for Task"}
              </text>
              <Icon.ChevronDown className={classes.iconStyle} />
            </div>
            <Select
              style={{ height: "0%", opacity: "0" }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Select Status "
              value={0}
              onChange={(event) => {
                props.handleTaskStatusChange(event.target.value[0]);
                setTaskStatusName(event.target.value[1]);
              }}
              defaultValue="none"
              MenuProps={{
                classes: {
                  paper: classes.dropDown,
                },
              }}
            >
              <MenuItem value={["", "None"]}>
                <em>None</em>
              </MenuItem>
              {props.taskStatus &&
                props.taskStatus.map((el) => (
                  <MenuItem value={[el.id, el.name]} key={el.id}>
                    {el.name} - {el.code}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <Button
          size="small"
          className={classes.buttonEditShotandNewVersion}
          disableElevation
          onClick={props.openNewShotVersionModal}
        >
          <Icon.Plus className={classes.buttonIcon} />
          New Version
        </Button>
      </div>
    </div>
  );
}

export default ShotVersionsTools;
