import React, { useEffect, useState } from "react";
import { Camera, Shield, LogOut } from "react-feather";
import { deleteCookie } from "../../Components/authorization";

//MUI
import Switch from "@material-ui/core/Switch";
import { Divider } from "@material-ui/core";
import { MoonStars, Sun } from "phosphor-react";
//css
import useStyles from "./ProfileDropdown.styles";

function ProfileDropdown(props) {
  const APP_URL = process.env.REACT_APP_BASE_URL_MCF;
  const SSO_URL_FRONTEND = process.env.SSO_URL_FRONTEND;
  const [onState, setOnState] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (
      localStorage.getItem("theme") === null ||
      localStorage.getItem("theme") === "dark"
    )
      setOnState(true);
    else setOnState(false);
  }, []);

  const logOut = () => {
    deleteCookie("access");
    deleteCookie("refresh");
    //this is a crossdomain deployment and it should not redirect to staging instead.
    //delete cokkies and redirect to home.
    //window.location.href = SSO_URL_FRONTEND + "logout/" + APP_URL;
    window.location.href = "/";
  };

  return (
    <div className={classes.profileDropdown}>
      <div class={classes.popoverArrow}></div>
      <div className={classes.profileDropdownMenu}>
        <div className={classes.profileDropdownItems}>
          <span>
            <Camera />
          </span>
          <p>Change Profile Picture</p>
        </div>
        <div className={classes.profileDropdownItems}>
          <Shield />
          <p>Privacy Settings</p>
        </div>
        <div
          className={classes.profileDropdownItems}
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <Sun size={26} />
          </div>
          <div style={{ float: "right" }}>
            <Switch
              height={20}
              width={40}
              offColor={"#171B21"}
              onColor={"#4ECCA3"}
              checked={onState}
              onClick={props.themeToggler}
              onChange={() => setOnState(!onState)}
            />
          </div>
          <div>
            <MoonStars size={26} />
          </div>
        </div>
        <Divider style={{ marginTop: "10px" }} />
        <div
          className={classes.profileDropdownItems}
          onClick={(e) => {
            e.preventDefault();
            logOut();
          }}
        >
          <LogOut />
          <p>Log Out</p>
        </div>
      </div>
    </div>
  );
}

export default ProfileDropdown;
