import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useStyles } from "./TaskGanttViewOverview.style.js";
import TrackableViewer from "../../../TrackableViewer/TrackableViewer.jsx";
import UsersViewer from "../../../UsersViewer/UsersViewer.jsx";
import CommentBox from "../../../CommentBox/CommentBox.jsx";
import ViewComments from "../../../CommentBox/ViewComments/ViewComments.jsx";
import { useFormik } from "formik";
import { fpost, fget } from "../../../../../API/callsAPI.js";
import * as yup from "yup";
import StatusViewer from "../../../StatusViewer/StatusViewer.jsx";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
export default function TaskGanttViewModal(props) {
  const classes = useStyles();
  const [selectedtask, setSelectedtask] = useState({});
  const [fileAttached, setFileAttached] = useState(false);
  const [attachment, setAttachment] = useState();
  const [comments, setComments] = useState([]);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  useEffect(() => {
    if (props.selectedTask.id && props.selectedTask.id !== selectedtask.id) {
      const selectedTaskModal = props.tasksList.filter(
        (eachtask) => eachtask.id === props.selectedTask.id
      );
      if (selectedTaskModal.length) {
        setSelectedtask(selectedTaskModal[0]);
      }
    }
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTask]);

  const getAttachment = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    e.target.value = null;
    setAttachment(file);
    setFileAttached(true);
  };
  const handleClearUpload = (e) => {
    setFileAttached(false);
    e?.stopPropagtion();
    setAttachment(null);
  };

  const getComments = async () => {
    try {
      const res = await fget({
        url: `trackables/task-comment?task=${props.selectedTask.id}`,
      });
      if (res.status === 200 || 201) {
        setComments(res.data.results);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      attachment: attachment ? attachment : "",
    },
  });

  const postComment = async (description) => {
    let formdata = new FormData();
    formdata.append("description", description);
    formdata.append("task", props.selectedTask.id);
    formdata.append("id", props.projectId);
    if (attachment) {
      formdata.append(
        "attachment",
        formik.values.attachment,
        formik.values.attachment.name
      );
    }

    let data = {
      url: `trackables/task-comment/`,
      data: formdata,
    };
    try {
      setShowCircularProgress(true);
      const res = await fpost(data);
      if (res.status === 200 || 201) {
        setShowCircularProgress(false);
        setAttachment(null);
        setFileAttached(false);
        formik.values.comment = "";
        getComments();
        setSnackValue({
          isOpen: true,
          message: `Your Comment added Successfully."`,
          isError: false,
        });
      }
    } catch (err) {
      setShowCircularProgress(false);
      setSnackValue({
        isOpen: true,
        message: `please fill all the required field`,
        isError: true,
      });
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
        // message={`${snackValue.message}`}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <div className={classes.divContainer}>
        {selectedtask.id ? (
          <>
            <div className={classes.dateDiv}>
              <span className={classes.dateText}>
                {new Date(selectedtask.start_time).toLocaleDateString()} -
                {new Date(selectedtask.end_time).toLocaleDateString()}
              </span>
            </div>
            {/* <div style={{ margin: "12px", height: "36px", display: "flex" }}>
              {[
                { tagName: "Blender", color: "255, 139, 54" },
                { tagName: "Maya", color: "58, 239, 152" },
              ].map((eachTag) => (
                <div
                  style={{
                    marginRight: "12px",
                    border: `1px solid rgba(${eachTag.color}, 1)`,
                    backgroundColor: `rgba(${eachTag.color}, 0.4)`,
                    borderRadius: "10px",
                    height: "100%",
                    minWidth: "100px",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  <span style={{ fontWeight: "300" }}>{eachTag.tagName}</span>
                </div>
              ))}
            </div> */}
            {/* edit avtar */}
            <div style={{ height: "42px", display: "flex" }}>
              <UsersViewer selectedTask={props.selectedTask} editable="no" />
            </div>
            <div className={classes.statusContainer}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className={classes.statusTitle}>Status</span>
                <StatusViewer status={selectedtask.status} />
              </div>
              <div style={{ display: "flex" }}>
                <span className={classes.priorityTitle}>Priority</span>
                <span className={classes.priorityText}>
                  {selectedtask.priority}
                </span>
              </div>
            </div>
            <div className={classes.trackableContainer}>
              <span className={classes.trackableTitle}>Trackable</span>
              <TrackableViewer trackable={selectedtask} />
            </div>

            <div className={classes.descriptionContainer}>
              <span className={classes.descriptionTitle}>Description</span>

              <TextField
                className={classes.descriptionContent}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontWeight: "400",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  },
                }}
                value={selectedtask.description}
                disabled
              />
            </div>
            <div style={{ marginTop: "2%" }}>
              <span className={classes.label}>Comments*</span>
              <CommentBox
                fileAttached={fileAttached}
                attachment={attachment}
                handleClearUpload={handleClearUpload}
                getAttachment={getAttachment}
                postComment={postComment}
                showCircularProgress={showCircularProgress}
              />
            </div>
            <div>
              <ViewComments comments={comments} />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
