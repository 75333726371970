//Check if the file has an image extension
export const checkImage = function (url) {
  if (url) {
    return url.match(/\.(jpeg|jpg|gif|png|bmp)/) != null;
  } else {
    return true;
  }
};

export const checkGlb = function (url) {
  if (url) {
    return url.match(/\.(glb)/) != null;
  } else {
    return true;
  }
};




