import React, { useRef, useState } from "react";
import { Popover } from "@material-ui/core";
import {
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  ArrowCounterClockwise,
  CornersOut,
  DotsThreeVertical,
  DownloadSimple,
} from "phosphor-react";
import useStyles from "./ImageViewer.styles";
import RotateIcon from "../Svg/RotateIcon";

const ImageViewer = ({
  matchHeight,
  setImageRef,
  setIsOpenFullscreenViewer,
  setFileForFullscreenViewer,
  file,
}) => {
  let classes = useStyles();
  let refDiv = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let zoomImage = 0;
  let rotateImage = 0;
  let imageOpData = [];

  const handleZoomIn = () => {
    if (zoomImage) {
      refDiv.current.style.transform = `scale(${zoomImage + 1.3})`;
      refDiv.current.style.transformOrigin = "50% 50%";

      zoomImage = zoomImage + 1.3;
      imageOpData.push({ zoomIn: zoomImage });
    } else {
      refDiv.current.style.transform = "scale(1.3)";
      refDiv.current.style.transformOrigin = "50% 50%";

      zoomImage = 1.3;
      imageOpData.push({ zoomIn: zoomImage });
    }
  };

  const handleZoomOut = () => {
    if (zoomImage) {
      refDiv.current.style.transform = `scale(${
        zoomImage - 1.3 < 1 ? 1 : zoomImage - 1.3
      })`;
      refDiv.current.style.transformOrigin = "50% 50%";

      zoomImage = zoomImage - 1.3 < 1 ? 0 : zoomImage - 1.3;
      imageOpData.push({ zoomOut: zoomImage });
    }
  };

  const handleRotate = () => {
    refDiv.current.style.transform = `rotate(${rotateImage + 90}deg)`;
    rotateImage = rotateImage + 90;
    imageOpData.push({ rotate: rotateImage });
  };

  const undoChanges = () => {
    let lastChange = imageOpData.at(-1);
    if (lastChange.zoomIn) {
      console.log("zoomIn");
      zoomImage = zoomImage - 1.3 < 1 ? 1 : zoomImage - 1.3;
      refDiv.current.style.transform = `scale(${zoomImage})`;
      refDiv.current.style.transformOrigin = "50% 50%";

      zoomImage = zoomImage - 1.3 < 1 ? 0 : zoomImage - 1.3;
      imageOpData = imageOpData.slice(0, -1);
    } else if (lastChange.zoomOut) {
      zoomImage = zoomImage + 1.3;
      refDiv.current.style.transform = `scale(${zoomImage})`;
      refDiv.current.style.transformOrigin = "50% 50%";
      imageOpData = imageOpData.slice(0, -1);
    } else if (lastChange.zoomOut === 0) {
      refDiv.current.style.transform = `scale(${zoomImage + 1.3})`;
      refDiv.current.style.transformOrigin = "50% 50%";
      imageOpData = imageOpData.slice(0, -1);
    } else if (lastChange.rotate) {
      rotateImage = rotateImage - 90;
      refDiv.current.style.transform = `rotate(${rotateImage}deg)`;
      imageOpData = imageOpData.slice(0, -1);
    }
  };

  return (
    <>
      <div
        style={{
          width: matchHeight ? "98%" : "100%",
          maxHeight: "98%",
          backgroundColor: "black",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
          ref={refDiv}
        >
          <img
            ref={(image) => {
              setImageRef(image);
            }}
            crossOrigin="Anonymous"
            style={{
              width: "auto",
              height: "100%",
            }}
            src={file}
            onContextMenu={(e) => e.preventDefault()}
            draggable={false}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0.1%",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            width: "100%",
            boxShadow: "0 0 10px 1px black",
            background: "rgba(0,0,0,0.45)",
            // boxShadow: [
            //   "inset 0 -50px 50px -30px rgba(0,0,0,1)",
            //   "0 -10px 10px 0 rgba(0,0,0,0.5)",
            // ],
          }}
          className={classes.imageIconColor}
        >
          <div>
            <ArrowCounterClockwise
              weight="bold"
              style={{ cursor: "pointer", marginLeft: "15px" }}
              onClick={undoChanges}
            />
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={handleRotate}
            >
              <RotateIcon />
            </div>
            <div>
              <MagnifyingGlassPlus
                weight="bold"
                style={{ cursor: "pointer" }}
                onClick={handleZoomIn}
              />
            </div>
            <div>
              <MagnifyingGlassMinus
                weight="bold"
                style={{ cursor: "pointer" }}
                onClick={handleZoomOut}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
            }}
          >
            <div>
              <CornersOut
                weight="bold"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsOpenFullscreenViewer(true);
                  setFileForFullscreenViewer(file);
                  console.log("clicked");
                }}
              />
            </div>
            <div>
              <DotsThreeVertical
                weight="bold"
                style={{ cursor: "pointer" }}
                onClick={handleClick}
              />
            </div>
            <div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{
                  marginTop: "-45px",
                }}
              >
                <div
                  classeName={classes.downloadOption}
                  style={{
                    backgroundColor: "#1B1D28",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                >
                  <a href={file} download>
                    <DownloadSimple
                      size={20}
                      className={classes.imageIconColor}
                    />
                  </a>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageViewer;
