//All Api should be done in Higher component all the renders should be done in child
import React, { useState, useEffect } from "react";

//@MUI
import {
  Grid,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from "@material-ui/core";

//components
import ProjectCards from "../ProjectPageCards/ProjectCards.component";
import ProjectPageFiltersModal from "../ProjectPageModals/ProjectPageFiltersModal";
import ProjectPageNewProjectModal from "../ProjectPageModals/ProjectPageNewProjectModal";
import ProjectPageSortByModal from "../ProjectPageModals/ProjectPageSortByModal";
import ProjectTools from "../ProjectPageTools/ProjectPageTools.component.jsx";
import ListView from "../ProjectPageListView/ProjectListView.component";
import ManageUsersModal from "../../Utilities/ManageUsers/ManageUsersModal/ManageUsersModal.component.jsx";
import PropTypes from "prop-types";
import NoData from "../../../Pages/InformativeComponents/NoData.component";
//css
import useStyles from "./ProjectPageOverview.styles";
import { Alert } from "@material-ui/lab";
import { fdelete, fget, fpatch } from "../../../API/callsAPI";
import ErrorOrSuccessMessage from "../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";

function ProjectsPageOverview(props) {
  const classes = useStyles();
  const [isCardView, setIsCardView] = useState(true);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [isSortModalOpen, setSortModalOpen] = useState(false);
  const [isNewProjModalOpen, setNewProjModalOpen] = useState(false);
  const [sortByDefault, setSortByDefault] = useState("Latest Modified");
  const [projectMirage, setProjectMirage] = useState(props.ProjectItems);
  const [pinnedProjects, setPinnedProjects] = useState([]);
  const disableFilter = true;
  const [manageUserModal, setManageUserModal] = useState({
    modalOpen: false,
    projectId: 0,
  });
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [orgUsers, setOrgUsers] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const [projectAdmin, setProjectAdmin] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isTasksTogglerOn, setIsTasksTogglerOn] = useState(false);
  const [editProjectModalData, setEditProjectModalData] = useState({
    isOpen: false,
    selectedProject: {},
  });
  const [deleteProjectModalData, setDeleteProjectModalData] = useState({
    isOpen: false,
    selectedProject: {},
  });

  useEffect(() => {
    fetchUsers();
    setProjectMirage(props.ProjectItems);
    const storedPinnedProjects = JSON.parse(
      localStorage.getItem("pinnedProjects") || "[]"
    );
    setPinnedProjects(storedPinnedProjects);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manageUserModal.projectId) {
      defaultUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageUserModal.projectId]);

  function handleViewToggle() {
    if (isCardView) setIsCardView(false);
    else setIsCardView(true);
  }

  function handleFilterModalState() {
    setFilterModalOpen(!isFilterModalOpen);
  }

  function handleSortModalState() {
    setSortModalOpen(!isSortModalOpen);
  }

  function handleNewProjModalState() {
    setNewProjModalOpen(!isNewProjModalOpen);
  }

  const handleEditProjectModal = (project) => {
    setEditProjectModalData({
      isOpen: true,
      selectedProject: project,
    });
  };

  const handleDeleteProjectModal = (project) => {
    setDeleteProjectModalData({ isOpen: true, selectedProject: project });
  };

  const handleTasksToggler = () => {
    setIsTasksTogglerOn(!isTasksTogglerOn);
  };

  const fetchUsers = async () => {
    try {
      const usersRes = await fget({
        url: "organization/users/",
      });
      setAllUserList(usersRes.data.results);
      let temp = [];
      usersRes.data.results.map((el) =>
        temp.push({
          label: el?.first_name + " " + el?.last_name,
          value: el?.id,
          avatar: el?.avatar,
        })
      );
      setOrgUsers(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const defaultUsers = async () => {
    if (manageUserModal.projectId) {
      let selectedProject = await props?.ProjectItems?.filter((el) => {
        return el.id === manageUserModal.projectId;
      });
      let usersAlready =
        orgUsers &&
        orgUsers.filter((el) => selectedProject[0].users.includes(el.value));
      let adminAlready =
        orgUsers &&
        orgUsers.filter((el) =>
          selectedProject[0].admin_users.includes(el.value)
        );
      setProjectAdmin(adminAlready);
      setProjectUsers(usersAlready);
    } else {
      return;
    }
  };

  const submitManageUsersChanges = async (event) => {
    setIsUpdating(true);
    event.preventDefault();
    const manageUserData = {
      url: `project/${manageUserModal.projectId}/`,
      data: {
        users: projectUsers.map((user) => {
          return user.value;
        }),
        admin_users: projectAdmin.map((user) => {
          return user.value;
        }),
      },
    };
    try {
      const res = await fpatch(manageUserData);
      setIsUpdating(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully updated user for "${res?.data?.name}"`,
        isError: false,
      });
      props.updateRefreshToken();
      setManageUserModal({ modalOpen: false, projectId: 0 });
    } catch (error) {
      setIsUpdating(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const handleSelectAdmin = (admins) => {
    setProjectAdmin(admins);
  };
  const handleSelectUser = (users) => {
    setProjectUsers(users);
  };

  function handleFilterBy(start, end) {
    var startDate = new Date(start);
    var endDate = new Date(end);

    var filteredProject = props.ProjectItems.filter((a) => {
      var date = new Date(a.created_at);
      return date >= startDate && date <= endDate;
    });

    setProjectMirage(filteredProject);
  }

  function handleSubmitSort(sortBy) {
    setSortModalOpen(false);

    switch (sortBy) {
      case "Latest Modified":
        projectMirage.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("Latest Modified");
        break;
      case "A-Z":
        projectMirage.sort((a, b) => a.name.localeCompare(b.name));
        setSortByDefault("A-Z");
        break;
      case "Z-A":
        projectMirage.sort((a, b) => b.name.localeCompare(a.name));
        setSortByDefault("Z-A");
        break;
      case "LastModified":
        projectMirage.sort(function (a, b) {
          var dateA = new Date(a.updated_at),
            dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        setSortByDefault("LastModified");
        break;
      case "DateCreated":
        projectMirage.sort(function (a, b) {
          var dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateA - dateB;
        });
        setSortByDefault("DateCreated");
        break;
      default:
        return props.ProjectItems;
    }
  }

  const handleDeleteProject = async () => {
    try {
      await fdelete({
        url: `project/${deleteProjectModalData?.selectedProject?.id}/`,
      });
      setSnackValue({
        isOpen: true,
        message: `SuccessFully deleted "${deleteProjectModalData?.selectedProject?.name}"`,
        isError: false,
      });
      props.updateRefreshToken();
      setDeleteProjectModalData({ isOpen: false, selectedProject: {} });
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  const sortProjectByPinned = () => {
    const sortedProject = [...props.ProjectItems].sort((a, b) => {
      const aIndex = pinnedProjects.indexOf(a.id);
      const bIndex = pinnedProjects.indexOf(b.id);
      if (aIndex !== -1 && bIndex === -1) {
        return -1;
      }
      if (aIndex === -1 && bIndex !== -1) {
        return 1;
      }
      return 0;
    });
    setProjectMirage(sortedProject);
  };

  useEffect(() => {
    sortProjectByPinned();
  }, [props.ProjectItems]);

  useEffect(() => {
    localStorage.setItem("pinnedProjects", JSON.stringify(pinnedProjects));
    sortProjectByPinned();
  }, [pinnedProjects]);

  const handleunPinnedPost = (id) => {
    const newPinnedProj = pinnedProjects.filter((eachId) => eachId !== id);
    setPinnedProjects(newPinnedProj);
  };

  const handlePinnedPost = (id) => {
    setPinnedProjects([...pinnedProjects, id]);
  };

  return (
    <div style={{ marginLeft: "-3%", paddingRight: "1.6%" }}>
      {isUpdating ? (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
        >
          <Alert severity="info">Updating Please Wait</Alert>
        </Snackbar>
      ) : (
        <ErrorOrSuccessMessage snackValue={snackValue} />
      )}
      <ProjectTools
        toggleCardView={handleViewToggle}
        openFilterModal={handleFilterModalState}
        openSortModal={handleSortModalState}
        openNewProjModal={handleNewProjModalState}
        isCardView={isCardView}
        handleTasksToggler={handleTasksToggler}
        handleFilter={(selectedStartDate, selectedEndDate) =>
          handleFilterBy(selectedStartDate, selectedEndDate)
        }
        value={projectMirage}
        updateRefreshToken={props.updateRefreshToken}
        onSubmit={handleSubmitSort}
        defaultvalue={sortByDefault}
      />

      <div className={classes.projectPageCardsDiv1}>
        {isCardView ? (
          <>
            {projectMirage && projectMirage.length !== 0 ? (
              <Grid
                container
                spacing={5}
                justify="between"
                style={{
                  height: "75vh",
                  overflow: "auto",
                  paddingRight: "0.9%",
                }}
              >
                {projectMirage
                  ?.filter((search) => {
                    if (props.searchQuery === undefined) {
                      return search;
                    } else if (props.searchQuery === "") {
                      return search;
                    } else if (
                      search.name
                        ?.toLowerCase()
                        .includes(props.searchQuery.toLowerCase())
                    ) {
                      return search;
                    }
                    return null;
                  })
                  .map((project) => {
                    return (
                      <Grid xs={12} sm={6} xl={4} lg={4} item key={project.id}>
                        <ProjectCards
                          project={project}
                          handleManageUserModal={(value) =>
                            setManageUserModal(value)
                          }
                          updateRefreshToken={props.updateRefreshToken}
                          setSnackValue={setSnackValue}
                          usersList={orgUsers}
                          handlePinnedPost={handlePinnedPost}
                          pinnedProjects={pinnedProjects}
                          handleunPinnedPost={handleunPinnedPost}
                          handleEditProjectModal={handleEditProjectModal}
                          handleDeleteProjectModal={handleDeleteProjectModal}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            ) : (
              <NoData />
            )}
          </>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Divider flexItem className={classes.divider} />
            {projectMirage && projectMirage.length !== 0 ? (
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.headTable}
                        style={{ width: "20%" }}
                      >
                        Project Name
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headTable}
                        style={{ width: "15%" }}
                      >
                        Date Created
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headTable}
                        style={{ width: "25%" }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headTable}
                        style={{ width: "15%" }}
                      >
                        Last Modified
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.headTable}
                        style={{ width: "10%" }}
                      >
                        Users
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.headTable}
                        style={{ width: "10%" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!disableFilter
                      ? projectMirage &&
                        projectMirage.length &&
                        projectMirage
                          ?.filter((search) => {
                            if (props.searchQuery === undefined) {
                              return search;
                            } else if (props.searchQuery === "") {
                              return search;
                            } else if (
                              search.name
                                ?.toLowerCase()
                                .includes(props.searchQuery.toLowerCase())
                            ) {
                              return search;
                            }
                            return null;
                          })
                          .map((project) => {
                            return (
                              <ListView
                                key={project?.id}
                                project={project}
                                isTasksTogglerOn={isTasksTogglerOn}
                                allUserList={allUserList}
                                handleManageUserModal={(value) =>
                                  setManageUserModal(value)
                                }
                                updateRefreshToken={props.updateRefreshToken}
                                setSnackValue={setSnackValue}
                                handleEditProjectModal={handleEditProjectModal}
                                handleDeleteProjectModal={
                                  handleDeleteProjectModal
                                }
                              />
                            );
                          })
                      : props.ProjectItems &&
                        props.ProjectItems.length &&
                        props.ProjectItems?.filter((search) => {
                          if (props.searchQuery === undefined) {
                            return search;
                          } else if (props.searchQuery === "") {
                            return search;
                          } else if (
                            search.name
                              ?.toLowerCase()
                              .includes(props.searchQuery.toLowerCase())
                          ) {
                            return search;
                          }
                          return null;
                        }).map((project) => {
                          return (
                            <ListView
                              key={project?.id}
                              project={project}
                              isTasksTogglerOn={isTasksTogglerOn}
                              allUserList={allUserList}
                              handleManageUserModal={(value) =>
                                setManageUserModal(value)
                              }
                              updateRefreshToken={props.updateRefreshToken}
                              setSnackValue={setSnackValue}
                              handleEditProjectModal={handleEditProjectModal}
                              handleDeleteProjectModal={
                                handleDeleteProjectModal
                              }
                            />
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoData />
            )}
          </div>
        )}
      </div>

      <div>
        {/* <Modal
          open={isFilterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          className={classes.projectPageModals}
        >
          <ProjectPageFiltersModal
            handleFilter={(selectedStartDate, selectedEndDate) =>
              handleFilterBy(selectedStartDate, selectedEndDate)
            }
            value={projectMirage}
            closeFilterModal={handleFilterModalState}
            updateRefreshToken={props.updateRefreshToken}
          />
        </Modal> */}
        {/* <Modal
          className={classes.projectPageModals}
          open={isSortModalOpen}
          onClose={() => setSortModalOpen(false)}
        >
          <ProjectPageSortByModal
            onSubmit={handleSubmitSort}
            defaultvalue={sortByDefault}
            closeSortModal={handleSortModalState}
            updateRefreshToken={props.updateRefreshToken}
          />
        </Modal> */}

        <Modal
          className={classes.projectPageModals}
          open={isNewProjModalOpen}
          onClose={() => setNewProjModalOpen(false)}
        >
          <ProjectPageNewProjectModal
            type="New"
            handleClose={() => setNewProjModalOpen(false)}
            updateRefreshToken={props.updateRefreshToken}
            setSnackValue={setSnackValue}
            usersList={orgUsers}
          />
        </Modal>
        <Modal
          className={classes.projectPageModal}
          open={editProjectModalData.isOpen}
          onClose={() =>
            setEditProjectModalData({ isOpen: false, selectedProject: {} })
          }
        >
          <ProjectPageNewProjectModal
            type="Edit"
            project={editProjectModalData.selectedProject}
            handleClose={() =>
              setEditProjectModalData({ isOpen: false, selectedProject: {} })
            }
            updateRefreshToken={props.updateRefreshToken}
            setSnackValue={setSnackValue}
            usersList={orgUsers}
            openDeleteProjectModal={() => {
              setDeleteProjectModalData({
                isOpen: true,
                selectedProject: editProjectModalData.selectedProject,
              });
            }}
          />
        </Modal>
        <Modal
          className={classes.projectPageModals}
          open={manageUserModal.modalOpen}
          onClose={() => setManageUserModal({ modalOpen: false, projectId: 0 })}
        >
          <ManageUsersModal
            isUpdating={isUpdating}
            handleSelectAdmin={handleSelectAdmin}
            handleSelectUser={handleSelectUser}
            projectAdmin={projectAdmin}
            projectUsers={projectUsers}
            usersList={orgUsers}
            submitManageUsersChanges={submitManageUsersChanges}
            closeModal={() =>
              setManageUserModal({ modalOpen: false, projectId: 0 })
            }
          />
        </Modal>
        <Modal
          className={classes.projectPageModal}
          open={deleteProjectModalData.isOpen}
          onClose={() =>
            setDeleteProjectModalData({ isOpen: false, selectedProject: {} })
          }
        >
          <DeleteModal
            type="Project"
            name={deleteProjectModalData?.selectedProject?.name}
            handleCloseDeleteModal={() => {
              setDeleteProjectModalData({ isOpen: false, selectedProject: {} });
            }}
            handleDelete={handleDeleteProject}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ProjectsPageOverview;

ProjectsPageOverview.propTypes = {
  ProjectItems: PropTypes.object,
  searchQuery: PropTypes.string,
};
