import React from "react";
const TrashBin = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 4H2.5"
        stroke="#C4C4C4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 7V11"
        stroke="#C4C4C4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.5 7V11"
        stroke="#C4C4C4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 4V13.5C12.5 13.6326 12.4473 13.7598 12.3536 13.8536C12.2598 13.9473 12.1326 14 12 14H4C3.86739 14 3.74021 13.9473 3.64645 13.8536C3.55268 13.7598 3.5 13.6326 3.5 13.5V4"
        stroke="#C4C4C4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 4V3C10.5 2.73478 10.3946 2.48043 10.2071 2.29289C10.0196 2.10536 9.76522 2 9.5 2H6.5C6.23478 2 5.98043 2.10536 5.79289 2.29289C5.60536 2.48043 5.5 2.73478 5.5 3V4"
        stroke="#C4C4C4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TrashBin;
