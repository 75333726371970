import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },
  tableContainer: {
    width: "100%",
  },
  table: {
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "table",
    width: "100%",
    tableLayout: "fixed",
  },
  tableRow: {
    borderCollapse: "collapse",
  },
  tableBody: {
    display: "block",
    overflowY: "auto",
    [theme.breakpoints.down(1142)]: {
      height: `calc(100vh - 280px)`,
    },
    [theme.breakpoints.down(837)]: {
      height: `calc(100vh - 340px)`,
    },
  },
  tooglerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  versionsTab: {
    textTransform: "none",
    minWidth: "40px",
    margin: "0px 10px",
  },
  taskTab: {
    textTransform: "none",
    minWidth: "40px",
  },
  assetsPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    display: "flex",
    borderRadius: "6px",
    height: "30px",
    width: "250px",
    background: "#393E46",
    margin: "8px 8px",
  },
  dropdownDiv: {
    marginTop: "5px",
  },
  selectText: {
    marginLeft: "12px",
    marginTop: "5px",
    fontFamily: "Nunito Sans",
    opacity: "0.7",
    width: "60%",
    fontStyle: "normal",
  },
  iconStyle: {
    float: "right",
    width: "27px",
    height: "18px",
    color: theme.palette.secondary.main,
  },
  assetVersionTablecell: {
    padding: "1.3% 0% !important",
    color: theme.palette.text.tertiary,
    borderCollapse: "collapse",
    width: "17%",
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
  },

  closeFullScreen: {
    position: "absolute",
    top: "10px",
    right: "20px",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    height: "30px",
    width: "30px",
  },
}));

export default useStyles;
