import React, { useState, useEffect } from "react";
import useStyles from "./UpdatesPageUpdateCards.styles";
import Player from "../../Player/player.component";
import CanvasFrame from "../../CanvasFrame/CanvasFrame.component";
import UpdatesPageCardCommentDiv from "../UpdatesPageCardsTools/UpdatesPageCardCommentDiv.component";
import ModelViewer from "../../Utilities/Model-Viewer/ModelViewer";
import { Card, CardContent, Modal, Button } from "@material-ui/core";
import UpdatesPageCardComment from "../UpdatesPageCardsTools/UpdatesPageCardComment.component";
import { checkImage, checkGlb } from "../../utils";
import * as Icon from "react-feather";
import { fget } from "../../../API/callsAPI";

function UpdatesPageUpdateCards(props) {
  const classes = useStyles();
  //States

  const [updateDetail] = useState(props.update);
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState(
    props.update.comments ? props.update.comments.count : 0
  );
  const [commentList, setCommentList] = useState(
    props.update.comments ? props.update.comments.results : null
  );
  const [seekTime, setSeekTime] = useState(0);
  const [screenshot, setScreenshot] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imageRef, setImageRef] = useState(null);
  const [canvasModal, setCanvasModal] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState();
  const [playerProgress, setPlayerProgress] = useState("00:00");

  const canvasModalToggle = () => {
    setCanvasModal(!canvasModal);
  };
  const displayImageToggle = () => setImageModal(!imageModal);

  useEffect(() => {
    const accessToken = document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    accessToken && setLoggedInUser(JSON.parse(atob(accessToken.split(".")[1])));
  }, []);

  return (
    <>
      <Modal
        open={imageModal}
        onClose={displayImageToggle}
        className={classes.modal1}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            outline: "none",
            height: "80vh",
          }}
        >
          <Icon.X
            style={{ alignSelf: "flex-end" }}
            onClick={displayImageToggle}
          />
          <img
            src={displayImage}
            height="90%"
            alt="attachment"
            onContextMenu={(e) => e.preventDefault()}
          ></img>
        </div>
      </Modal>
      <div>
        <Modal open={canvasModal} className={classes.modal}>
          <CanvasFrame
            users={props.users}
            imageRef={imageRef}
            image={screenshot}
            progress={playerProgress}
            assetID={
              updateDetail.resource_object
                ? updateDetail.resource_object.asset
                  ? updateDetail.resource_object.asset
                  : updateDetail.resource_object.shot
                : null
            }
            closeModal={canvasModalToggle}
            toggle={canvasModalToggle}
            version_id={
              updateDetail.resource_object
                ? updateDetail.resource_object.id
                : null
            }
          />
        </Modal>
      </div>
      <div style={{ marginBottom: "15px" }}>
        <Card className={classes.cardDiv}>
          <CardContent
            style={{
              margin: "2px",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "20px",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontFamily: "Nunito Sans",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {updateDetail.resource_object
                  ? updateDetail.resource_object.name
                  : null}
              </p>
              <p
                style={{
                  marginRight: "2%",
                  fontSize: "10px",
                  fontFamily: "Nunito Sans",
                }}
              >
                {new Intl.DateTimeFormat("en-IN", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }).format(new Date(updateDetail.created_at))}
              </p>
            </div>
            <div style={{ height: "25px" }}>
              <p style={{ fontFamily: "Nunito Sans", fontWeight: "100" }}>
                {updateDetail.message}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {updateDetail.signed_url ? (
                checkImage(updateDetail.resource_object.file) ? (
                  <>
                    <img
                      ref={(image) => {
                        setImageRef(image);
                      }}
                      crossOrigin="Anonymous"
                      onClick={() => {
                        setDisplayImage(updateDetail.signed_url.signed_url);
                        displayImageToggle();
                      }}
                      className="card-img-top"
                      src={updateDetail.signed_url.signed_url}
                      alt="Project new"
                      style={{
                        cursor: "pointer",
                        marginRight: "10%",
                        maxWidth: "100%",
                        maxHeight: "280px",
                        borderRadius: "5px",
                      }}
                      onContextMenu={(e) => e.preventDefault()}
                    ></img>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></div>
                  </>
                ) : checkGlb(updateDetail.signed_url?.signed_url) ? (
                  updateDetail.signed_url?.signed_url ? (
                    <div style={{ height: "280px" }}>
                      <ModelViewer
                        file={updateDetail.signed_url?.signed_url}
                        allowHdrChange={true}
                        projectId={props.projectId}
                        versionId={updateDetail.resource_object.id}
                        versionType={
                          updateDetail.resource_object.asset ? "Asset" : "Shot"
                        }
                      />
                    </div>
                  ) : (
                    "File is Loading"
                  )
                ) : (
                  <>
                    <Player
                      users={props.users}
                      className="card-img-top"
                      controls
                      alt="Project new"
                      autoPlay
                      muted
                      width="100%"
                      height="100%"
                      data={updateDetail.signed_url.signed_url}
                      seekTime={seekTime}
                      setSeekTime={setSeekTime}
                      screenshot={screenshot}
                      setScreenshot={setScreenshot}
                      assetId={
                        updateDetail.resource_object.asset
                          ? updateDetail.resource_object.asset
                          : updateDetail.resource_object.shot
                      }
                      version_id={updateDetail.resource_object.id}
                      setList={setCommentList}
                      updatesPage={true}
                      setPlayerProgress={setPlayerProgress}
                      canvasModal={canvasModal}
                    />
                  </>
                )
              ) : null}
            </div>
            {updateDetail.comments ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "13px",
                }}
              >
                <div
                  onClick={(e) => {
                    setShowComments(!showComments);
                  }}
                  className={classes.commentIconDiv}
                >
                  <Icon.MessageCircle className={classes.commentIcon} />
                  <p
                    style={{
                      margin: "0px",
                      fontFamily: "nunito sans",
                      fontSize: "10px",
                    }}
                  >
                    {commentCount}
                  </p>
                </div>

                <UpdatesPageCardComment
                  users={props.users}
                  trackableId={
                    updateDetail.resource_object.shot
                      ? updateDetail.resource_object.shot
                      : updateDetail.resource_object.asset
                  }
                  versionId={updateDetail.resource_object.id}
                  setCommentList={setCommentList}
                  updateCommentCount={() => setCommentCount(commentCount + 1)}
                />
                <Button
                  className={classes.editButton}
                  onClick={canvasModalToggle}
                >
                  <Icon.Edit className={classes.editLogo} />
                </Button>
              </div>
            ) : null}

            {showComments ? (
              updateDetail.comments ? (
                <UpdatesPageCardCommentDiv
                  commentResult={commentList}
                  setCommentList={setCommentList}
                  commentList={commentList}
                  updateDetail={updateDetail}
                  loggedInUser={loggedInUser}
                  updateCommentCount={(e) => setCommentCount(e)}
                />
              ) : null
            ) : null}
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default UpdatesPageUpdateCards;
