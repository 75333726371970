import React, { useState } from "react";
import { useLocation } from "react-router";
import { TableRow, Button } from "@material-ui/core";
import useStyles from "../TaskPageListViewOverview.styles";
import { CaretDown, CaretUp } from "phosphor-react";
import TaskPageListItems from "./TaskPageListItems";

export default function TaskPageListGrouped(props) {
  const { groupedTask, taskStatus, updateRefreshCounter, handleEditTaskModal } =
    props;
  const classes = useStyles();
  const location = useLocation();
  const [hideItems, setHideItems] = useState(false);

  return (
    <>
      <TableRow hover={false} className={classes.borderBottom}>
        <div>
          <Button
            style={{
              fontSize: "18px",
              fontWeight: "600",
              padding: "5px 0",
              minWidth: 0,
            }}
            onClick={() => {
              if (props.groupedTask.linkedClass === "Asset") {
                location.pathname = `/${props.departments[0].project}/assets/${props.groupedTask.trackable}/assetversions`;
                window.open(`${location.pathname}`);
              } else {
                location.pathname = `/${props.departments[0].project}/sequence/${props.groupedTask.tasks[0].linked.parent_sequence}/shots/${props.groupedTask.trackable}/shotversions`;
                window.open(`${location.pathname}`);
              }
            }}
          >
            {groupedTask.tasks[0].linked.code ||
              groupedTask.tasks[0].linked.name}
          </Button>
          <Button
            onClick={() => setHideItems(!hideItems)}
            style={{ minWidth: "10px" }}
          >
            {hideItems ? <CaretDown size={20} /> : <CaretUp size={20} />}
          </Button>
        </div>
      </TableRow>
      {groupedTask.tasks.map((singleTask) => (
        <TaskPageListItems
          key={singleTask?.id}
          handleViewTaskModal={(task) =>
            props.handleViewTaskModal(task, groupedTask.tasks)
          }
          singleTask={singleTask}
          statusList={taskStatus}
          currStatus={singleTask.status}
          updateRefreshCounter={updateRefreshCounter}
          hideItems={hideItems}
          handleEditTaskModal={handleEditTaskModal}
          handleTaskUpdate={props.handleTaskUpdate}
          projectUsers={props.projectUsers}
          departments={props.departments}
          scrollValue={props.scrollValue}
        />
      ))}
    </>
  );
}
