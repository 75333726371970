import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import useStyles from "./ConfigureEmails.style";
import { Grid, Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { fpatch } from "../../../../API/callsAPI";
import * as Icon from "react-feather";

export default function ConfigureEmail({
  setSnackValue,
  emailNotifications,
  projectId,
}) {
  const classes = useStyles();
  const [showButtons, setShowButtons] = useState(false);
  const [value, setValue] = useState(
    emailNotifications.all
      ? "2"
      : emailNotifications.comment_add
      ? "1"
      : emailNotifications.project_user_add ||
        emailNotifications.task_assign ||
        emailNotifications.trackable_status_change ||
        emailNotifications.task_status_change ||
        emailNotifications.version_status_change ||
        emailNotifications.trackable_add ||
        emailNotifications.version_add
      ? "3"
      : ""
  );
  const [chooseNotifications, setChooseNotifications] = useState({
    project: projectId,
    all: false,
    comment_add: false,
    comment_mention: false,
  });

  const [check, setCheck] = useState({
    all: emailNotifications.all,
    project_user_add: emailNotifications.project_user_add,
    task_assign: emailNotifications.task_assign,
    trackable_status_change: emailNotifications.trackable_status_change,
    task_status_change: emailNotifications.task_status_change,
    version_status_change: emailNotifications.version_status_change,
    trackable_add: emailNotifications.trackable_add,
    version_add: emailNotifications.version_add,
    comment_add: emailNotifications.comment_add,
    comment_mention: emailNotifications.comment_mention,
  });

  const notifications = [
    {
      id: "0",
      name: "project_user_add",
      message: "Project user add",
      value: check.project_user_add,
    },
    {
      id: "1",
      name: "task_assign",
      message: "Task Assign",
      value: check.task_assign,
    },
    {
      id: "2",
      name: "trackable_status_change",
      message: "Trackables Status Change",
      value: check.trackable_status_change,
    },
    {
      id: "3",
      name: "task_status_change",
      message: "Task Status Change",
      value: check.task_status_change,
    },
    {
      id: "4",
      name: "version_status_change",
      message: "Version Status Change",
      value: check.version_status_change,
    },
    {
      id: "5",
      name: "trackable_add",
      message: "Add Trackables",
      value: check.trackable_add,
    },
    {
      id: "6",
      name: "version_add",
      message: "Add Version",
      value: check.version_add,
    },
  ];

  useEffect(() => {
    if (emailNotifications.all) {
      setValue("2");
    } else if (emailNotifications.comment_add) {
      setValue("1");
    } else if (
      emailNotifications.project_user_add ||
      emailNotifications.task_assign ||
      emailNotifications.trackable_status_change ||
      emailNotifications.task_status_change ||
      emailNotifications.version_status_change ||
      emailNotifications.trackable_add ||
      emailNotifications.version_add
    ) {
      setValue("3");
      setCheck({
        all: emailNotifications.all,
        project_user_add: emailNotifications.project_user_add,
        task_assign: emailNotifications.task_assign,
        trackable_status_change: emailNotifications.trackable_status_change,
        task_status_change: emailNotifications.task_status_change,
        version_status_change: emailNotifications.version_status_change,
        trackable_add: emailNotifications.trackable_add,
        version_add: emailNotifications.version_add,
        comment_add: emailNotifications.comment_add,
        comment_mention: emailNotifications.comment_mention,
      });
    }
  }, [emailNotifications]);

  const handleRadioChange = (e) => {
    setValue(e.target.value);
    setShowButtons(true);
  };

  const handleOnChange = (e) => {
    // console.log(e)
    setChooseNotifications({
      ...chooseNotifications,
      [e.target.name]: e.target.checked,
    });
    setShowButtons(true);
  };

  const changeEmailConfiguration = () => {
    let message = "Changes Saved Successfully";
    if (value === "1") {
      let data = {
        all: false,
        project_user_add: false,
        task_assign: false,
        trackable_status_change: false,
        task_status_change: false,
        version_status_change: false,
        trackable_add: false,
        version_add: false,
        comment_add: true,
        comment_mention: true,
        project: projectId,
      };
      setNotificationConfiguration(data, message);
    } else if (value === "2") {
      let data = {
        all: true,
        project: projectId,
      };

      setNotificationConfiguration(data, message);
    } else {
      console.log(chooseNotifications);
      setNotificationConfiguration(chooseNotifications, message);
    }
  };

  const discardChanges = () => {
    let data = {
      all: false,
      project_user_add: false,
      task_assign: false,
      trackable_status_change: false,
      task_status_change: false,
      version_status_change: false,
      trackable_add: false,
      version_add: false,
      comment_add: false,
      comment_mention: false,
      project: projectId,
    };
    let message = "Changes Discarded Successfully";
    setNotificationConfiguration(data, message);
  };

  //set notification configuration
  const setNotificationConfiguration = async (data, message) => {
    try {
      const res = await fpatch({
        url: `email-events/${projectId}/`,
        data,
      });
      if (res.status === 200 || 201) {
        setSnackValue({
          isOpen: true,
          message: `${message}`,
          isError: false,
        });
      }
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <>
      <div className={classes.divContent}>
        <div className={classes.statusSettingTitle}>Configure E-mail</div>
        <div className={classes.divContent1}>
          {showButtons ? (
            <>
              <div>
                <Button
                  className={classes.saveChangesButton}
                  onClick={() => changeEmailConfiguration()}
                >
                  <Icon.Save
                    style={{
                      marginRight: "6%",
                      width: "18px",
                      marginBottom: "1px",
                    }}
                  />
                  Save Changes
                </Button>
              </div>
              <div>
                <Button
                  className={classes.discardChangeButton}
                  onClick={() => discardChanges()}
                >
                  <Icon.X
                    style={{
                      marginRight: "6%",
                      width: "18px",
                      marginBottom: "1px",
                    }}
                  />
                  Discard Changes
                </Button>
              </div>
            </>
          ) : (
            " "
          )}
        </div>
      </div>
      <FormControl className={classes.root}>
        <RadioGroup
          name="Radio Form"
          value={value}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            classes={{
              label: classes.formControlLabel,
            }}
            value="1"
            control={<Radio />}
            label="Just Comments"
          />
          <FormControlLabel
            classes={{
              label: classes.formControlLabel,
            }}
            value="2"
            control={<Radio />}
            label="Send me everything"
          />
          <FormControlLabel
            classes={{
              label: classes.formControlLabel,
            }}
            value="3"
            control={<Radio />}
            label="Let me choose my notifications"
          />
        </RadioGroup>
      </FormControl>
      <div>
        {value === "3" ? (
          <Grid container spacing={2} justify="between">
            {notifications?.map((el, index) => {
              return (
                <Grid item xs={2} sm={2} key={el.id}>
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Checkbox
                      checked={chooseNotifications[el.name]}
                      defaultChecked={check[el.name]}
                      onClick={(e) => handleOnChange(e)}
                      name={el.name}
                    />

                    <div>
                      <span className={classes.labelText}>{el.message}</span>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
