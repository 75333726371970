import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { getTrackableIconFromAssetName } from "../Trackables/trackablesData.jsx";
import ImageViewer from "../ImageViewer/ImageViewer.component";
import NoThumbnail from "../NoThumbnail.component";
import { useStyles } from "./TrackableViewer.js";
import TaskVersionHover from "../TaskVersionHover/TaskVersionHover.jsx";
export default function TrackableViewer({ trackable }) {
  const [isHover, setIsHover] = useState(false);

  const classes = useStyles();
  const location = useLocation();

  const openTrackableInNewTab = () => {
    if (trackable.linked_class === "Asset") {
      location.pathname = `/${trackable.linked.project}/assets/${trackable.linked.id}/assetversions/`;
      window.open(`${location.pathname}`);
    } else {
      location.pathname = `/${trackable.linked.project}/sequence/${trackable.linked.parent_sequence}/shots/${trackable.linked.id}/shotversions`;
      window.open(`${location.pathname}`);
    }
  };

  const openVersionInNewTab = () => {
    const version =
      trackable.shotversions[0]?.id || trackable.assetversions[0]?.id;
    if (trackable.linked_class === "Asset") {
      location.pathname = `/${trackable.linked.project}/assets/${trackable.linked.id}/assetversions/`;
      const encode = btoa(unescape(encodeURIComponent(`versionId=${version}`)));

      window.open(`${location.pathname}?${encode}`);
    } else {
      location.pathname = `/${trackable.linked.project}/sequence/${trackable.linked.parent_sequence}/shots/${trackable.linked.id}/shotversions/`;
      const encode = btoa(unescape(encodeURIComponent(`versionId=${version}`)));
      window.open(`${location.pathname}?${encode}`);
    }
  };

  return (
    <div className={classes.trackableContainer}>
      <div className={classes.trackableIcon}>
        {getTrackableIconFromAssetName(trackable.linked_class)}
      </div>
      {trackable.linked.thumbnail ? (
        <ImageViewer
          style={{
            borderRadius: "10px",
            width: "40px",
            marginRight: "12px",
          }}
          url={trackable.linked.thumbnail}
        />
      ) : (
        <NoThumbnail style={{ marginRight: "12px", borderRadius: "10px" }} />
      )}

      <span>
        <span className={classes.onHover} onClick={openTrackableInNewTab}>
          {trackable.linked.name || trackable.linked.code}
        </span>

        {trackable.shotversions[0]?.name || trackable.assetversions[0]?.name ? (
          <>
            <span>{" - "}</span>
            <span className={classes.onHover} onClick={openVersionInNewTab}>
              {trackable.shotversions[0]?.name ||
                trackable.assetversions[0]?.name}
            </span>
          </>
        ) : (
          ""
        )}
        {trackable.shotversions.length > 1 ? (
          <span
            onMouseEnter={() => {
              setIsHover(true);
            }}
          >
            {" "}
            +{trackable.shotversions.length - 1}
          </span>
        ) : trackable.assetversions.length > 1 ? (
          <span
            onMouseEnter={() => {
              setIsHover(true);
            }}
          >
            {" "}
            +{trackable.assetversions.length - 1}
          </span>
        ) : (
          ""
        )}
      </span>
      {isHover ? (
        <div
          style={{
            marginTop: "35px",
            marginLeft: "-30px",
            position: "absolute",
          }}
        >
          <TaskVersionHover
            versionList={
              trackable.shotversions.length
                ? trackable.shotversions
                : trackable.assetversions
            }
            parent={trackable.linked}
            setIsHover={setIsHover}
            isHover={isHover}
            tableView={true}
            versionLength={
              trackable.shotversions.length
                ? trackable.shotversions.length
                : trackable.assetversions.length
            }
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
