import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Slide,
  TableBody,
} from "@material-ui/core";

import React, { useState } from "react";
import useStyles from "./TaskPageListViewOverview.styles";
import TaskPageListGrouped from "./TaskPageListViewTools/TaskPageListGrouped";
import TaskPageEditModal from "../../TaskPageModals/TaskPageEditModal";
import { fpatch } from "../../../../API/callsAPI";
import TaskViewModal from "../../TaskPageModals/TaskViewModal/TaskViewModal";
import ErrorOrSuccessMessage from "../../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component";

export default function TaskPageListViewOverview(props) {
  const {
    groupedTask,
    taskStatus,
    updateRefreshCounter,
    projectUsers,
    departments,
    setUpdating,
    rearrangeTaskOrder,
  } = props;
  const [isTaskPageEditModalOpen, setIsTaskPageEditModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [mutualTaskList, setMutualTaskList] = useState([]); //task sharing same trackable
  const [scrollValue, setScrollValue] = useState(0);
  const [openTaskViewModal, setTaskViewModal] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [viewModal, setViewModal] = useState(true);
  //get the filtered object from params
  const classes = useStyles();

  function handleViewTaskModal(task, mutualTasks) {
    setTaskViewModal(true);
    setSelectedTask(task);
    if (mutualTasks) {
      setMutualTaskList(mutualTasks);
    }
  }

  const handleEditTaskModal = () => {
    setIsTaskPageEditModalOpen(!isTaskPageEditModalOpen);
  };

  const handleTaskUpdate = async (taskId, updatedTaskForm) => {
    setUpdating(true);
    let updatedTask = {
      url: `trackables/task/${taskId}/`,
      data: updatedTaskForm,
    };
    try {
      const updateTaskRes = await fpatch(updatedTask);
      setUpdating(false);
      setIsTaskPageEditModalOpen(false);
      setSnackValue({
        isOpen: true,
        message: `SuccessFully edited task : ${updateTaskRes.data.name}`,
        isError: false,
      });
      updateRefreshCounter();
    } catch (error) {
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <TableContainer
        style={{
          height:
            props.trackableType === "task"
              ? `calc(100vh - 230px)`
              : `calc(100vh - 260px)`,
          marginTop: "5px",
          paddingRight: "10px",
        }}
        /* onScroll={(e) => {
          setScrollValue(e.target.scrollTop);
        }} */
      >
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.headTable} align="left">
                Name
              </TableCell>
              <TableCell className={classes.headTable} align="left">
                Duration
              </TableCell>
              <TableCell className={classes.headTable} align="left">
                Status
              </TableCell>
              <TableCell className={classes.headTable} align="left">
                priority
              </TableCell>
              <TableCell className={classes.headTable} align="left">
                Trackable
              </TableCell>
              <TableCell className={classes.headTable} align="left">
                Department
              </TableCell>
              <TableCell className={classes.headTable} align="left">
                Users
              </TableCell>

              <TableCell className={classes.headTable} align="left">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedTask &&
              groupedTask.groupedTask.map((task, index) => (
                <TaskPageListGrouped
                  key={index}
                  handleViewTaskModal={handleViewTaskModal}
                  groupedTask={task}
                  taskStatus={taskStatus}
                  updateRefreshCounter={updateRefreshCounter}
                  handleEditTaskModal={handleEditTaskModal}
                  handleTaskUpdate={handleTaskUpdate}
                  projectUsers={projectUsers}
                  departments={departments}
                  scrollValue={scrollValue}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Slide direction="left" in={openTaskViewModal}>
        <div
          style={{
            display: openTaskViewModal ? "block" : "none",
            top: props.trackableType === "task" ? "155px" : "185px",
            height:
              props.trackableType === "task"
                ? `calc(100vh - 155px)`
                : `calc(100vh - 185px)`,
          }}
          className={classes.floatingDiv}
        >
          <div
            style={{
              height: "100%",
              padding: "5px",
            }}
          >
            {viewModal ? (
              <TaskViewModal
                closeModal={() => setTaskViewModal(false)}
                task={selectedTask}
                projectId={props.projectId}
                taskStatus={taskStatus}
                handleEditTaskModal={handleEditTaskModal}
                handleTaskUpdate={handleTaskUpdate}
                projectUsers={projectUsers}
                switchToEditModal={() => setViewModal(false)}
                handleTaskDelete={props.handleTaskDelete}
              />
            ) : (
              <TaskPageEditModal
                rearrangeTaskOrder={rearrangeTaskOrder}
                SwitchToViewModal={() => setViewModal(true)}
                selectedTask={selectedTask}
                mutualTaskList={mutualTaskList}
                statusList={taskStatus}
                handleEditTaskModal={() => setTaskViewModal(false)}
                handleTaskUpdate={handleTaskUpdate}
                projectUsers={projectUsers}
                departments={departments}
              />
            )}
          </div>
        </div>
      </Slide>
    </>
  );
}
