import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  canvas: {
    width: "100%",
    // height: "550px !important",
    cursor: "pointer",
    backgroundColor: "grey",
    borderRadius: "20px",
  },
  hdrOptionContainer: {
    width: "87%",
    ooverflowY: "hidden",
    overflowX: "auto",
    marginRight: "3%",
  },
  ModelPageModals: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bgContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "13px",
    background: theme.palette.secondary.light,
    borderRadius: "0px 0px 20px 20px",
    position: "absolute",
    marginTop: "-157px",
    width: "100%",
  },
}));

export default useStyles;
