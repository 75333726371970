import React, { useState, useEffect } from "react";

import { Plus } from "react-feather";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
//Validators
import { useFormik } from "formik";
import * as yup from "yup";
//
import useStyles from "./ShotVersionNewVersion.styles";
import { Asterisk } from "phosphor-react";

import { fget, fpost, exportFileWithXhr } from "../../../API/callsAPI";
import { useHistory } from "react-router-dom";
import DragScreen from "../../Utilities/DragScreen/DragScreen";
import UploadFile from "../../Utilities/UploadFile/UploadFile";
import ModalTitleAndButtons from "../../Utilities/ModalTitleAndButtons/ModalTitleAndButtons.component";
import HelperText from "../../Utilities/HelperText/HelperText";

const ShotVersionNewVersionModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [file, setUploadFile] = useState();
  const [fileTouched, setFileTouched] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedTask, setSelectedTask] = useState();

  useEffect(() => {
    // fetching the tasks only if the taskList is empty
    // if its fetched in edit modal no need to make the api call again
    !props.taskList.length && props.fetchTasks();
  }, []);

  const displaySelectedFile = (event) => {
    let file = event.target.files[0];
    event.target.value = null;
    setUploadFile(file);
    setFileTouched(true);
  };

  const handleClearUpload = (event) => {
    event.stopPropagation();
    setUploadFile(null);
    setFileTouched(false);
  };

  //Validators
  const NewVersionValidationScheme = yup.object({
    versionName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      versionName: "",
      status: "",
    },
    validationSchema: NewVersionValidationScheme,
    onSubmit: async (values) => {
      setLoader(true);
      if (fileTouched) {
        setFileUploading(true);
      }
      let formdata = new FormData();
      formdata.append("name", values.versionName);
      if (file) {
        formdata.append("file_name", file.name);
      }
      formdata.append("status", values.status);
      formdata.append("shot", props.shotID);
      selectedTask && formdata.append("task", selectedTask);

      let newVersion = {
        url: "trackables/shot-version/",
        data: formdata,
      };

      try {
        const res = await fpost(newVersion);

        if (res.status === 200 || 201) {
          if (file) {
            getSignedUrl(res.data.id);
          } else {
            setLoader(false);
            setSnackValue({
              isOpen: true,
              message: `SuccessFully Created ${res.data.name}`,
              isError: false,
            });
            setFileUploading(false);
            props.updateRefreshCounter();
            props.handleShotVersionModal();
          }
        }
      } catch (error) {
        setFileUploading(false);
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
      }
    },
  });

  function updateProgressBar(e) {
    if (e.lengthComputable) {
      var percentComplete = parseInt((e.loaded / e.total) * 100);
      setUploadProgress(percentComplete);
    }
  }

  function transferComplete(evt) {
    setSnackValue({
      isOpen: true,
      message: `File transfered SuccessFully `,
      isError: false,
    });
  }

  function transferFailed(evt) {
    setSnackValue({
      isOpen: true,
      message: `Transfer Failed`,
      isError: true,
    });
  }

  function transferCanceled(evt) {
    setSnackValue({
      isOpen: true,
      message: `File Transfer Cancelled`,
      isError: true,
    });
  }

  const getSignedUrl = async (newId) => {
    try {
      const res = await fget({
        url: `trackables/shotversion/${newId}/upload`,
      });

      if (res.status === 200) {
        await exportFileWithXhr({
          url: res.data?.signed_url,
          data: file,
          updateProgressBar: (e) => {
            updateProgressBar(e);
          },
          transferComplete: transferComplete(),
          transferFailed: transferFailed(),
          transferCanceled: transferCanceled(),
        });
        fget({
          url: `trackables/shotversion/${newId}/thumbnail`,
        });
        setLoader(false);
        setSnackValue({
          isOpen: true,
          message: `SuccessFully Created`,
          isError: false,
        });
        setFileUploading(false);
        props.updateRefreshCounter();
        props.handleShotVersionModal();
        props.handleUpdatedFileForParent(file);
      }
    } catch (error) {
      setFileUploading(false);
      setLoader(false);
      setSnackValue({
        isOpen: true,
        message: `${error}`,
        isError: true,
      });
    }
  };
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const handleDragFile = (e) => {
    if (e.type === "dragenter") {
      //when  file enter- display dragscreen
      props.handleDragScreen(true);
      props.setDragFileEnterModal(true);
    }
    //catching the dragged file for uploading
    if (e.type === "drop") {
      props.handleDragScreen(false);
      console.log(e.dataTransfer.files[0]);
      setUploadFile(e.dataTransfer.files[0]);
      setFileTouched(true);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ isOpen: false, message: "" })}
        // message={`${snackValue.message}`}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <Card
        className={classes.card}
        onDragEnter={(e) => handleDragFile(e)}
        onDrop={(e) => handleDragFile(e)}
        onDragLeave={(e) => handleDragFile(e)}
        onDragOver={(e) => handleDragFile(e)}
      >
        <CardContent className={classes.cardContent}>
          <DragScreen dragScreen={props.dragScreen} />
          <form enctype="multipart/form-data" onSubmit={formik.handleSubmit}>
            <ModalTitleAndButtons
              name="Version"
              type="New"
              closeModal={() => props.handleShotVersionModal()}
            />
            <div className={classes.cardContentDiv1}>
              <div>
                <span>Version Name</span>
                <Asterisk size={12} color="#FF0000" weight="fill" />
                <div
                  style={{ marginTop: "5px" }}
                  className={classes.cardContentDiv2}
                >
                  <TextField
                    type="text"
                    className={classes.cardContentInput1}
                    InputProps={{ disableUnderline: true }}
                    placeholder="Enter a Version Name"
                    name="versionName"
                    value={formik.values.versionName}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.versionName)}
                  />
                </div>
                {formik.errors.versionName && formik.touched.versionName ? (
                  <HelperText text={formik.errors.versionName} />
                ) : null}
              </div>
              <div
                className={classes.displayFlex}
                style={{
                  marginTop: "25px",
                }}
              >
                <div style={{ width: "150px" }}>
                  <span>Status</span>
                  <div
                    style={{ marginTop: "5px" }}
                    className={classes.cardContentDiv2}
                  >
                    <Select
                      style={{ width: "100%" }}
                      labelId="demo-simple-select-outlined-label"
                      id="selectStatusV"
                      label="Select Status"
                      className={classes.cardContentInput1}
                      disableUnderline
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                    >
                      <MenuItem
                        className={classes.addNewStatusButton}
                        onClick={() => {
                          history.push(`/${props.projectId}/settings`);
                        }}
                      >
                        <Plus />
                        Add new Status
                      </MenuItem>
                      <MenuItem value="">None</MenuItem>
                      {props.status &&
                        props.status.map((eachStatus) => (
                          <MenuItem value={eachStatus.id}>
                            <span
                              style={{
                                height: "15px",
                                width: "15px",
                                backgroundColor: `rgb(${eachStatus?.color})`,
                                borderRadius: "50%",
                                display: "inline-block",
                                margin: "0px 5px",
                              }}
                            ></span>
                            {eachStatus.name} - {eachStatus.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
                <div style={{ maxWidth: "50%" }}>
                  <span>Status Code</span>
                  <div
                    style={{ marginTop: "5px" }}
                    className={classes.cardContentDiv2}
                  >
                    <TextField
                      className={classes.cardContentInput1}
                      InputProps={{
                        disableUnderline: true,
                        style: { opacity: "0.4" },
                      }}
                      disabled
                      fullWidth
                      value={
                        props.status.find(
                          (eachStatus) => eachStatus.id === formik.values.status
                        )?.code
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                <span style={{ display: "block", margin: "25px 0 5px 0" }}>
                  Link to Task
                </span>
                <Select
                  style={{ width: "100%" }}
                  id="linkToTask"
                  label="Link to Task"
                  name="Link to Task"
                  className={classes.cardContentDiv2}
                  disableUnderline
                  value={selectedTask}
                  onChange={(e) => setSelectedTask(e.target.value)}
                >
                  <MenuItem>None</MenuItem>
                  {props.taskList &&
                    props.taskList.map((task) => (
                      <MenuItem value={task.id}>{task.name}</MenuItem>
                    ))}
                </Select>
              </div>
              <div
                style={{
                  marginTop: "25px",
                  display: "flex",
                  flexDirection: "column",

                  justifyContent: "space-between",

                  width: "100%",
                }}
              >
                <span>Attachments </span>
                <UploadFile
                  fileUploading={fileUploading}
                  uploadProgress={uploadProgress}
                  thumbnail={file}
                  handleClearUpload={handleClearUpload}
                  handleThumbnailUpload={displaySelectedFile}
                />
              </div>
            </div>

            <br />

            <CardActions className={classes.cardAction}>
              <Button
                className={classes.done}
                type="submit"
                value="Submit"
                size="small"
                disabled={fileUploading}
                disableElevation
              >
                {loader ? (
                  <CircularProgress style={{ width: "25px", height: "25px" }} />
                ) : (
                  <b>Create New Version</b>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ShotVersionNewVersionModal;
