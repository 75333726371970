import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { X } from "phosphor-react";
import React from "react";
import useStyles from "./NewOrEditTaskPhaseModal.style";
import { useFormik } from "formik";
import * as yup from "yup";
import { fpatch, fpost } from "../../../../../API/callsAPI";
import { useState } from "react";

const NewOrEditTaskPhaseModal = ({
  type,
  handleClose,
  selectedPhase,
  templateId,
  updateRefreshCounter,
  departments,
  setSnackValue,
}) => {
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState(false);

  const NewPhaseValidationScheme = yup.object({
    phaseName: yup
      .string("String required")
      .min(1, "Too Short!")
      .max(50, "Maximum Character Limit: 50")
      .required("This is a required field"),
    duration: yup.number("number required").required("this field is required"),
    department: yup
      .string("string required")
      .required("this field is required"),
  });
  const formik = useFormik({
    initialValues: {
      phaseName: selectedPhase?.name ? selectedPhase?.name : "",
      duration: selectedPhase?.number_of_days
        ? selectedPhase?.number_of_days
        : "",
      department: selectedPhase?.dept
        ? departments.find((dep) => dep.id === selectedPhase.dept)
        : "",
      description: selectedPhase?.description ? selectedPhase?.description : "",
    },
    validationSchema: NewPhaseValidationScheme,
    onSubmit: async (values) => {
      let formdata = new FormData();
      formdata.append("name", values.phaseName);
      formdata.append("number_of_days", values.duration);
      formdata.append("dept", values.department.id);
      formdata.append("description", values.description);
      formdata.append("template", templateId);
      formdata.append("color", "decolor");

      try {
        setIsUpdating(true);
        if (type === "New") {
          const newData = { url: `phase/`, data: formdata };
          const newRes = await fpost(newData);
          setSnackValue({
            isOpen: true,
            message: `successfully created task phase "${newRes?.data?.name}"`,
            isError: false,
          });
          updateRefreshCounter();
          handleClose();
          setIsUpdating(false);
        } else {
          const editData = {
            url: `phase/${selectedPhase?.id}/`,
            data: formdata,
          };
          const editRes = await fpatch(editData);
          setSnackValue({
            isOpen: true,
            message: `successfully edited task phase "${editRes?.data?.name}"`,
            isError: false,
          });
          updateRefreshCounter();
          handleClose();
          setIsUpdating(false);
        }
      } catch (error) {
        setSnackValue({
          isOpen: true,
          message: `${error}`,
          isError: true,
        });
        setIsUpdating(false);
      }
    },
  });
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.cardTitle}>
              <span className={classes.title}>{type} Task Phase</span>
              <Button
                className={classes.closeButton}
                onClick={() => handleClose()}
              >
                <X size="30px" />
              </Button>
            </div>
            <br />

            <div>
              <span className={classes.inputTitle}>
                Task Phase Name <span style={{ color: "red" }}>*</span>
              </span>
              <div className={classes.cardContentDiv1}>
                <TextField
                  placeholder="Enter a task phase name"
                  fullWidth
                  type="text"
                  className={classes.cardContentInput}
                  InputProps={{ disableUnderline: true }}
                  name="phaseName"
                  id="phaseName"
                  value={formik.values.phaseName}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.phaseName)}
                />
                <FormHelperText style={{ color: "rgb(255,204,0)" }}>
                  {formik.errors.phaseName ? "Phase Name is required" : null}
                </FormHelperText>
              </div>
            </div>
            <div>
              <span className={classes.inputTitle}>
                Duration <span style={{ color: "red" }}>*</span>
              </span>
              <div className={classes.cardContentDiv1}>
                <TextField
                  placeholder="Enter duration (in days)"
                  fullWidth
                  type="number"
                  className={classes.cardContentInput}
                  InputProps={{ disableUnderline: true }}
                  name="duration"
                  id="duration"
                  value={formik.values.duration}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.duration)}
                />
                <FormHelperText style={{ color: "rgb(255,204,0)" }}>
                  {formik.errors.duration ? "Duration is required" : null}
                </FormHelperText>
              </div>
            </div>
            <div>
              <span className={classes.inputTitle}>Select Department</span>
              <span style={{ color: "red" }}>*</span>
              <div className={classes.cardContentDiv1}>
                <Select
                  // required
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-outlined-label"
                  id="department"
                  disableUnderline
                  className={classes.cardContentInput1}
                  label="Select Task Template"
                  onChange={formik.handleChange}
                  name="department"
                  value={formik.values.department}
                  error={Boolean(formik.errors.department)}
                >
                  <MenuItem value="">None</MenuItem>
                  {departments &&
                    departments.map((eachDepartment) => (
                      <MenuItem value={eachDepartment}>
                        {eachDepartment.name} - {eachDepartment.id}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "rgb(255,204,0)" }}>
                  {formik.errors.department ? "Department is required" : null}
                </FormHelperText>
              </div>
            </div>
            <div>
              <span className={classes.inputTitle}>Description</span>
              <div className={classes.cardContentDiv2}>
                <TextField
                  type="text"
                  fullWidth
                  multiline={true}
                  InputProps={{ disableUnderline: true }}
                  placeholder="Enter a Description..."
                  name="description"
                  className={classes.cardContentInput}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <CardActions className={classes.buttons}>
              <Button
                size="small"
                className={classes.confirmButton}
                disableElevation
                type="submit"
                value="Submit"
                disabled={isUpdating ? true : false}
              >
                {isUpdating ? (
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                ) : (
                  <span>Confirm</span>
                )}
              </Button>
              <Button
                size="small"
                className={classes.cancelButton}
                disableElevation
                onClick={() => {
                  handleClose();
                }}
                disabled={isUpdating ? true : false}
              >
                {isUpdating ? (
                  <CircularProgress style={{ width: "20px", height: "20px" }} />
                ) : (
                  <span>Cancel</span>
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewOrEditTaskPhaseModal;
