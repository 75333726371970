import React, { useState } from "react";
//@Components
import ShotVersionsListStatusButtons from "../ShotVersionsListContents/ShotVersionsListStatusButtons.component";

//@MUI
import { TableRow, TableCell, Modal } from "@material-ui/core";

import useStyles from "./ShotVersionList.styles";
import ShotVersionEditVersionModal from "../ShotVersionPageModals/ShotVersionEditVersionModal";
import DeleteModal from "../../Utilities/DeleteModal/DeleteModal.component";
import NoThumbnail from "../../Utilities/NoThumbnail.component";
import ImageViewer from "../../Utilities/ImageViewer/ImageViewer.component";
import StatusDropDown from "../../Utilities/StatusDropDown/StatusDropDown.component";
import { fdelete } from "../../../API/callsAPI";
import DateAndTimeViewer from "../../Utilities/DateAndTimeViewer/DateAndTimeViewer.component";

export default function Shotversionslist(props) {
  const classes = useStyles();

  const [isEditShotVersionModalOpen, setEditShotVersionModalOpen] =
    useState(false);

  const [isDeleteShotVersionModalOpen, setDeleteShotVersionModalOpen] =
    useState(false);
  const [deleteData, setDeleteData] = useState({});

  function handleEditShotVersionModalOpenState(e) {
    e?.stopPropagation();
    setEditShotVersionModalOpen(!isEditShotVersionModalOpen);
  }

  function handleDeleteShotVersionModalOpenState(e, data) {
    e?.stopPropagation();
    setDeleteData(data);
    setDeleteShotVersionModalOpen(!isDeleteShotVersionModalOpen);
  }

  const userCreatedShotVersion = props?.data?.created_by
    ? props.data.created_by.first_name + " " + props.data.created_by.last_name
    : " ";

  const handleDeleteShotVersion = async () => {
    try {
      await fdelete({
        url: `trackables/shot-version/${deleteData?.id}/`,
      });

      props.updateRefreshCounter();
      setDeleteShotVersionModalOpen(false);
      setEditShotVersionModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <TableRow
        hover
        onClick={(e) => props.openShotDetailModal(props.data)}
        style={{
          cursor: "pointer",
          padding: "0px",
          display: "table",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <TableCell align="left" className={classes.shotVersionListTableCell}>
          <div style={{ minWidth: "40%" }}>
            {props.data.thumbnail ? (
              <ImageViewer
                url={props.data.thumbnail}
                sliderState={props.shotVersionToggle ? 25 : 0}
              />
            ) : (
              <NoThumbnail sliderState={props.shotVersionToggle ? 25 : 0} />
            )}
          </div>
        </TableCell>
        <TableCell align="left" className={classes.shotVersionListTableCell}>
          <div>
            {props.data.name ? (
              <span>{props.data.name}</span>
            ) : (
              <span>Version Name</span>
            )}
          </div>
        </TableCell>
        <TableCell align="left" className={classes.shotVersionListTableCell}>
          <DateAndTimeViewer dateAndTime={props?.data?.updated_at} />
        </TableCell>
        <TableCell align="left" className={classes.shotVersionListTableCell}>
          <div>
            <StatusDropDown
              url={`trackables/shot-version/${props.data.id}/`}
              updateRefreshCounter={props.updateRefreshCounter}
              statusList={props.status}
              currStatus={props.data.status}
            />
          </div>
        </TableCell>
        <TableCell align="center" className={classes.shotVersionListTableCell}>
          <span>{userCreatedShotVersion}</span>
        </TableCell>
        <TableCell align="center" className={classes.shotVersionListTableCell}>
          <ShotVersionsListStatusButtons
            openEditShotVersionModal={handleEditShotVersionModalOpenState}
            openDeleteShotVersionModal={handleDeleteShotVersionModalOpenState}
          />
        </TableCell>
      </TableRow>
      <div>
        <Modal
          className={classes.shotVersionPageModals}
          open={isEditShotVersionModalOpen}
          onClose={(e) => {
            setEditShotVersionModalOpen(false);
            e.stopPropagation();
          }}
        >
          <ShotVersionEditVersionModal
            data={props.data}
            sid={props.sequenceId}
            shotID={props.shotID}
            projectId={props.projectId}
            status={props.status}
            shotVersionId={props.data.id}
            handleEditShotVersionModalOpenState={
              handleEditShotVersionModalOpenState
            }
            openCreateNewStatusModal={props.openCreateNewStatusModal}
            openDeleteShotVersionModal={handleDeleteShotVersionModalOpenState}
            updateRefreshCounter={props.updateRefreshCounter}
            dragScreen={props.dragScreen}
            handleDragScreen={props.DatehandleDragScreen}
            setDragFileEnterModal={props.setDragFileEnterModal}
            taskList={props.taskList}
            fetchTasks={props.fetchTasks}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.shotVersionPageModals}
          open={isDeleteShotVersionModalOpen}
          onClose={(e) => {
            setDeleteShotVersionModalOpen(false);
            e.stopPropagation();
          }}
        >
          <DeleteModal
            type="Shot Version"
            name={deleteData?.name}
            handleCloseDeleteModal={() => {
              setDeleteShotVersionModalOpen(false);
            }}
            handleDelete={handleDeleteShotVersion}
          />
        </Modal>
      </div>
    </>
  );
}
