import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import React from "react";
import { X } from "react-feather";
import PropTypes from "prop-types";
import useStyles from "./ProjectPageSortByModal.styles";

export default function ProjectPageSortByModal(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState();
  const [defVal, setDefVal] = React.useState(
    props.defaultvalue ? props.defaultvalue : ""
  );
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const clearSort = () => {
    setDefVal("");
    props.updateRefreshToken();
  };
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h2 className={classes.title}>Sort by</h2>
            <div className={classes.clear}>
              <X size="30px" onClick={() => props.closeSortModal()} />
            </div>
          </div>
          <br />
          <FormControl component="fieldset">
            <RadioGroup
              value={value}
              onChange={(event) => handleChange(event)}
              defaultValue={defVal}
            >
              <FormControlLabel
                className={classes.textColor}
                value="Latest Modified"
                control={<Radio />}
                label="Latest Modified"
              />
              <FormControlLabel
                className={classes.textColor}
                value="A-Z"
                control={<Radio />}
                label="A-Z"
              />
              <FormControlLabel
                className={classes.textColor}
                value="Z-A"
                control={<Radio />}
                label="Z-A"
              />

              <FormControlLabel
                className={classes.textColor}
                value="Date Created"
                control={<Radio />}
                label="Date Created"
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <div className={classes.cardAction}>
          <Button
            size="small"
            variant="outlined"
            className={classes.sortButton}
            onClick={() => props.onSubmit(value)}
          >
            Done
            {/* </Button>
            <Button size="small" 
            class={classes.sortButton} onClick={() => props.onSubmit(value)}>
              Sort
            </Button> */}
          </Button>
          <div className={classes.clear}>
            <Button onClick={() => clearSort()}>Clear Sort</Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

ProjectPageSortByModal.propsTypes = {
  onabortSubmit: PropTypes.func,
  defaultvalue: PropTypes.string,
  closeSortModal: PropTypes.func,
};
