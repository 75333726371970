//Library
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, CircularProgress, Box } from "@material-ui/core";

//Components

import UpdatesPageDepartment from "../UpdatesPageDepartment/UpdatesPageDepartment.component";
import UpdatesPageUpdateCards from "../UpdatesPageUpdateCards/UpdatesPageUpdateCards.component";

import { fget, fgetBFF, fpatch } from "../../../API/callsAPI";

//CSS Styles
import useStyles from "./UpdatesOverview.styles";

function UpdatesOverview(props) {
  const classes = useStyles();
  //States
  const [departments, setDepartments] = useState();
  const [setIsLoaded] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [updates, setUpdates] = useState([]);
  //const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(5);
  const [allUsers, setAllUsers] = useState([]);

  //UseEffect
  useEffect(() => {
    fetchUpdates();
    fetchDepartments();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await fget({
        url: `project/${props.project}/users/`,
      });

      setAllUsers(
        res.data.results.map((el) => {
          const data = {
            name: el.first_name + " " + el.last_name,
            id: el.id,
          };

          return data;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (pageNumber !== 0) fetchUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  //Function to fetch pagination on scroll
  const observer = useRef();
  const lastBookReference = useCallback(
    (node) => {
      if (nextPageLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(pageNumber + 5);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextPageLoading, hasMore]
  );

  //Function to mark a update as read
  const markAsRead = async (updateId) => {
    const updateFilter = updates.filter((item) => item.id !== updateId);
    // console.log(itemIndex);
    // updates.splice(itemIndex, 1);

    try {
      await fpatch({
        url: `updates/${updateId}/`,
        data: { isRead: true },
      });
      setUpdates([...updateFilter]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const res = await fget({
        url: `department/?project=${props.project}`,
      });

      setDepartments(res.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  //Bff Function to fetchUpdates
  const fetchUpdates = async () => {
    setNextPageLoading(true);
    setHasMore(false);
    try {
      const res = await fgetBFF({
        url: `web/updates?project=${props.project}&offset=${pageNumber}&page_size=${pageSize}`,
      });
      if (res.status === 200) {
        setNextPageLoading(false);
        if (res.data.updates.results) {
          setUpdates((prevUpdates) => [
            ...prevUpdates,
            ...res.data.updates.results,
          ]);
        }
        if (res.data.updates.next) {
          setHasMore(true);
        } else {
          setIsLoaded(true);
          setHasMore(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
      // setError(
      //   error.response.data.detail
      //     ? error.response.data.detail
      //     : error.message
      // );
    }
  };

  return (
    <>
      <div>
        <Grid container spacing={5}>
          <Grid
            display={{ xs: "none", lg: "block" }}
            item
            lg={4}
            md={5}
            container
            direction="column"
          >
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
              }}
            >
              <h1 style={{ fontFamily: "Nunito Sans", fontWeight: "500" }}>
                Departments
              </h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {departments && departments.length ? (
                  departments.map((el) => (
                    <UpdatesPageDepartment project={props.project} dept={el} />
                  ))
                ) : (
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontWeight: "450",
                      width: "auto",
                      color: "#FF7878",
                    }}
                  >
                    This project has no departments
                  </span>
                )}
              </div>
            </Box>
            <Grid>
              {/* <h1 style={{ fontFamily: "Nunito Sans", fontWeight: "500" }}>
                Status Tags
              </h1>
              <UpdatesPageStatustag /> */}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={8} lg={4} md={5}>
            <h1
              style={{
                fontFamily: "Nunito Sans",
                fontWeight: "510",
                fontSize: "25px",
              }}
            >
              Updates
            </h1>
            {updates.map((update, index) => {
              if (updates.length === index + 1) {
                return (
                  <div ref={lastBookReference} key={update.id}>
                    <UpdatesPageUpdateCards update={update} users={allUsers} />
                  </div>
                );
              }
              return (
                <div key={update.id}>
                  <UpdatesPageUpdateCards
                    markAsRead={markAsRead}
                    users={allUsers}
                    update={update}
                    projectId={props.project}
                  />
                </div>
              );
            })}
            {nextPageLoading ? (
              <div className={classes.nextPageLoader}>
                <CircularProgress
                  type="Oval"
                  color="secondary"
                  height={50}
                  width={50}
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default UpdatesOverview;
