import React, { useEffect, useState } from "react";
import useStyles from "./TaskViewModal.style.js";
import {
  Card,
  CardContent,
  Modal,
  Menu,
  Button,
  TextField,
  Divider,
} from "@material-ui/core";
import {
  X,
  DotsThree,
  PencilLine,
  Trash,
  CaretUp,
  CaretDown,
} from "phosphor-react";
import { useFormik } from "formik";
import * as yup from "yup";
import { fget, fpost } from "../../../../API/callsAPI.js";
import ViewComments from "../../../Utilities/CommentBox/ViewComments/ViewComments.jsx";
import CommentBox from "../../../Utilities/CommentBox/CommentBox.jsx";
import TrackableViewer from "../../../Utilities/TrackableViewer/TrackableViewer.jsx";
import UsersViewer from "../../../Utilities/UsersViewer/UsersViewer.jsx";
import StatusViewer from "../../../Utilities/StatusViewer/StatusViewer.jsx";
import DeleteModal from "../../../Utilities/DeleteModal/DeleteModal.component";
import PriorityViewer from "../../../Utilities/PriorityViewer/PriorityViewer.component.jsx";
import ErrorOrSuccessMessage from "../../../Utilities/ErrorOrSuccessMessage/ErrorOrSuccessMessage.component.jsx";
import useCollapse from "react-collapsed";
export default function TaskViewModal(props) {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [assignedUser, setAssignedUser] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [optionModalAnchor, setOptionModalAnchor] = useState(null);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [isTaskDetailsExpanded, setIsTaskDetailsExpanded] = useState(true);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded: isTaskDetailsExpanded,
  });
  const [loggedInUser, setLoggedInUser] = useState();
  const [commentsRefreshCounter, setCommentsRefreshCounter] = useState(0);
  const [repliedTo, setRepliedTo] = useState({});

  const updateCommentsRefreshCounter = () => {
    setCommentsRefreshCounter(commentsRefreshCounter + 1);
  };
  useEffect(() => {
    if (props.task.assigned_users !== null || undefined) {
      // if (typeof props.task.assigned_users == "array") {
      if (Array.isArray(props.task.assigned_users)) {
        setAssignedUser([props.task.assigned_users]);
      } else {
        setAssignedUser(props.task?.assigned_users);
      }
    }
  }, [props.task]);

  useEffect(() => {
    if (props.task.id) {
      getComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task.id, commentsRefreshCounter]);

  const getComments = async () => {
    try {
      const res = await fget({
        url: `trackables/task-comment?task=${props.task.id}`,
      });
      if (res.status === 200 || 201) {
        setComments(res.data.results);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: props.task.name,
      status: props.task.status ? props.task.status.id : "",
      attachment: "",
    },
  });

  const postComment = async (description, attachment) => {
    let formData = new FormData();
    formData.append("name", props.task.name);
    formData.append("description", description);
    formData.append("task", props.task.id);
    formData.append("id", props.projectId);
    if (repliedTo?.id) {
      formData.append("replied_to", repliedTo?.id);
    }
    if (attachment) {
      formData.append("attachment", attachment, attachment.name);
    }
    let data = {
      url: `trackables/task-comment/`,
      data: formData,
    };
    try {
      setShowCircularProgress(true);
      const res = await fpost(data);
      if (res.status === 200 || 201) {
        setShowCircularProgress(false);
        formik.values.comment = "";
        getComments();
        setRepliedTo({});
        setSnackValue({
          isOpen: true,
          message: `Your Comment added Successfully."`,
          isError: false,
        });
      }
    } catch (err) {
      console.log(err);
      setShowCircularProgress(false);
      setSnackValue({
        isOpen: true,
        message: `please fill all the required field`,
        isError: true,
      });
    }
  };

  useEffect(() => {
    const accessToken = document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      /(?:(?:^|.*;\s*)access\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    accessToken &&
      setLoggedInUser(
        props.projectUsers.find(
          (user) =>
            user.id === JSON.parse(atob(accessToken.split(".")[1])).user_id
        )
      );
  }, []);

  return (
    <>
      <ErrorOrSuccessMessage snackValue={snackValue} />
      <Card className={classes.ViewModalContainer}>
        <CardContent className={classes.cardContent}>
          <div className={classes.taskViewerTools}>
            <div>
              <div className={classes.taskName}>{props.task.name}</div>
              <div className={classes.duration}>
                {new Date(props.task.start_time).toString().substr(4, 6)} -{" "}
                {new Date(props.task.end_time).toString().substr(4, 6)}
              </div>
            </div>
            <div className={classes.buttons}>
              <Button
                onClick={(event) => {
                  setOptionModalAnchor(event.target);
                }}
                style={{ minWidth: "20px" }}
              >
                <DotsThree height={20} width={20} />
              </Button>
              <Button
                style={{ minWidth: "20px" }}
                onClick={() => {
                  props.closeModal();
                  setRepliedTo({});
                }}
              >
                <X className={classes.icon} size={24} />
              </Button>
            </div>
          </div>
          <div {...getCollapseProps({})}>
            <div className={classes.taskDetails}>
              <div>
                {props?.task?.id ? (
                  <UsersViewer
                    assignedUser={assignedUser}
                    selectedTask={props?.task}
                    editable="no"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className={classes.statusAndPriority}>
                <div className={classes.labelAndContentGap}>
                  <span className={classes.label}>Status :</span>
                  <div>
                    {props?.task?.status ? (
                      <>
                        <StatusViewer status={props?.task?.status} />
                      </>
                    ) : (
                      "no status"
                    )}
                  </div>
                </div>
                <div className={classes.labelAndContentGap}>
                  <span className={classes.label}>Priority </span>
                  <PriorityViewer priority={props?.task?.priority} />
                </div>
              </div>
              <div className={classes.labelAndContentGap}>
                <span className={classes.label}>Trackable</span>
                <div>
                  {props?.task?.id ? (
                    <TrackableViewer trackable={props?.task} />
                  ) : (
                    "no trackable"
                  )}
                </div>
              </div>
              <div className={classes.descriptionContainer}>
                <div className={classes.label}>Description</div>
                <span style={{ paddingLeft: "10px" }}>
                  {props?.task?.description}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.dividerLine}>
            <div className={classes.line}></div>
            <div>
              <div
                className={classes.upOrDownIcon}
                {...getToggleProps({
                  onClick: () =>
                    setIsTaskDetailsExpanded(!isTaskDetailsExpanded),
                })}
              >
                {isTaskDetailsExpanded ? (
                  <CaretUp size={20} />
                ) : (
                  <CaretDown size={20} />
                )}
              </div>
            </div>
            <div className={classes.line}></div>
          </div>

          <div>
            <CommentBox
              postComment={postComment}
              showCircularProgress={showCircularProgress}
              loggedInUser={loggedInUser}
              type="newComment"
              repliedTo={repliedTo}
              setRepliedTo={setRepliedTo}
            />
          </div>
          <div
            style={{
              height: isTaskDetailsExpanded
                ? `calc(100% - 350px)`
                : `calc(100% - 150px)`,
            }}
          >
            <ViewComments
              comments={comments}
              isTaskDetailsExpanded={isTaskDetailsExpanded}
              loggedInUser={loggedInUser}
              updateCommentsRefreshCounter={updateCommentsRefreshCounter}
              task={props.task}
              projectId={props.projectId}
              setSnackValue={setSnackValue}
              setRepliedTo={setRepliedTo}
            />
          </div>
        </CardContent>
        {/* Menu Popover */}
        <Menu
          anchorEl={optionModalAnchor}
          open={Boolean(optionModalAnchor)}
          onClose={() => setOptionModalAnchor(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          MenuListProps={{
            style: {
              padding: "0px",
            },
          }}
        >
          <div className={classes.menuDiv}>
            <Button
              style={{ minWidth: "120px", height: "30px", padding: "4px" }}
              onClick={() => {
                props.switchToEditModal();
              }}
            >
              <div className={classes.editAndCloseButton}>
                <PencilLine size={20} />
                <span>Edit Task</span>
              </div>
            </Button>
            <hr style={{ width: "90%", margin: "1px" }} />
            <Button
              style={{ minWidth: "120px", height: "30px", padding: "4px" }}
              onClick={() => setDeleteModalOpen(true)}
            >
              <div className={classes.editAndCloseButton}>
                <Trash size={20} />
                <span>Delete Task</span>
              </div>
            </Button>
          </div>
        </Menu>
        {deleteModalOpen && (
          <Modal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DeleteModal
              name={props.task.name}
              type="task"
              handleCloseDeleteModal={() => setDeleteModalOpen(false)}
              handleDelete={() => {
                props.handleTaskDelete(props.task.id).then((res) => {
                  props.closeModal();
                  setOptionModalAnchor(null);
                });
                setDeleteModalOpen(false);
              }}
            />
          </Modal>
        )}
      </Card>
    </>
  );
}
