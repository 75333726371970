import { Chip, Tooltip } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import StringTruncate from "../../Utilities/StringTruncate/StringTruncate";

function UpdatesPageDepartment(props) {
  return (
    <div style={{ float: "left", display: "flex", margin: "2px" }}>
      <Link
        style={{ textDecoration: "none" }}
        to={`/${props.project}/departments/${props.dept.id}/department`}
      >
        <Tooltip title={props.dept.name}>
          <Chip
            label={StringTruncate(props.dept.name, 20)}
            style={{
              height: "30px",
              fontFamily: "Nunito Sans",
              padding: "5px",
              margin: "3px",
              backgroundColor: "#393E46",
            }}
            clickable
            size="small"
          ></Chip>
        </Tooltip>
      </Link>
    </div>
  );
}

export default UpdatesPageDepartment;
