import React from "react";
import { FormControl, MenuItem, Select, Tooltip } from "@material-ui/core";
import { fpatch } from "../../../API/callsAPI";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import useStyles from "./StatusDropDown.style";
import { useState, useEffect } from "react";

export default function StatusDropDown(props) {
  const classes = useStyles();
  const [updateStatus, setUpdateStatus] = useState("finished");
  const [snackValue, setSnackValue] = React.useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const handleUpdateStatus = (status) => {
    setUpdateStatus(status);
    if (status !== "updating") {
      setTimeout(() => {
        setUpdateStatus("finished");
      }, 2000);
    }
  };

  const handleStatusChange = async (e) => {
    handleUpdateStatus("updating");
    let formdata = new FormData();

    formdata.append("status", e.target.value);

    let editedSequence = {
      url: props.url,
      data: formdata,
    };

    try {
      const res = await fpatch(editedSequence);
      handleUpdateStatus("updated");
      setSnackValue({
        isOpen: true,
        message: `SuccessFully changed status of ${res?.data?.name}`,
        isError: false,
      });
      props.updateRefreshCounter();
    } catch (error) {
      handleUpdateStatus("error");
      setSnackValue({
        isOpen: true,
        message: `error in changing status`,
        isError: true,
      });
    }
  };

  return (
    <>
      {updateStatus === "updating" ? (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
        >
          <Alert severity="info">Updating Status Please Wait</Alert>
        </Snackbar>
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackValue.isOpen}
          autoHideDuration={1500}
          onClose={() => setSnackValue({ isOpen: false, message: "" })}
        >
          <Alert
            severity={snackValue.isError === false ? "success" : "warning"}
          >
            {snackValue.message}
          </Alert>
        </Snackbar>
      )}
      <div className={classes.statusDropDown}>
        <FormControl fullWidth>
          <Select
            disableUnderline
            className={classes.input}
            value={props?.currStatus?.id || "none"}
            onClick={(e) => e.stopPropagation()}
            onChange={handleStatusChange}
          >
            <MenuItem className={classes.menuItem} value={"none"}>
              <Tooltip title={"No status selected"} placement="top">
                <div>
                  <span style={{ overflow: "hidden" }}>None</span>
                </div>
              </Tooltip>
            </MenuItem>
            {props.statusList &&
              props.statusList.map((status) => (
                <MenuItem
                  className={classes.menuItem}
                  value={status.id}
                  key={status.id}
                >
                  <Tooltip title={status?.name} placement="top">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className={classes.statusColor}
                        style={{
                          backgroundColor: `rgb(${status?.color})`,
                        }}
                      ></span>
                      <span style={{ width: "80%", overflow: "hidden" }}>
                        {status.name}
                      </span>
                    </div>
                  </Tooltip>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {updateStatus === "updating" ? (
          <>
            <lord-icon
              className={classes.animatedIcon}
              src="https://cdn.lordicon.com/sihdhmit.json"
              trigger="loop"
              colors="primary:#6C65D9"
              state="loop"
            ></lord-icon>
          </>
        ) : updateStatus === "updated" ? (
          <>
            <lord-icon
              src="https://cdn.lordicon.com/hjeefwhm.json"
              trigger="loop"
              colors="primary:green"
              state="morph-check-out"
              className={classes.animatedIcon}
            ></lord-icon>
          </>
        ) : updateStatus === "error" ? (
          <>
            <lord-icon
              src="https://cdn.lordicon.com/otyynlki.json"
              trigger="loop"
              colors="primary:red"
              className={classes.animatedIcon}
            ></lord-icon>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
